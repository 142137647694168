import { Col, Image, Row, Form, Input, Checkbox, Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDirectusClient } from "../lib/directus";
import { login, readMe, refresh } from "@directus/sdk";
import { useDispatch, useSelector } from 'react-redux';
import { userAdd } from "../features/auth/authSlice";
function Auth(props) {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  const dispatch =useDispatch()
const user = useSelector((state)=>state.user)
  const onFinish = async (values) => {
    console.log("holaa");
    const directus = await getDirectusClient();

    // Try to authenticate with token if exists
    console.log(user);
      try {
        const response = await directus.users.me();
        const autenticado = response.data !== null;
        console.log("¿Estás autenticado?", autenticado);
        setAuthenticated(true)
      } catch (error) {
        console.error("Error al validar la autenticación:", error);
      }
    

    // Let's login in case we don't have token or it is invalid / expired
    if (authenticated == false) {
      const email = values.username;
      const password = values.password;

      await directus.login( email, password  )
     
        .then((res) => {
          console.log(res);
          dispatch(userAdd(res))
          setAuthenticated(true);
          navigate("/");
        })
        .catch((e) => {
          Modal.error({
            title: "error al iniciar sesion",
            content: "usuario o contraseña incorrectos",
          });
        });
    } else {
      navigate("/");
    }

    // GET DATA

    // After authentication, we can fetch data from any collections that the user has permissions to.
  };

  const validarlogin = async () => {
    const directus = await getDirectusClient();

    await directus.refresh({})


      .then((res) => {
        console.log(res);
        navigate("/");
      })
      .catch((err) => {
        console.log("eror token");
        console.log(err);
      });
  };

  useEffect(() => {
    validarlogin();
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row>
      <Col xs={24} lg={12}>
        <Image style={{ height: "100vh" }} preview={false} src="login.jpg" />
      </Col>{" "}
      <Col
        xs={24}
        lg={12}
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Usuario"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Ingresar
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default Auth;
