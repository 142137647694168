import { combineReducers, configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import userReducer from "../features/auth/authSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import facturaReducer from "../features/factura/facuraSlice";
import productosvendedorReducer from "../features/productosvendedor/productosvendedorSlice";
import menuReducer from "../features/menu/menuSlice";
import productosfacturar from "../features/productosFacrurar/productosSlice";
import authdataReducer from "../features/authdata/authdataSlice";
import permisosReducer from '../features/permisos/permisosSlice'
const rootPersistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  counter: counterReducer,
  user: userReducer,
  facrura: facturaReducer,
  productosvendedor: productosvendedorReducer,
  menu: menuReducer,
  productosfacturar: productosfacturar,
  userdata: authdataReducer,
  permisos: permisosReducer
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
