import {
  Table,
  Button,
  Modal,
  Space,
  Form,
  Input,
  Checkbox,
  Switch,
  message,
  InputNumber,
  Select,
  Tabs,
  Tag,
} from "antd";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";

import React, { useEffect, useState } from "react";
import { getDirectusClient } from "../../lib/directus";
import Layouts from "../../components/Layouts";
import { createItem, deleteItem, readItems, updateItem } from "@directus/sdk";
const { Option } = Select;
const Groups = () => {
  const [filter, setFilter] = useState([]);
  const [hasCode, setHasCode] = useState(false);
  const [Code, setCode] = useState(false);

  const [form] = Form.useForm();

  const handleCheckboxChange = (e) => {
    setHasCode(e.target.checked);
  };

  const handleCheckbox = (e) => {
    setCode(e.target.checked);
  };

  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [OpenEdit, setOpenEdit] = useState(false);
  const [groupselect, setGroupselect] = useState({});
  const showModal = () => {
    setIsModalOpen(true);
  };
  const Modalopen = () => {
    setModalOpen(true);
  };
  const openEdit = () => {
    setOpenEdit(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setModalOpen(false);
    setOpenEdit(false);
  };

  //Get
  const GetGroups = async () => {
    const directus = await getDirectusClient();
 await directus.request(readItems("grupos",{
      fields: "*.*.*",
      limit: -1,
 })).then((datos) => {
       setData(datos);
    })
   
  };

  //Post
  const onFinish = async (values) => {
    console.log(values);
    const directus = await getDirectusClient();
    const res = await directus.request(createItem("grupos",{
      nombre: values.nombre,
      codebar: values.codebar,
      iccid: values.iccid,
      codebarmax: values.codebarmax,
      codebarmin: values.codebarmin,
      estado: values.estado,
      iccidmin: values.iccidmin,
      iccidmax: values.iccidmax,
      impuesto: values.impuesto,
      afectainventario: values.afectainventario,
    }));

    if (res.id > 0) {
      message.success("Grupo creado");
      GetGroups();
    } else {
      message.error("Upss!!, algo salio mal :(");
    }
  };

  //Delete
  const DeleteGroup = async (id) => {
    const directus = await getDirectusClient();
   Modal.confirm({
     title: "Confirmar",

     content: "Desea eliminar este grupo?",
     okText: "Eliminar",
     cancelText: "Calcelar",
     onOk: async()=> {
       await directus.request(deleteItem("grupos",id));
      GetGroups();
     },
   });
    
  };

  //Put

  useEffect(() => {
    GetGroups();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Serial",
      dataIndex: "codebar",
      key: "codebar",
      render: (_, record) => {
        console.log(record.codebar);
        return record.codebar ? (
          <Tag color="green">Si</Tag>
        ) : (
          <Tag color="red">No</Tag>
        );
      },
    },
    {
      title: "Serial min",
      dataIndex: "codebarmax",
      key: "codebarmax",
    },
    {
      title: "Serial min",
      dataIndex: "codebarmin",
      key: "codebarmin",
    },
    {
      title: "Iccid",
      dataIndex: "iccid",
      key: "iccid",
      render: (_, record) => {
        console.log(record.iccid);
        return record.iccid ? (
          <Tag color="green">Si</Tag>
        ) : (
          <Tag color="red">No</Tag>
        );
      },
    },
    {
      title: "Iccidmax",
      dataIndex: "iccidmax",
    },
    {
      title: "Iccidmin",
      dataIndex: "iccidmin",
    },
    {
      title: "Impuesto",
      dataIndex: "impuesto",
    },
    {
      title: "Estado",
      dataIndex: "estado",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              console.log(record);
              setOpenEdit(true);
              setFilter(record);
              form.setFieldsValue({ nombre: record.nombre });
              form.setFieldsValue({ impuesto: record.impuesto });
              setHasCode(record.codebar);
              setCode(record.iccid);
              form.setFieldsValue({ codebar: record.codebar });
              form.setFieldsValue({ codebarmax: record.codebarmax });
              form.setFieldsValue({ codebarmin: record.codebarmin });
              form.setFieldsValue({ iccid: record.iccid });
              form.setFieldsValue({ iccidmax: record.iccidmax });
              form.setFieldsValue({ iccidmin: record.iccidmin });
              form.setFieldsValue({ estado: record.estado });
              form.setFieldsValue({
                afectainventario: record.afectainventario,
              });
            }}
          >
            Edit
          </Button>
          <Button
            type="link"
            onClick={() => {
              showModal();
              setGroupselect(record);
              console.log(record);
            }}
          >
            View
          </Button>

          <Button type="link" danger onClick={() => DeleteGroup(record.id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const EditGroup = async (values) => {
    const directus = await getDirectusClient();
    const update = await directus.request(updateItem("grupos",filter.id, {
      nombre: values.nombre,
      codebar: values.codebar,
      iccid: values.iccid,
      codebarmax: values.codebarmax,
      codebarmin: values.codebarmin,
      iccidmax: values.iccid ? values.iccidmax : 0,
      iccidmin: values.iccid ? values.iccidmin : 0,
      impuesto: values.impuesto,
      afectainventario: values.afectainventario,
    }));
    if (update.id > 0) {
      message.success("Grupo Editado");
      GetGroups();
    } else {
      message.error("Upss!!, algo salio mal :(");
    }
  };

  return (
    <Layouts>
      <Table
        dataSource={data}
        columns={columns}
        title={() => (
          <Button icon={<PlusOutlined />} onClick={Modalopen}>
            Nuevo
          </Button>
        )}
      />
      <Modal
        title={` Grupo ${groupselect.nombre}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Nombre: {groupselect.nombre}</p>
        <p>Impuesto: {groupselect.impuesto}</p>
        <p>Estado: {groupselect.estado}</p>
        <p>
          Tipomercancia:{" "}
          {groupselect.id ? groupselect.tipomercancia?.name : null}
        </p>
      </Modal>
      {/* Edit Form */}
      <Modal open={OpenEdit} footer={null} onCancel={handleCancel}>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          autoComplete="off"
          onFinish={EditGroup}
        >
          <Form.Item
            name="nombre"
            rules={[
              {
                required: true,
                message: "Este campo es obligatorio",
              },
            ]}
          >
            <Input placeholder="Nombre del grupo" />
          </Form.Item>
          <Form.Item
            name="impuesto"
            label="Impuesto"
            rules={[
              { required: true, message: "Este es un campo obligatorio" },
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              step={0.01}
              formatter={
                (value) => `${value}%` // Agrega el símbolo de porcentaje después del número ingresado
              }
            />
          </Form.Item>
          <Form.Item name="codebar" valuePropName="checked">
            <Checkbox onChange={handleCheckboxChange}>Tiene serial?</Checkbox>
          </Form.Item>

          {hasCode && (
            <>
              <Form.Item
                label="Maximo"
                name="codebarmax"
                rules={[
                  { required: true, message: "Este es un campo obligatorio" },
                ]}
              >
                <InputNumber placeholder="Código de barras máximo" />
              </Form.Item>

              <Form.Item
                label="Minimo"
                name="codebarmin"
                rules={[
                  { required: true, message: "Este es un campo obligatorio" },
                ]}
              >
                <InputNumber placeholder="Código de barras mínimo" />
              </Form.Item>
            </>
          )}

          <Form.Item name="iccid" valuePropName="checked">
            <Checkbox onChange={handleCheckbox}>Iccid?</Checkbox>
          </Form.Item>

          {Code && (
            <>
              <Form.Item
                label="Maximo"
                name="iccidmax"
                rules={[
                  { required: true, message: "Este es un campo obligatorio" },
                ]}
              >
                <InputNumber placeholder="Código máximo" />
              </Form.Item>

              <Form.Item
                label="Minimo"
                name="iccidmin"
                rules={[
                  { required: true, message: "Este es un campo obligatorio" },
                ]}
              >
                <InputNumber placeholder="Codigo minimo" />
              </Form.Item>
            </>
          )}

          <Form.Item name="estado" label="Estado">
            <Select
              placeholder="Select a option and change input text above"
              allowClear
            >
              <Option value="activo">Activo</Option>
              <Option value="pausado">Pausado</Option>
              <Option value="anulado">Anulado</Option>
              <Option value="solucionado">Solucionado</Option>
              <Option value="vencido">Vencido</Option>
            </Select>
          </Form.Item>

          <Form.Item valuePropName="checked" name="afectainventario">
            <Checkbox>Afecta Inventario?</Checkbox>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Editar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* Created Form */}
      <Modal open={ModalOpen} onCancel={handleCancel} footer={false}>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{
            codebar: false,
            iccid: false,
            estado: false,
            codebarmax: 0,
            codebarmin: 0,
            iccidmax: 0,
            iccidmin: 0,
          }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="nombre"
            rules={[
              {
                required: true,
                message: "Este campo es obligatorio",
              },
            ]}
          >
            <Input placeholder="Nombre del grupo" />
          </Form.Item>
          <Form.Item
            name="impuesto"
            label="Impuesto"
            rules={[
              { required: true, message: "Este es un campo obligatorio" },
              { type: "number", message: "Ingrese un número válido" },
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              step={0.01}
              formatter={
                (value) => `${value}%` // Agrega el símbolo de porcentaje después del número ingresado
              }
            />
          </Form.Item>
          <Form.Item name="codebar" valuePropName="checked">
            <Checkbox onChange={handleCheckboxChange}>Tiene codigo?</Checkbox>
          </Form.Item>

          {hasCode && (
            <>
              <Form.Item
                label="Maximo"
                name="codebarmax"
                rules={[
                  { required: true, message: "Este es un campo obligatorio" },
                ]}
              >
                <InputNumber placeholder="Código de barras máximo" />
              </Form.Item>

              <Form.Item
                label="Minimo"
                name="codebarmin"
                rules={[
                  { required: true, message: "Este es un campo obligatorio" },
                ]}
              >
                <InputNumber placeholder="Código de barras mínimo" />
              </Form.Item>
            </>
          )}

          <Form.Item name="iccid" valuePropName="checked">
            <Checkbox onChange={handleCheckbox}>Iccid?</Checkbox>
          </Form.Item>

          {Code && (
            <>
              <Form.Item
                label="Maximo"
                name="iccidmax"
                rules={[
                  { required: true, message: "Este es un campo obligatorio" },
                ]}
              >
                <InputNumber placeholder="Código máximo" />
              </Form.Item>

              <Form.Item
                label="Minimo"
                name="iccidmin"
                rules={[
                  { required: true, message: "Este es un campo obligatorio" },
                ]}
              >
                <InputNumber placeholder="Codigo minimo" />
              </Form.Item>
            </>
          )}

          <Form.Item name="estado" label="Estado">
            <Select
              placeholder="Select a option and change input text above"
              allowClear
            >
              <Option value="activo">Activo</Option>
              <Option value="pausado">Pausado</Option>
              <Option value="anulado">Anulado</Option>
              <Option value="solucionado">Solucionado</Option>
              <Option value="vencido">Vencido</Option>
            </Select>
          </Form.Item>

          <Form.Item valuePropName="checked" name="afectainventario">
            <Checkbox>Afecta Inventario?</Checkbox>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Crear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layouts>
  );
};

export default Groups;
