import { Form, Modal, Select, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { getDirectusClient } from '../../../lib/directus';
import { createItem, updateItem } from '@directus/sdk';
import { Input } from 'antd';

function Editar(props) {
 
    

    const [fielderror, setFielderror] = useState("");
  const [error, setError] = useState(false);

   const [form] = Form.useForm();
    
  const onFinish = async (r) => {
    const directus = await getDirectusClient();
    await directus
      .request(
        updateItem("rutas", props.data.id, {
          coordinador: {
            create: [
              {
                rutas_id: "+",
                coordinador_id: {
                  id: r.coordinador,
                },
              },
            ],
            update: [],
            delete: [
              props.data.coordinador?props.data.coordinador[0].id:null
            ],
          },

          nombre: r.nombre,
         
          
          descripcion: r.descripcion,
        })
      )
      .then((res) => {
        console.log(res);
        props.handleCanceleditar();
        setError("")
      })
      .catch((er) => {
        console.log(er.errors[0].extensions.field);
        setFielderror(er.errors[0].extensions.field);
        setError(true);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  

  useEffect(() => {
    form.setFieldValue("nombre", props.data.nombre);
    form.setFieldValue("descripcion", props.data.descripcion);
    form.setFieldValue(
      "coordinador",
      props.data.coordinador?props.data.coordinador[0].coordinador_id.id:null
    );
    console.log("ff");
    //console.log(props.data.coordinador[0].id);
  },[props.data])
    return (
      <Modal
        title="Editar Coordinador"
        open={props.openmodaleditar}
        onOk={false}
        onCancel={props.handleCanceleditar}
        footer={null}
      >
        {error ? (
          <p style={{ color: "red" }}>
            El campo {fielderror} ya existe en el sistema{" "}
          </p>
        ) : null}
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="nombre"
            name="nombre"
            rules={[
              {
                required: true,
                message: "ingresar nombre",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="descripcion"
            name="descripcion"
            rules={[
              {
                required: true,
                message: "ingresar descripcion",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="coordinador"
            name="coordinador"
            rules={[
              {
                required: true,
                message: "ingresar Coordinador",
              },
            ]}
          >
            <Select allowClear>
              {props.coordinadores.map((coordinador) => {
                return (
                  <Select.Option value={coordinador.id}>
                    {coordinador.first_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
}

export default Editar;