import { Button, Form, Input, Modal, Select } from "antd";
import { getDirectusClient } from "../../../lib/directus";
import { useEffect, useRef, useState } from "react";
const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Addcaja = (props) => {
  const fileselect = useRef();
  const [totalerrores, setTotalErrores] = useState(0);
  const [loanding, setLoanding] = useState(false);
  const [archivocargado, setArchivocargado] = useState(false);
  const [form] = Form.useForm();
  const onGenderChange = (value) => {
    switch (value) {
      case "male":
        form.setFieldsValue({
          note: "Hi, man!",
        });
        break;
      case "female":
        form.setFieldsValue({
          note: "Hi, lady!",
        });
        break;
      case "other":
        form.setFieldsValue({
          note: "Hi there!",
        });
        break;
      default:
    }
  };
  const onFinish = async (values) => {
    var datoprocesados = 0;
    const errores = [];
    const preciot = console.log(values);
    const directus = await getDirectusClient();
    var largo = values.inicial.length;
    var inicial = parseInt(values.inicial) - 1;
    var final = parseInt(values.final);
    var largodedatos = parseInt(values.final) - parseInt(values.inicial) + 1;
    console.log(largodedatos);
    if (
      props.producto.cantidad < largodedatos ||
      props.producto.cantidad - props.producto.seriales.length < largodedatos
    ) {
      Modal.error({
        title: "Validacion de tamaño de archivo",
        content: `validamo los seriales  y contienen ${largodedatos} registros y la cantidad de registros a subir son ${
          props.producto.cantidad - props.producto.seriales.length
        }!`,
      });
    } else {
      for (let index = 0; index < largodedatos; index++) {
        inicial = inicial + 1;

        if (props.producto.producto_detalle.grupo.codebar == true) {
          if (
            largo >= props.producto.producto_detalle.grupo.codebarmin &&
            largo <= props.producto.producto_detalle.grupo.codebarmax
          ) {
            await directus
              .items("seriales")
              .createOne({
                serial: inicial.toString().padStart(largo, "0"),
                iccid: 0,
                min: 0,
                estado: 5,
                caja: values.caja,
                orden_compra_ingreso: props.order,
                producto: props.producto.producto_detalle.id,
                producto_odc: props.producto.id,
              })
              .then((res) => {
                console.log("creado " + inicial);
                datoprocesados = datoprocesados + 1;

                if (res.errors) {
                  console.log(res.errors);
                }
              })
              .catch((err) => {
                console.log(err);

                if (err.errors[0].extensions.code == "RECORD_NOT_UNIQUE") {
                  errores.push(inicial);
                  datoprocesados = datoprocesados + 1;
                }
              });
          } else {
            errores.push(inicial);
            console.log("error serial " + inicial);
            datoprocesados = datoprocesados + 1;
          }
        }

        if (largodedatos == datoprocesados) {
          Modal.warning({
            content: (
              <>
                <p>
                  {" "}
                  Se procesaron
                  <span style={{ color: "green" }}> {datoprocesados}</span>{" "}
                  Seriales de los cuales se encontraron errores en{" "}
                  <span style={{ color: "red" }}>{errores.length} </span>{" "}
                  Seriales por que ya existen en la base de datos o no cumplen
                  con el tamaño de codigo de barra
                </p>
              </>
            ),
          });
        }

        /*
         */

        /* console.log("larogo " + largodedatos);
      console.log("proce " + datoprocesados);

      if (parseInt(datoprocesados) === parseInt(largodedatos)) {
        console.log(errores.length);
        setTotalErrores(errores.length);
        setLoanding(false);
        setArchivocargado(true);
        fileselect.current.value = "";
      } else {
        console.log("no es igual");
        console.log("procefuera" + datoprocesados);
        console.log("l" + largodedatos);
      }*/
      }
    }
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      note: "Hello world!",
      gender: "male",
    });
  };
  return (
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      style={{
        maxWidth: 600,
      }}
    >
      <Form.Item
        name="caja"
        label="Caja"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="inicial"
        label="Inicial"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="final"
        label="Final"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Procesar
        </Button>
        <Button htmlType="button" onClick={onReset}>
          Reset
        </Button>
      </Form.Item>
    </Form>
  );
};
export default Addcaja;
