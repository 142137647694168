import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "counter",
  initialState: [1, 2, 3],
  reducers: {
    counterAdd: (state, action) => {
      console.log(action);
      return action.payload;
    },
  },
});

export const { counterAdd } = counterSlice.actions;
export default counterSlice.reducer;
