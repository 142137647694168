import React, { useEffect, useState } from "react";
import Layouts from "../../components/Layouts";
import { getDirectusClient } from "../../lib/directus";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

function Roles(props) {
  const [roles, setRoles] = useState([]);

  const navigate = useNavigate();
  const getRoles = async () => {
    const directus = await getDirectusClient();

    directus
      .items("directus_roles")
      .readByQuery({ fields: ["*.*"], filter: { app: { _eq: true } } })
      .then((res) => {
        console.log(res);

        setRoles(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <Layouts>
      <div>
        {roles.map((role) => {
          return (
            <Button
              onClick={() =>
                navigate(`/permisions`, { state: { id: role.id, role: role } })
              }
            >
              {role.name}
            </Button>
          );
        })}
        <p></p>
      </div>
    </Layouts>
  );
}

export default Roles;
