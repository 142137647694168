import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Card, Divider, Form, Modal, Select, Space, Tabs } from "antd";
import Layouts from "../../../components/Layouts";
import { getDirectusClient } from "../../../lib/directus";
import Addfileproducts from "../components/Addfileproducts";
import Addcaja from "../components/Addcaja";
import { readItem, readItems, updateItem, updateItems } from "@directus/sdk";

const { Option } = Select;

const onChange = (key) => {
  console.log(key);
};

function OrdeEnter(props) {
  let { order_id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [order, setOrder] = useState({});
  const [bodegas, setBodegas] = useState([]);
  const [productselect, setProductselect] = useState([]);
  const [order1, setOrder1] = useState({});

  const navigate = useNavigate();

  const getOrder = async () => {
    const directus = await getDirectusClient();
    await directus
      .request(
      readItem("ordenes_decompra",order_id, {
        fields: ["*.*.*.*"],
        filter: {
          count: ["productos.id"],
        },
      }))
      .then((res) => {
        console.log(res);

        let ordenn = res;

        setOrder(ordenn);

        /*  ordenn.productos.map(async (producto) => {
          await directus
            .request("seriales")
            readItems({
              fields: "*.*",
              limit: -1,
              filter: { producto_odc: producto.id },
            })
            .then((res) => {
              console.log(res);
              producto.totalproductosagregados = res.data.length;
            });
        });*/
      });
  };

  const getBodegas = async () => {
    const directus = await getDirectusClient();
    await directus
      .request(
      readItems("bodegas",{ fields: "*.*" }))
      .then((res) => {
        console.log(res);
        setBodegas(res.data);
      });
  };

  useEffect(() => {
    console.log(order_id);

    getOrder();
    getBodegas();
  }, []);

  const onFinish = async (values) => {
    const directus = await getDirectusClient();
    await directus.request(updateItem("ordenes_decompra",order_id, {
      bodegas: [{ id: values.bodega }],
    }));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    getOrder();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const items = [
    {
      key: "1",
      label: `Subir archivo csv`,
      children: (
        <Addfileproducts
          modal={isModalOpen}
          producto={productselect}
          getOrder={getOrder}
          order={order_id}
        />
      ),
    },
    /*{
      /*
      key: "2",
      label: `Lector codebar`,
      
      children: (
        <Addcaja
          modal={isModalOpen}
          producto={productselect}
          getOrder={getOrder}
        />
      ),
      
    },*/
    {
      key: "3",
      label: `Por caja`,
      children: (
        <Addcaja
          modal={isModalOpen}
          producto={productselect}
          getOrder={getOrder}
          order={order_id}
        />
      ),
    },
  ];

  let totalproductosagregados = 0;
  let preciototal = 0;
  let productoaagregar = 0;

  const cerrarOrden = async () => {
    const directus = await getDirectusClient();
    directus
      .request("ordenes_decompra")
      .updateOne(order_id, {
        estado: 3,
      })
      .then((res) => {
        console.log(res);
        if (res?.id > 0) {
          console.log(order_id);
          directus
            .request(
            updateItems("seriales",
              { orden_compra_ingreso: order_id },
              {
                estado: 1,
              }
            ))
            .then((res) => {
              Modal.success({
                content:
                  "Orden cerrada con exito, Se activaron " +
                  res.data.length +
                  " seriales",
                onOk: () => {
                  console.log("ok");
                  navigate("/order_list");
                },
              });
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Layouts>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Card title="Ingresar Orden">
          <Space
            split={<Divider type="vertical" />}
            size={"large"}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <p>Orden #: {order.id} </p>
            <p>Factura #: {order.numero_factura} </p>
            <p>proveedor : {order.proveedor ? order.proveedor.nombre : null}</p>

            <p>fecha creacion : {order.date_created}</p>
          </Space>
        </Card>
        <div>
          {order.productos
            ? order.productos.map((producto) => {
                totalproductosagregados += producto.seriales.length;
                preciototal +=
                  producto.seriales.length * producto.precio_uni_final;

                productoaagregar += producto.cantidad;

                return (
                  <Card key={producto.id}>
                    <Space
                      direction="horizontal"
                      split={<Divider type="vertical" />}
                    >
                      {producto ? producto.producto_detalle.nombre : null}
                      <p>
                        {/*parseFloat(producto.producto_detalle.preciosinimp) +
                          (parseFloat(producto.producto_detalle.preciosinimp) *
                            5) /
                100*/}
                      </p>
                      <p>Cantidad: {producto.cantidad}</p>
                      <p>Cantidad ingresada: {producto.seriales.length}</p>

                      {/*producto.cantidad *
                        (parseFloat(producto.producto_detalle.preciosinimp) +
                          (parseFloat(producto.producto_detalle.preciosinimp) *
                            5) /
                        100)*/}
                      <Button
                        onClick={() => {
                          setProductselect(producto);
                          setIsModalOpen(true);
                        }}
                      >
                        Ingresar
                      </Button>
                    </Space>
                  </Card>
                );
              })
            : null}
          <p>Productos para agregar {productoaagregar}</p>
          <p> Total productos agregados: {totalproductosagregados}</p>
          <p>
            Productos faltantes: {productoaagregar - totalproductosagregados}
          </p>
          <p>Precio total agregado: {preciototal}</p>
          <Button
            type="primary"
            disabled={
              productoaagregar === totalproductosagregados ? false : true
            }
            onClick={cerrarOrden}
          >
            Cerrar orden de compra
          </Button>
        </div>
        <div>
          <Modal
            width={700}
            footer={[
              <Button type="primary" onClick={handleOk}>
                ok
              </Button>,
            ]}
            title="Ingresar producto"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>
              {productselect.producto_detalle
                ? productselect.producto_detalle.nombre
                : null}
            </p>
            <p>
              creado por:
              {productselect.user_created
                ? productselect.user_created.first_name
                : null}
            </p>
            <p>Cantidad: {productselect.cantidad}</p>

            <p>Ingresados: {productselect.seriales?.length}</p>

            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </Modal>
        </div>
      </Space>
    </Layouts>
  );
}

export default OrdeEnter;
