import React, { useEffect, useState } from "react";
import { getDirectusClient } from "../../../lib/directus";
import { Button, Form, Input, Modal, Space } from "antd";
import { useForm } from "antd/es/form/Form";

function Serial(props) {
  const arrayp = props.productosagregados;
  const [seriales, setSeriales] = useState([]);

  const [productos, setProductos] = useState([]);
  const [form] = useForm();

  const onFinish = async (values) => {
    const directus = await getDirectusClient();

    await directus
      .items("seriales")
      .readByQuery({
        fields: [
          "*",
          "producto.preciosinimp",
          "producto.nombre",
          "producto.id",
          "producto.grupo.*",
        ],
        filter: { serial: values.serial, estado: 1 },
        limit: -1,
      })
      .then((res) => {
        if (res.data.length <= 0) {
          Modal.error({
            title: "No se encontraron seriales con los datos suministrados",
          });
        }
        setSeriales(res.data);
        console.log("ddd");
        console.log(res.data);
        let existen = 0;
        props.setProductosagregados(res.data);
      })
      .catch((err) => console.log(err));

    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {}, [arrayp]);

  return (
    <>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Space size="large" direction="vertical" style={{ display: "flex" }}>
          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}
            label="Serial"
            name="serial"
            rules={[{ required: true, message: "Please input your caja!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              procesar
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </>
  );
}

export default Serial;
