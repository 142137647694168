import React, { useEffect } from "react";
import { getDirectusClient } from "../lib/directus";
import { useNavigate } from "react-router-dom";
import { readMe, readPermissions } from "@directus/sdk";
import { useDispatch } from "react-redux";
import { userdataAdd } from "../features/authdata/authdataSlice";
import { permisosadd } from "../features/permisos/permisosSlice";

function Validorligin(props) {
  const navigate = useNavigate();

  const dispatch = useDispatch()

  const validarlogin = async () => {
    const directus = await getDirectusClient();

    await directus.request(readMe({
      fields: ["*", "bodega.*", "role.*"]
    }))
      .then((res) => {
        console.log("login...");
        dispatch(userdataAdd(res))
         validadarPermisos();
      })
      .catch((err) => {
        navigate("/login");
        console.log(err);
       
      });
  };



  const validadarPermisos =async () => {
    const directus = await getDirectusClient()
     await directus.request(
      readPermissions({ fields: ["*"] })
     ).then((permissionsRes) => {
    
     dispatch(permisosadd(permissionsRes))

        
     }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    validarlogin();
  }, []);
  return <div></div>;
}

export default Validorligin;
