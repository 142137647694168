import React, { useEffect, useState } from "react";
import Layouts from "../../components/Layouts";
import { getDirectusClient } from "../../lib/directus";
import { Button, Modal, Space, Table } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Createseller from "./components/Createclient";
import Editseller from "./components/Editclient";
import { deleteItem, readItems, readUsers } from "@directus/sdk";
import { QRCode } from "react-qrcode-logo";
import { PDFViewer } from "@react-pdf/renderer";
import Pdfqr from "./components/Pdfqr";
import {
  pdf,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  Image,
} from "@react-pdf/renderer";
const Clientsp = (props) => {
  const [datasellers, setDatasellers] = useState([]);
  const [opencreate, setOpencreate] = useState(false);
  const [openupdate, setOpenupdate] = useState(false);
  const [selectseller, setSelectseller] = useState({});
  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Direccion",
      dataIndex: "direccion",
      key: "direccion",
    },
    {
      title: "Zona",
      dataIndex: "zona",
      key: "zona",
    },
    {
      title: "Documento",
      dataIndex: "documento",
      key: "documento",
    },
    {
      title: "Acciones",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={async () => {
              const directus = await getDirectusClient();
              Modal.confirm({
                title: "Eliminar usuario",
                icon: <ExclamationCircleFilled />,
                content: "Desea eliminar este usuario?",
                onOk() {
                  directus
                    .request(deleteItem("directus_users", record.id))
                    .then((res) => {
                      getSellers();
                    });
                },
                onCancel() {},
              });
            }}
          >
            eliminar
          </Button>
          <Button
            onClick={() => {
              console.log(record);
              setSelectseller(record);
              setOpenupdate(true);
            }}
          >
            Editar
          </Button>
          <QRCode
            value={record.id.toString()}
            id={ record.id}
            logoImage="logoeme.png"
            fgColor="green"
            size={300}
            logoWidth={60}
            qrStyle="squares"
            eyeRadius={[
              [20, 20, 0, 20], // top/left eye
              [20, 20, 20, 0], // top/right eye
              [20, 0, 20, 20], // bottom/left
            ]}
          />
        </Space>
      ),
    },
  ];
  const getSellers = async () => {
    const directus = await getDirectusClient();

    directus.request(
      readItems('cliente',{
        filter: {
     
        },
        limit: -1
      }))
      .then((res) => {
        console.log(res);
        setDatasellers(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSellers();
  }, []);

  const closeCreate = () => {
    setOpencreate(false);
  };

  const closeUpdate = () => {
    setOpenupdate(false);
  };


      const generatePdfsim = () => {
        const doc = <Pdfqr clientes={datasellers} />;
        const asPdf = pdf([]); // {} is important, it resets the previous document
        asPdf.updateContainer(doc);

        asPdf.toBlob().then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "qrs.pdf";
          link.click();
        });
      };


  return (
    <Layouts>
      <Button onClick={() => generatePdfsim()}>Descargar qrs</Button>

      <h3>Usuarios y clientes</h3>
      <Button
        onClick={() => {
          setOpencreate(true);
        }}
      >
        crear usuario o cliente
      </Button>
      {console.log("vendedores", datasellers)}
      <Table columns={columns} dataSource={datasellers} />
      <Createseller
        getSellers={getSellers}
        opencreate={opencreate}
        closeCreate={closeCreate}
      />
      <Editseller
        getSellers={getSellers}
        opencreate={openupdate}
        closeUpdate={closeUpdate}
        selectseller={selectseller}
        setSelectseller={setSelectseller}
      />
      <div style={{display: "none"}}>
        {datasellers.map((c) => {
          return (
            <QRCode
              value={c.id.toString()}
              id={c.id}
              logoImage="logoeme.png"
              fgColor="green"
              size={300}
              logoWidth={60}
              qrStyle="squares"
              eyeRadius={[
                [20, 20, 0, 20], // top/left eye
                [20, 20, 20, 0], // top/right eye
                [20, 0, 20, 20], // bottom/left
              ]}
            />
          );
        })}
      </div>
    </Layouts>
  );
};

export default Clientsp;
