import Icon, {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { getDirectusClient } from "../lib/directus";
import { Layout, Menu, theme, Breadcrumb, Button, Flex, Tooltip, Avatar, Space, Image } from "antd";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import Validorligin from "./Validorligin";
import { useDispatch, useSelector } from "react-redux";
import { menuAdd } from "../features/menu/menuSlice";
import { readMe } from "@directus/sdk";
import { LogoutOutlined } from "@ant-design/icons";
import moment from "moment";
import getIconByName from "./getIconByName";
const { Header, Content, Footer, Sider } = Layout;

const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

const Layouts = (props) => {

const userdata = useSelector((state)=>state.userdata)
 
  const [menulist, setMenulist] = useState([]);
  const navigate = useNavigate();

  const dispacth = useDispatch();
  const menu = useSelector((state) => state.menu);

  const [items, setItems] = useState([
    {
      key: 1,
      label: "inicio",
      icon: <UserOutlined />,
      children: [
        {
          key: 2,
          label: "inicio",

          icon: <UserOutlined />,
        },
      ],
    },
  ]);
  const cerrarSesion = async () => {
    try {
      const directus = await getDirectusClient();
      await directus.logout();
     
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };
  const getMenu = async () => {
    const directus = await getDirectusClient();

    await directus.request(readMe({ fields: ["role.menus.*.*.*" ] })).then((d) => {


      if (d.role.menus !== "undefined") {
        console.log("menu ee");
        console.log(d.role.menus);
        setMenulist(d.role.menus);
        dispacth(menuAdd(d.role.menus));
      } else {
        console.log(d.role.menus);
        console.log("no hay menus");
        setMenulist([]);
        dispacth(menuAdd([]));
      }

        
    }).catch((err)=>console.log(err));
  

  };
  useEffect(() => {
    getMenu();
  }, []);

  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout hasSider>
      <Validorligin />
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          overflow: "auto",
          height: "100vh",

          background: "#fff",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            height: 32,
            margin: 16,
            background: "#fff",
            textAlign: "center"
          }}
        >
          <Image style={{ textAlign: "center" }} width={60} src="https://backend.emesmart.com/assets/bd0445d3-40e7-45b6-b284-72f01233f6dc" />
        </div>
        <Menu mode="inline">
          {menu.map((menu) => {
            return menu.menu_id.espadre ? (
              menu.menu_id.children.length > 0 ? (
                <Menu.SubMenu
                  icon={getIconByName(menu.menu_id.iconv)}
                  key={menu.menu_id.id}
                  title={menu.menu_id.label}
                >
                  {menu.menu_id.children.map((chil) => {
                    return (
                      <Menu.Item
                        icon={getIconByName(menu.menu_id.iconv)}
                        key={chil.id}
                      >
                        <Link to={chil.slug}>{chil.label}</Link>
                      </Menu.Item>
                    );
                  })}
                </Menu.SubMenu>
              ) : (
                <Menu.Item
                  icon={getIconByName(menu.menu_id.iconv)}
                  key={menu.menu_id.id}
                >
                  <Link to={menu.menu_id.slug}>{menu.menu_id.label}</Link>
                </Menu.Item>
              )
            ) : null;
          })}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Flex
            vertical={false}
            align="end"
            ali
            justify="space-between"
            gap={"large"}
          >
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: () => setCollapsed(!collapsed),
              }
            )}
            <Space>
              <Tooltip title={userdata.first_name + " " + userdata.last_name}>
                <Avatar icon={<UserOutlined />} />
              </Tooltip>
              <Tooltip title="Cerrar Sesion">
                <Button
                  shape="circle"
                  type="primary"
                  title="Cerrar Sesion"
                  icon={<LogoutOutlined />}
                  style={{ marginTop: 10, marginRight: 20 }}
                  onClick={cerrarSesion}
                />
              </Tooltip>
            </Space>
          </Flex>
        </Header>
        <Content
          style={{
            margin: "10px 16px",
          }}
        >
          {props.children}
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          ©{moment().format("YYYY")}
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Layouts;
