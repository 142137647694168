import React, { useEffect } from "react";
import Layouts from "../../components/Layouts";
import { getDirectusClient } from "../../lib/directus";
import { Button, Form, Input, InputNumber, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import {  readItems, updateItems, updateSingleton } from "@directus/sdk";

function CambioSerial(props) {
  const [form] = Form.useForm();
  const directus = getDirectusClient();

  const buscarproductofacturado = async (serial, nuevo) => {
    const serialnuevo = await directus
      .request(
        readItems("seriales", {
          fields: ["*"],
          filter: {
            serial: {
              _eq: nuevo,
            },
            estado: 1,
          },
        })
      )
    

    const datos_factura = await directus
      .request(
        readItems("productos_factura", {
          fields: ["*", "seriales.*"],
          filter: {
            seriales: {
              serial: {
                _eq: serial,
              },
            },
          },
        })
      )
     

    console.log(datos_factura);
    if (datos_factura.data.length > 0) {
      console.log(datos_factura.data[0].id);

      const actualizaserial = await directus.request(updateItems("seriales", {
          filter: {
            serial: {
              _eq: serial,
            },
          },
        },
        {
          estado: 1,
        }))

      if (actualizaserial.data.length > 0) {
        console.log("actualizardata");
        if (serialnuevo.data.length > 0) {
          const actualizaserialnuevo = await directus
            .items("seriales")
            .updateOne(
              serialnuevo.data[0].id,

              {
                estado: 2,
              }
            );

          if (actualizaserialnuevo.id > 0) {
            console.log("actualizar factura");
            const datos_facturam = await directus
              .items("productos_factura")
              .updateOne(datos_factura.data[0].id, {
                seriales: {
                  create: [],
                  update: [
                    {
                      produtofactura: datos_factura.data[0].id,
                      id: actualizaserialnuevo.id,
                    },
                  ],
                  delete: [actualizaserial.data[0].id],
                },
              });

            if (datos_facturam.id > 0) {
              form.resetFields();
              Modal.success({ title: "se cambio el serial con exito" });
            }
          } else {
            Modal.error({
              title:
                "No fue posible actualizar el nuevo serial es probable que no exista ",
            });
          }
        } else {
          Modal.error({
            title:
              "No fue posible actualizar el nuevo serial es probable que no exista ",
          });
        }
      } else {
        Modal.error({
          title:
            "No fue posible actualizar serial anterior es probable que no exista ",
        });
      }
    } else {
      Modal.error({
        title: "No encontramos el serial en ninguna factura existente",
      });
    }
  };

  useEffect(() => {
    // buscarproductofacturado(serial, nuevo);
  }, []);

  const onFinish = (ev) => {
    console.log(ev);
    buscarproductofacturado(ev.serialanterior, ev.nuevo);
  };
  return (
    <Layouts>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <h3>
          Bienvenido al modulo de cambio de serial en factura,{" "}
          <span style={{ color: "red" }}>
            Por favor verifique los seriales antes de hacer el proceso de cambio
          </span>
        </h3>

        <Form.Item
          label="Serial anterior"
          name="serialanterior"
          rules={[
            {
              required: true,
              message: "debe ser un numero y no puede estar vacio",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Nuevo Serial"
          name="nuevo"
          rules={[
            {
              required: true,
              message: "debe ser un numero y no puede estar vacio",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Layouts>
  );
}

export default CambioSerial;
