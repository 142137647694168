import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layouts from '../../../components/Layouts';
import { getDirectusClient } from "../../../lib/directus";
import { readItems, createItem, updateItem, deleteItem } from '@directus/sdk'; // Importar deleteItem
import { Table, Button, Space, Modal, Form, Input, Select, message, Spin, Popconfirm } from 'antd'; // Importar message, Spin, Popconfirm

/**
 * Encuesta component displays a table of surveys and provides actions for creating, editing, deleting, and viewing surveys.
 * The surveys are fetched from the Directus API based on the user's bodega ID.
 */
const Encuesta = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // Get the Directus client and user's bodega ID from Redux store
  const directus = getDirectusClient();
  const bodegaId = useSelector(state => state.userdata.bodega);
  
  // State to store the surveys fetched from Directus API
  const [encuestas, setEncuestas] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentEncuesta, setCurrentEncuesta] = useState(null);
  const [bodegas, setBodegas] = useState([]);
  const [form] = Form.useForm(); // Usar form hook de Ant Design
  const [loading, setLoading] = useState(false); // Estado para mostrar el spinner

  /**
   * Fetches the surveys from Directus API based on the user's bodega ID and updates the state.
   */
  useEffect(() => {
    const fetchEncuestas = async () => {
      setLoading(true); // Mostrar spinner mientras se cargan las encuestas
      try {
        // Fetch surveys from Directus API
        const res = await directus.request(
          readItems("encuestas", {
            fields: ["*.*.*"], // Fetch all fields
            filter: {}, // No filter
          })
        );
        console.log(res);
        setEncuestas(res);
      } catch (error) {
        console.error('Error al recuperar las encuestas:', error);
      } finally {
        setLoading(false); // Ocultar spinner después de cargar las encuestas
      }
    };

    const fetchBodegas = async () => {
      try {
        const res = await directus.request(
          readItems("bodegas", {
            fields: ["id", "nombre"], // Fetch id and name
          })
        );
        setBodegas(res);
      } catch (error) {
        console.error('Error al recuperar las bodegas:', error);
      }
    };

    // Fetch surveys and bodegas
    if (bodegaId) {
      fetchEncuestas();
      fetchBodegas();
    }
  }, [bodegaId]);

  // Columns to display in the table
  const columns = [
    {
      title: 'Nombre', // Column title
      dataIndex: 'nombre', // Data field to display in the column
      key: 'nombre', // Unique key for the column
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
    },
    {
      title: 'Bodega',
      dataIndex: 'bodega',
      key: 'bodega',
      render: (bodega)=> bodega?.nombre? bodega.nombre:'No especificado',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => showEditModal(record)}>Editar</Button>
          <Popconfirm
            title="¿Estás seguro de eliminar esta encuesta?"
            onConfirm={() => handleDelete(record.id)}
            okText="Sí"
            cancelText="No"
          >
            <Button>Eliminar</Button>
          </Popconfirm>
          <Button onClick={() => navigate(`/encuesta/${record.id}`)}>Ver</Button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    setCurrentEncuesta(record);
    setIsEditModalVisible(true);
    form.setFieldsValue({
      nombre: record.nombre,
      descripcion: record.descripcion,
      bodega: record.bodega?.id,
    });
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsEditModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditModalVisible(false);
    form.resetFields(); // Resetear los campos del formulario al cancelar
  };

  const handleDelete = async (id) => {
    try {
      await directus.request(deleteItem('encuestas', id));
      message.success('Encuesta eliminada con éxito');
      await fetchEncuestas(); // Consultar de nuevo las encuestas
    } catch (error) {
      message.error('Error al eliminar la encuesta');
      console.error('Error al eliminar la encuesta:', error);
    }
  };

  const fetchEncuestas = async () => {
    setLoading(true); // Mostrar spinner mientras se cargan las encuestas
    try {
      const res = await directus.request(
        readItems("encuestas", {
          fields: ["*.*.*"], // Fetch all fields
          filter: {}, // No filter
        })
      );
      setEncuestas(res);
    } catch (error) {
      console.error('Error al recuperar las encuestas:', error);
    } finally {
      setLoading(false); // Ocultar spinner después de cargar las encuestas
    }
  };

  const onFinish = async (values) => {
    console.log('Formulario enviado:', values);
    try {
      const nuevaEncuesta = {
        nombre: values.nombre,
        descripcion: values.descripcion,
        bodega: values.bodega || currentEncuesta?.bodega?.id // Asegurarse de que la bodega no esté vacía
      };
      await directus.request(createItem('encuestas', nuevaEncuesta));
      message.success('Encuesta guardada con éxito');
      await fetchEncuestas(); // Consultar de nuevo las encuestas
      setIsModalVisible(false); // Cerrar el modal después de guardar
      form.resetFields(); // Resetear los campos del formulario después de guardar
    } catch (error) {
      message.error('Error al guardar la encuesta');
      console.error('Error al guardar la encuesta:', error);
    }
  };

  const onEditFinish = async (values) => {
    console.log('Formulario de edición enviado:', values);
    try {
      const updatedEncuesta = {
        nombre: values.nombre,
        descripcion: values.descripcion,
        bodega: values.bodega || currentEncuesta?.bodega?.id // Asegurarse de que la bodega no esté vacía
      };
      await directus.request(updateItem('encuestas', currentEncuesta.id, updatedEncuesta));
      message.success('Encuesta actualizada con éxito');
      await fetchEncuestas(); // Consultar de nuevo las encuestas
      setIsEditModalVisible(false); // Cerrar el modal después de actualizar
      form.resetFields(); // Resetear los campos del formulario después de actualizar
    } catch (error) {
      message.error('Error al actualizar la encuesta');
      console.error('Error al actualizar la encuesta:', error);
    }
  };

  return (
    <Layouts>
      <h1>Encuestas</h1>
      <Button type="primary" style={{ marginBottom: 16 }} onClick={showModal}>
        Crear Nueva Encuesta
      </Button>
      {loading ? (
        <Spin />
      ) : (
        <Table dataSource={encuestas} columns={columns} rowKey="id" />
      )}
      <Modal title="Crear Nueva Encuesta" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item label="Nombre de la Encuesta" name="nombre">
            <Input placeholder="Introduce el nombre de la encuesta" />
          </Form.Item>
          <Form.Item label="Descripción" name="descripcion">
            <Input.TextArea placeholder="Introduce una descripción" />
          </Form.Item>
          <Form.Item label="Selecciona una Bodega" name="bodega">
            <Select placeholder="Selecciona una bodega">
              {bodegas.map(bodega => (
                <Select.Option key={bodega.id} value={bodega.id}>
                  {bodega.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Guardar Encuesta
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal title="Editar Encuesta" visible={isEditModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Form layout="vertical" onFinish={onEditFinish} form={form} initialValues={currentEncuesta}>
          <Form.Item label="Nombre de la Encuesta" name="nombre">
            <Input />
          </Form.Item>
          <Form.Item label="Descripción" name="descripcion">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Selecciona una Bodega" name="bodega">
            <Select>
              {bodegas.map(bodega => (
                <Select.Option key={bodega.id} value={bodega.id}>
                  {bodega.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Actualizar Encuesta
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    
    </Layouts>
  );
};

export default Encuesta;

