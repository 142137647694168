import { useState, useEffect } from "react";
import { Form, Input, Button, Select, Space, InputNumber } from "antd";
import { getDirectusClient } from "../../../lib/directus";
import Layouts from "../../../components/Layouts";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { redirect, useNavigate } from "react-router-dom";
import { createItems, readItems } from "@directus/sdk";
const { Option } = Select;

const OrdersCreate = () => {
  const navigate = useNavigate();
  const [proveedores, setProveedores] = useState([]);
  const [productos, setProductos] = useState([]);
  const [estados, setEstado] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);

  const [cantidad, setCatidad] = useState(null);
  const [precio, setPrecio] = useState(null);
  const [desceunto, setDescuento] = useState(null);
  const [valosim, setValosim] = useState(null);
  const [iditem, setIditem] = useState(null);

  const [totalprice, setTotalprice] = useState(0);

  const [form] = useForm();

  useEffect(() => {
    async function fetchProveedores() {
      const directus = await getDirectusClient();
       await directus
        .request(
          readItems("proveedores", { limit: -1 })).then((proveedores) => {
          setProveedores(proveedores);
        });
      
    }

    async function fetchProductos() {
      const directus = await getDirectusClient();
      await directus
        .request(
          readItems("productos", { fields: "*.*", limit: -1 })).then((productos) => {
          setProductos(productos);
        });
      
    }

    async function fetchEstados() {
      const directus = await getDirectusClient();
      await directus
        .request(
          readItems("estados_ordenes_compra", { limit: -1 })).then((estados) => {
           setEstado(estados);
        });
     
    }

    fetchProveedores();
    fetchProductos();
    fetchEstados();
  }, []);

  const onFinish = async (values) => {
    const directus = await getDirectusClient();
    setLoading(true);

    console.log(values.productoss);

    try {
      const ordenDeCompra = {
        numero_factura: values.numero_factura,
        proveedor: values.proveedor,
        estado: 1,
        fecha: new Date().toISOString().split("T")[0],
        productos: values.productoss,
      };

       await directus
        .request(
          createItems("ordenes_decompra", ordenDeCompra)).then((orden) => {
           console.log("redirect");
      navigate(`/order_assign/${orden.id}`);
      console.log(orden);
        });
     
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleSearch = async (value) => {
    console.log("buscando");
    const directus = await getDirectusClient();
    setLoading(true);
    await directus.request(readItems("productos",{
      search: value,
      fields: "*.*",
    })).then((response) => {
      setProductos(response);
      setLoadings(false);
    });
    
  };

  useEffect(() => {
    console.log(precio);

    const p =
      parseFloat(precio !== null ? precio.target.value : 0) +
      parseFloat(
        valosim !== null
          ? valosim.target.value == ""
            ? 0
            : valosim.target.value
          : 0
      ) -
      parseFloat(desceunto !== null ? desceunto.target.value : 0);
    const fields = form.getFieldValue("productoss");
    if (fields) {
      console.log(iditem);
      if (typeof fields[iditem].precio_uni_final !== "undefined") {
        fields[iditem].precio_uni_final =
          fields[iditem].precio_compra +
          fields[iditem].valor_sim -
          fields[iditem].descuento_comercial;

        console.log(fields[iditem]);

        form.setFieldsValue({
          productoss: fields,
        });
      } else {
        fields[iditem].precio_uni_final = p;
        form.setFieldsValue({
          productoss: fields,
        });
      }
    }

    console.log(fields);
  }, [precio, desceunto, valosim]);

  useEffect(() => {
    const fields = form.getFieldValue("productoss");
    console.log(fields);

    let total = 0;
    if (fields) {
      fields.map((f) => {
        total = total + f.precio_uni_final * f.cantidad;
      });

      console.log(total);
      setTotalprice(total);
    }
  }, [form.getFieldValue("productoss")]);

  return (
    <Layouts>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Factura #"
          name="numero_factura"
          rules={[{ required: true, message: "Please input your factura!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="proveedor"
          label="Proveedor"
          rules={[{ required: true }]}
        >
          <Select>
            {proveedores.map((proveedor) => (
              <Option key={proveedor.id} value={proveedor.id}>
                {proveedor.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.List name="productoss">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: "flex",
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "producto_detalle"]}
                    label="Producto"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Selecciona un producto"
                      showSearch={true}
                      filterOption={false}
                      onSearch={handleSearch}
                      onChange={(value) => console.log(value)}
                      style={{ width: 200 }}
                    >
                      {productos.map((product) => (
                        <Select.Option key={product.id} value={product.id}>
                          {product.nombre}{" "}
                          <span style={{ color: "#20c77c", fontSize: 12 }}>
                            {" "}
                            {product.grupo.nombre}
                          </span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    label="Cantidad #"
                    initialValue={0}
                    name={[name, "cantidad"]}
                    onChange={(v) => {
                      setIditem(name);
                      setCatidad(v);
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your cantidad!",
                      },
                    ]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    label="descuento #"
                    type="number"
                    initialValue={0}
                    name={[name, "descuento_comercial"]}
                    onChange={(v) => {
                      setIditem(name);
                      console.log(v);
                      setDescuento(v);
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your cantidad!",
                      },
                    ]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    label="precio #"
                    type="number"
                    initialValue={0}
                    name={[name, "precio_compra"]}
                    onChange={(v) => {
                      setIditem(name);
                      console.log(v);
                      setPrecio(v);
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your cantidad!",
                      },
                    ]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    label="Valor sim #"
                    type="number"
                    name={[name, "valor_sim"]}
                    initialValue={0}
                    onChange={(v) => {
                      setIditem(name);
                      setValosim(v);
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your cantidad!",
                      },
                    ]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    label="final v #"
                    type="number"
                    defaulValue={0}
                    initialValue={0}
                    name={[name, "precio_uni_final"]}
                    onChange={(v) => {
                      setIditem(name);
                      //setValosim(v);
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your cantidad!",
                      },
                    ]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  agregar producto
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <p>Precio Total : {totalprice}</p>

        <Button type="primary" htmlType="submit">
          Crear orden de compra
        </Button>
      </Form>
    </Layouts>
  );
};

export default OrdersCreate;
