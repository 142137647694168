import React from "react";
import Layouts from "../../components/Layouts";
import { Button } from "antd";
import { Link } from "react-router-dom";

function Loan(props) {
  return (
    <Layouts>
      <Link to={"/loans_create"}>
        <Button>Crear prestamo </Button>
      </Link>
      <Link to={"/loan_repay"}>
        <Button>Devolver prestamo </Button>
      </Link>
    </Layouts>
  );
}

export default Loan;
