import React, { useEffect, useState } from "react";
import Layouts from "../../components/Layouts";
import { getDirectusClient } from "../../lib/directus";
import { Button, Col, Form, Row, Select, Table, Tabs } from "antd";
import Seriales from "./components/Seriales";
import { useDispatch, useSelector } from "react-redux";
import { productosfacturaAdd } from "../../features/productosFacrurar/productosSlice";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const arrayproductosfacturar = [];
function Factura(props) {
  const dispacth = useDispatch();
  const [vendedoresprestamo, setVendedoresprestamo] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const productofactura = useSelector((state) => state.productosfacturar);
  const [vendedorprestamo, setVendedorprestamo] = useState("");
  const [totalfactura, setTotalfactura] = useState(0);
  const [productosvendedor, setProductosvendedor] = useState([]);
  const [productotabla, setProductotabla] = useState([]);
  const [precios, setPrecios] = useState([]);
  //const [productofactura, setProductofactura] = useState([{}]);

  const [precioselect, setPrecioselect] = useState(0);
  const [productoselect, setProductoselect] = useState({});

  const [seriales, setSeriales] = useState([]);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const consultarVendedorprestamo = async (value) => {
    const directus = await getDirectusClient();
    //   setLoading(true);
    const response = await directus.items("directus_users").readByQuery({
      search: value,
      fields: [
        "id",
        "first_name",
        "last_name",
        "productos_prestados.id",
        "productos_prestados.nombre",
        "productos_prestados.preciosinimp",
        "productos_prestados.precios.*",
      ],
      filter: {
        role: {
          name: {
            _eq: "vendedor",
          },
        },
      },
    });
    setVendedoresprestamo(response.data);
  };

  const seleccionarvendedorprestamo = (ev) => {
    const vendedor = vendedoresprestamo.find((item) => item.id === ev);
    setVendedorprestamo(vendedor);
    setProductosvendedor(vendedor.productos_prestados);

    // consultarserialesvendedor(ev, vendedor.);
  };

  const selecionadoproducto = (ev) => {
    let precios = [];

    //console.log(precios);
    const pro = productosvendedor.find((item) => item.id === ev);

    form.resetFields(["precios"]);

    precios.push({
      precio: pro.preciosinimp,
      alias: "precioinicial",
      estado: true,
    });

    pro.precios.map((p) => {
      if (p.estado == true) {
        precios.push(p);
      }
    });

    //console.log(precios);

    setPrecios(precios);
    setProductoselect(pro);
    /*
    form.setFieldsValue({ precios: null });
    setPreciosselect(pro.precios);
    setProductoselet(pro);
   */
  };

  const selectprecio = (ev) => {
    setPrecioselect(parseFloat(ev));

    //console.log(productoselect);
    consultarserialesvendedor();
  };

  const consultarserialesvendedor = async () => {
    const directus = await getDirectusClient();

    await directus
      .items("seriales")
      .readByQuery({
        meta: "*",
        fields: ["*", "producto.grupo.impuesto", "producto.id"],
        limit: pagination.pageSize,
        offset: (pagination.current - 1) * pagination.pageSize,
        filter: {
          user: {
            id: {
              _eq: vendedorprestamo.id,
            },
          },
          producto: {
            id: {
              _eq: productoselect.id,
            },
          },
          estado: {
            id: {
              _eq: 3,
            },
          },
        },
      })
      .then((response) => {
        // //console.log(response);
        //   //console.log(response.meta);
        // setData(response.data);
        // //console.log(response.data);
        //  let productos = procesarseriales(response.data);
        //  //console.log(productos);

        // dispacth(productosvendedorAdd(productos));
        //setProductoslist(productos);
        setSeriales(response.data);
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: response.meta.filter_count,
        }));
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const fetchDataSerialespaginacion = async (v) => {
    //console.log(v);
    // //console.log("cambio pagina");
    const directus = await getDirectusClient();

    await directus
      .items("seriales")
      .readByQuery({
        meta: "*",
        fields: "*.*.*",
        limit: pagination.pageSize,
        offset: (pagination.current - 1) * pagination.pageSize,
        filter: {
          user: {
            id: {
              _eq: vendedorprestamo.id,
            },
          },
          producto: {
            id: {
              _eq: productoselect.id,
            },
          },
          estado: {
            id: {
              _eq: 3,
            },
          },
        },
      })
      .then((response) => {
        /*   console.log(response);
        //console.log(response.meta);
        setData(response.data);
        //console.log(response.data);
        let productos = procesarseriales(response.data);
        //console.log(productos);
        setProductoslist(productos);
*/
        setSeriales(response.data);

        setPagination((prevPagination) => ({
          ...prevPagination,
          total: response.meta.filter_count,
        }));
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const agregarserial = (serial, checked) => {
    if (checked) {
      let found = arrayproductosfacturar.find(
        (item) => item.producto === productoselect
      );

      //console.log(found);

      if (found) {
        //console.log("existe");

        let serialesex = found.seriales;

        const seriall = serialesex.find((item) => item.id === serial);

        if (seriall) {
          //console.log("serialexiste");
        } else {
          //console.log();

          const serialadd = seriales.find((item) => item.id === serial);
          //console.log(serialadd);
          //console.log(Array.isArray(serialesex));
          serialesex.push(serialadd);
          found.precio = precioselect;
        }
      } else {
        ////console.log("creando nuevo");
        const seriall = seriales.find((item) => item.id === serial);
        ////console.log(arrayproductosfacturar);
        arrayproductosfacturar.push({
          producto: productoselect,
          seriales: [seriall],
          precio: precioselect,
        });
      }
    } else if (checked == false) {
      ////console.log("existe quitar serial");
      let found = arrayproductosfacturar.find(
        (item) => item.producto === productoselect
      );
      let serialesex = found.seriales;

      const seriall = serialesex.find((item) => item.id === serial);

      //console.log("quitar serial");

      console.log(seriall);

      serialesex.splice(serialesex.indexOf(seriall), 1);

      console.log(serialesex.length == 0 ? "cero" : null);

      if (serialesex.length == 0) {
        arrayproductosfacturar.splice(arrayproductosfacturar.indexOf(found), 1);
      }

      console.log(arrayproductosfacturar);
    }

    //

    let arrtabla = [];
    let total = 0;
    arrayproductosfacturar.map((a) => {
      arrtabla.push({
        producto: a.producto.nombre,
        cantidad: a.seriales.length,
        precio: a.precio,
        total: a.precio * a.seriales.length,
      });
      total = total + a.precio * a.seriales.length;
    });
    setTotalfactura(total);
    addarray(arrtabla);
    console.log(arrayproductosfacturar);
    //  dispacth(productosfacturaAdd(arrayproductosfacturar));
    //  setProductos(procesarserialesf(arrayproductosfacturar));
  };

  const addarray = async (array) => {
    await setProductotabla(array);
  };

  const items = [
    {
      key: "1",
      label: `por Seriales`,
      children: (
        <Seriales
          productoselect={productoselect}
          seriales={seriales}
          setPagination={setPagination}
          current={pagination.current}
          totalpagina={pagination.total}
          agregarserial={agregarserial}
        />
      ),
    },
    {
      key: "2",
      label: `Por caja`,
      children: `Content of Tab Pane 2`,
    },
    {
      key: "3",
      label: `Por caja y grupo`,
      children: `Content of Tab Pane 3`,
    },
  ];

  useEffect(() => {
    fetchDataSerialespaginacion();
  }, [pagination.current]);

  const columns = [
    {
      title: "Producto",
      dataIndex: "producto",
      key: "producto",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
    },
    {
      title: "Precio",
      dataIndex: "precio",
      key: "precio",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  const Facturar = async () => {
    console.log(arrayproductosfacturar);

    const directus = await getDirectusClient();

    let productos = [];

    arrayproductosfacturar.map((item) => {
      let seriales = [];

      item.seriales.map((serial) => {
        seriales.push({
          id: serial.id,
          productofactura: "+",
        });
      });

      productos.push({
        nombre: item.producto.nombre,
        cantidad: item.seriales.length,
        precio_unitario: item.precio,
        producto: item.producto.id,
        seriales: {
          create: [],
          update: seriales,
          delete: [],
        },
      });
    });

    console.log(productos);
    /*
      item.seriales.map((serial) => {
        productos.push({
          serial: serial.serial,
          nombre: item.nombre,
          cantidad: 1,
          precio_unitario: item.precio,
          producto: serial.id,
        });
      });
    });*/

    directus
      .items("facturas")
      .createOne({
        vendedor_prestamo: vendedorprestamo.id,
        vendedor_factura: vendedorprestamo.id,
        productos: {
          create: productos,
        },
      })
      .then((factura) => {
        console.log(factura);
        if (factura.id > 0) {
          navigate("/visualizarfactura", {
            state: {
              factura: factura,
            },
          });
        }
      });
  };
  return (
    <Layouts>
      <Row gutter={16}>
        <Col>
          {" "}
          <Form form={form}>
            <Form.Item
              name="vendedor"
              label="Vendedor prestamo"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Selecciona un vendedor"
                showSearch={true}
                filterOption={false}
                onSearch={consultarVendedorprestamo}
                onChange={seleccionarvendedorprestamo}
                style={{ width: 200 }}
              >
                {vendedoresprestamo.map((vendedor) => (
                  <Option key={vendedor.id} value={vendedor.id} allowClear>
                    {vendedor.first_name}
                    <span style={{ color: "#20c77c", fontSize: 12 }}>
                      {vendedor.last_name}
                    </span>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="producto"
              label="Seleccione un producto"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Selecciona un Producto"
                filterOption={false}
                onChange={selecionadoproducto}
                style={{ width: 200 }}
              >
                {productosvendedor.map((producto) => (
                  <Option key={producto.id} value={producto.id} allowClear>
                    {producto.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="precios"
              label="Seleccione precio de producto"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Selecciona un Precio"
                showSearch={true}
                filterOption={false}
                onChange={selectprecio}
                style={{ width: 200 }}
              >
                {precios.map((precio) => (
                  <Option key={precio.alias} value={precio.precio} allowClear>
                    {`${precio.alias}   ${precio.precio}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>
        <Col>
          <Tabs defaultActiveKey="1" items={items} />
        </Col>
      </Row>
      <Table columns={columns} dataSource={productotabla} />
      <p>{totalfactura}</p>

      <Button
        onClick={() => {
          console.log(arrayproductosfacturar);
          Facturar();
        }}
      >
        Facturar // listo para enviar datos a guardar factura
      </Button>
    </Layouts>
  );
}

export default Factura;
