import { log } from '@craco/craco/lib/logger';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { Col, Row } from 'antd';
import React from 'react';
import { QRCode } from "react-qrcode-logo";



        

 

const  Pdfqr =   (props)=> {


    const img =  (c) => {
        console.log(c.id.toString());
        const canvas =  document.getElementById(c.id);
        
  
     
        const    img =  canvas.toDataURL("image/png");
            console.log(canvas);
    
       return  img; 
    }
    

  
    return (
      <Document>
        <Page size="letter">
          <div
            style={{
              marginLeft: 30,
              
              display: "flex",
              flexDirection: "row",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            {props.clientes.map((c) => {
              const i = img(c);
              return (
                <div
                  style={{
                    width: "30%",
                    border: "1px solid #ccc",
                  }}
                >
                  <Image key={c.id} style={{ width: 150 }} src={i} />
                  <Text>{c.nombre}</Text>
                </div>
              );
            })}
          </div>
        </Page>
      </Document>
    );
}

export default Pdfqr;