import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";

import "./App.css";
import Home from "./containers/Home";

import { counterAdd } from "./features/counter/counterSlice";
import Routers from "./Routers";

function App() {
  const counter = useSelector((state) => state.counter);

  const dispatch = useDispatch();

  //console.log(counter);

  const add = () => {
    dispatch(counterAdd([4, 5, 6]));
  };

  return (
    <BrowserRouter>
      <Routers />
    </BrowserRouter>
  );
}

export default App;
