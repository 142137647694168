import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "productosfacturar",
  initialState: [],
  reducers: {
    menuAdd: (state, action) => {
      console.log(action);
      return action.payload;
    },
  },
});
export const { menuAdd } = menuSlice.actions;
export default menuSlice.reducer;
