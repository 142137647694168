import { createSlice } from "@reduxjs/toolkit";

export const authdataSlice = createSlice({
  name: "userdata",
  initialState: {},
  reducers: {
    userdataAdd: (state, action) => {
      console.log(action);
      return action.payload;
    },
  },
});

export const { userdataAdd } = authdataSlice.actions;
export default authdataSlice.reducer;
