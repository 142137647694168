import { Form, Modal, Input, Select, Button, Checkbox, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { getDirectusClient } from "../../../lib/directus";
import { useForm } from "antd/es/form/Form";
import { readItems, updateItem } from "@directus/sdk";
const { Option } = Select;

function Editproduct({ openedit, editModal, getProducts, productselect }) {
  //const [openadd, setOpenadd] = useState(false);
  const [form] = Form.useForm();
  const [grupos, setGrupos] = useState([]);
  const [estadoproducto, setEstadoproducto] = useState([]);

  const onFinish = async (values) => {
    const directus = await getDirectusClient();
    directus
      .request(
      updateItem("productos",parseInt(productselect.id), {
        referencia: values.referencia,
        nombre: values.nombre,
        grupo: values.grupo,
        preciosinimp: values.preciosinimp,
        estado: values.estado,
        min: values.min,
        precios: values.precios,
      }))
      .then((res) => {
        getProducts();
        editModal();
        form.resetFields();
      })
      .then((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log(productselect);
    form.setFieldValue("referencia", productselect.referencia);
    form.setFieldValue("nombre", productselect.nombre);
    form.setFieldValue("grupo", productselect.grupo?.id);
    form.setFieldValue("preciosinimp", productselect.preciosinimp);
    form.setFieldValue("estado", parseInt(productselect.estado?.id));
    form.setFieldValue("min", productselect.min);
    form.setFieldValue("precios", productselect.precios);
  }, [productselect]);

  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      note: "Hello world!",
      gender: "male",
    });
  };
  const getGrupos = async () => {
    const directus = await getDirectusClient();
    directus
      .request(readItems("grupos"))
      .then((res) => {
        console.log(res);
        setGrupos(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEstadoproducto = async () => {
    const directus = await getDirectusClient();
    directus
      .request(
      readItems("estadoproducto"))
      .then((res) => {
        console.log(res);
        setEstadoproducto(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getGrupos();
    getEstadoproducto();
  }, []);

  return (
    <Modal
      title="Crear producto"
      open={openedit}
      onCancel={editModal}
      footer={false}
    >
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
      >
        <Form.Item
          name="referencia"
          label="Referencia"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="nombre"
          label="Nombre deproducto"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="grupo"
          label="Grupo"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="selecione un grupo" allowClear>
            {grupos.map((grupo) => {
              return <Option value={grupo.id}>{grupo.nombre}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="preciosinimp"
          label="Precio sin imp"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="estado"
          label="Estado de producto"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="seleccione un estado " allowClear>
            {estadoproducto.map((estadoproducto) => {
              return (
                <Option value={estadoproducto.id}>
                  {estadoproducto.nombre}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name="min"
          label="Min"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox></Checkbox>
        </Form.Item>

        <h3>Añadir precios </h3>

        <Form.List name="precios">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "precio"]}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Precio" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "alias"]}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Alias" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Agregar nuevo precio
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Crear
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Editproduct;
