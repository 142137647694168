
const validarpermiso = (collection, action, permisos) => {
     
       const permisosresult =   permisos.filter(
           (permission) =>
             permission.collection === collection && permission.action === action
       )
    
    if (permisosresult.length > 0) {
      return true;
    } else {
      return false;
    }
       
}
export default validarpermiso;
