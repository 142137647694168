import React, { useEffect } from "react";
import Layouts from "../../components/Layouts";
import { getDirectusClient } from "../../lib/directus";

function Devolverfactura(props) {
  const directus = getDirectusClient();

  const consultafactura = async (idfactura) => {
    await directus
      .items("facturas")
      .readOne(idfactura, {
        fields: ["*.*"],
      })
      .then((res) => {
        console.log(res.productos);
      });
  };
  useEffect(() => {
    consultafactura(59);
  }, []);
  return <Layouts>devolucion de factura</Layouts>;
}

export default Devolverfactura;
