import React, { useEffect, useState } from "react";
import Layouts from "../../components/Layouts";
import { getDirectusClient } from "../../lib/directus";
import { Button, Modal, Space, Table } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Createseller from "./components/Createclient";
import Editseller from "./components/Editclient";
import { deleteItem, readUsers } from "@directus/sdk";
const Clients = (props) => {
  const [datasellers, setDatasellers] = useState([]);
  const [opencreate, setOpencreate] = useState(false);
  const [openupdate, setOpenupdate] = useState(false);
  const [selectseller, setSelectseller] = useState({});
  const columns = [
    {
      title: "Nombre",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Apellido",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Documento",
      dataIndex: "documento",
      key: "documento",
    },
    {
      title: "Acciones",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={async () => {
              const directus = await getDirectusClient();
              Modal.confirm({
                title: "Eliminar usuario",
                icon: <ExclamationCircleFilled />,
                content: "Desea eliminar este usuario?",
                onOk() {
                  directus
                    .request(deleteItem("directus_users", record.id))
                    .then((res) => {
                      getSellers();
                    });
                },
                onCancel() {},
              });
            }}
          >
            eliminar
          </Button>

          <Button
            onClick={() => {
              console.log(record);
              setSelectseller(record);
              setOpenupdate(true);
            }}
          >
            Editar
          </Button>
        </Space>
      ),
    },
  ];
  const getSellers = async () => {
    const directus = await getDirectusClient();

    directus.request(
      readUsers({
        filter: {
          role: {
            name: {
              _eq: "clientes"
            }
          }
        },
      }))
      .then((res) => {
        console.log(res);
        setDatasellers(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSellers();
  }, []);

  const closeCreate = () => {
    setOpencreate(false);
  };

  const closeUpdate = () => {
    setOpenupdate(false);
  };
  return (
    <Layouts>
     
      <h3>Usuarios y clientes</h3>
      <Button
        onClick={() => {
          setOpencreate(true);
        }}
      >
        crear usuario o cliente
      </Button>
      { console.log("vendedores", datasellers)}
      <Table columns={columns} dataSource={datasellers} />
      <Createseller
        getSellers={getSellers}
        opencreate={opencreate}
        closeCreate={closeCreate}
      />
      <Editseller
        getSellers={getSellers}
        opencreate={openupdate}
        closeUpdate={closeUpdate}
        selectseller={selectseller}
        setSelectseller={setSelectseller}
      />
    </Layouts>
  );
};

export default Clients;
