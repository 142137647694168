import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { readItems } from '@directus/sdk';
import { getDirectusClient } from "../../../lib/directus";
import { Table, Button, Space, Modal, Form, Input, Select, message, Spin } from 'antd';
import Layouts from '../../../components/Layouts';

const Respuestas = () => {
  const { id } = useParams();
  const directus = getDirectusClient();
  const [preguntasYRespuestas, setPreguntasYRespuestas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPreguntasYRespuestas = async () => {
      setLoading(true);
      try {
        const res = await directus.request(
          readItems("preguntas", {
            fields: ["*.*.*", "respuestas.*.*.*"], // Fetch all fields
            filter: {
              encuesta: {
                id: id
              }
            }
          })
        );
          console.log(res);
        setPreguntasYRespuestas(res);
      } catch (error) {
        console.error('Error al recuperar las preguntas y respuestas:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPreguntasYRespuestas();
  }, [id]);

  return (
    <Layouts>
      <Spin spinning={loading}>
        {/* Aquí puedes renderizar las preguntas y respuestas */}
              {preguntasYRespuestas.map(pregunta => {
          return (
            <div key={pregunta.id}>
              <h2>{pregunta.pregunta}</h2>
                  {pregunta.tipo_respuesta === "texto" ? (
                      
                      <>
                             <p>textto</p>
                <ul>
                  {pregunta.respuestas.map((respuesta) => (
                    <li key={respuesta.id}>{respuesta.texto}</li>
                  ))}
                </ul>
                      </>
               
              ) : pregunta.tipo === "numero" ? (
                <ul>
                  {pregunta.respuestas.map((respuesta) => (
                    <li key={respuesta.id}>{respuesta.numero}</li>
                  ))}
                </ul>
              ) : pregunta.tipo === "multiple" ? (
                <p>{pregunta.respuestas.length}</p>
              ) : pregunta.tipo === "unica" ? (
                <p>{pregunta.respuestas.length}</p>
              ) : null}
            </div>
          );
        } )}
      </Spin>
    </Layouts>
  );
};

export default Respuestas;
