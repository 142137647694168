import React, { useEffect, useState } from 'react';
import Layouts from '../../../components/Layouts';
import { getDirectusClient } from '../../../lib/directus';
import { readItems, readUsers } from '@directus/sdk';
import { useSelector } from 'react-redux';
import { Alert, Button, Space, Spin, Tree } from 'antd';
import { readUser } from '@directus/sdk';
import { DatePicker, Modal, Select } from "antd";
import moment from 'moment';
import Chart from 'react-google-charts';
const { RangePicker } = DatePicker;
const {DirectoryTree} = Tree

function Reportetree(props) {

  const [hoys, setHoys] = useState(true)
  const [userselect, setUserselect] = useState()
  const [total, setTotal] =useState(0)
  const userdata = useSelector((state) => state.userdata)
  const [datareporte, setDatareporte] = useState([]);
  const [loanding, setLoanding] = useState(false);
const [estadodatapicker, setestadodatapicker] =useState(false)
    const [treeData, setTreeData] = useState([


    ]);
  const [tipopersonal, setTipopersonal] = useState("tropas")
  const [estados, setEstados] = useState([])
 const [startDate, setStartDate] = useState("2023-01-01");
 var hoy = moment();
 var hoyf = hoy.add(1, "d").format("YYYY-MM-DD");

 const [endDate, setEndDate] = useState(hoyf);
  const getEstados = () => {
    const directus = new getDirectusClient();
     directus
       .request(
         readItems("estados_gestion", {
           fields: ["*.*"],
         })
       )
       .then((res) => {
        // console.log(resPagina);
         // Aquí puedes agregar la lógica para manejar los datos paginados, por ejemplo, concatenarlos con los ya existentes
         setEstados(res);
       })
       .catch((err) => console.log(err));
  }
  const getUsers = () => {
      
    let arraycompleto = []
         
        const directus = new getDirectusClient();
        

        
    if (userdata.role.name=="") {
      
    } else if (userdata.role.name == "") {
    }
            directus
            .request(
                readUsers({
                    fields: ["*","role.*", "subordinados.*.*"],
                    filter: {
                        superior: {
                            id: {
                                _eq: userdata.id
                            }
                        }
                    }
                }))
                    .then((values) => {
                        
                    let arraysimple = []
                
                          values.map((value) => {
                             // console.log(value);
                            if (value.role.name == "coordinador_ruta") {
                              console.log("coordinadores", value);


                              //Generando supervisores
                              let arraysub = []
                              console.log("total", value.subordinados.length);
                              value.subordinados.map((sub) => {

                                let promoarr = []
                                sub.subordinados.map((promo) => {
                                  promoarr.push({
                                      
                                    title: promo.first_name ? promo.first_name : promo,
                                    key: promo.id ? promo.id : promo,
                                  });
                                })


                                console.log("sub", sub.id);
                                arraysub.push({
                                  title: sub.first_name,
                                  key: sub.id,
                                  children: promoarr
                                });
                              });
                              //Generando //coordinadores
                              setTimeout(() => {
                                console.log("subs", arraysub);
                                arraysimple.push({
                                  title: value.first_name,
                                  key: value.id,
                                  // disabled: true,
                                  children: arraysub,
                                });
                              }, 0);

                        
                            } else if (
                              value.role.name == "supervisor_ruta"
                            ) {
                              console.log("supervisores", value);
                              //Generando supervisores
                              let arraysub = [];
                              console.log("total", value.subordinados.length);
                              value.subordinados.map((sub) => {

                                let promoarr = [];
                                sub.subordinados.map((promo) => {
                                  promoarr.push({
                                    title: promo.first_name
                                      ? promo.first_name
                                      : promo,
                                    key: promo.id ? promo.id : promo,
                                  });
                                });

                                console.log("sub", sub.id);
                                arraysub.push({
                                  title: sub.first_name,
                                  key: sub.id,
                                  children: promoarr,
                                });
                              });
                              //Generando //coordinadores
                              setTimeout(() => {
                                console.log("subs", arraysub);
                                arraysimple.push({
                                  title: value.first_name,
                                  key: value.id,
                                  // disabled: true,
                                  children: arraysub,
                                });
                              }, 0);
                            } else if (
                              value.role.name == "vendedor"
                            ) { 
                               let arraysub = [];
                               console.log("total", value.subordinados.length);
                               value.subordinados.map((sub) => {
                                 let promoarr = [];
                                 sub.subordinados.map((promo) => {
                                   promoarr.push({
                                     title: promo.first_name
                                       ? promo.first_name
                                       : promo,
                                     key: promo.id ? promo.id : promo,
                                   });
                                 });

                                 console.log("sub", sub.id);
                                 arraysub.push({
                                   title: sub.first_name,
                                   key: sub.id,
                                   children: promoarr,
                                 });
                               });
                               //Generando //coordinadores
                               setTimeout(() => {
                                 console.log("subs", arraysub);
                                 arraysimple.push({
                                   title: value.first_name,
                                   key: value.id,
                                   // disabled: true,
                                   children: arraysub,
                                 });
                               }, 0);
                            }
                          })
                      
                      
                      setTimeout(() => {
                            console.log("simple", arraysimple);
                            arraycompleto.push({
                              title: userdata.first_name,
                              key: userdata.id,
                              disabled: userdata.role.name=="gerente"?true:false,
                              children: arraysimple,
                            });
                          }, 0);
              

                      
                      setTimeout(() => {
                        console.log("total", arraycompleto);
                        setTreeData(arraycompleto)
                      }, 0);
                })
          .catch((err) => {
            console.log(err);
          });  
        

    }

    useEffect(() => {
      getUsers()
      getEstados()

       let fecha = moment().format("YYYY-MM-DD");
       let fechayhorainicial = fecha + " " + "12:00:00";
       let fechayhorafinal = fecha + " " + "23:59:00";
      
       setStartDate(fechayhorainicial);
       setEndDate(fechayhorafinal);
    }, [])
  
  
  const selectTree = (ev) => {
    setLoanding(true)
    setestadodatapicker(true)
   console.log(ev);
   setUserselect(ev[0])
   cargarReporte(ev[0], startDate, endDate)
  }
  
  const cargarReporte = (userselect, startDate, endDate) => {

        const datachart = [["estado", "Cantidad"]];
    const directus = new getDirectusClient();
    directus
      .request(
        readUser(userselect, {
          fields: ["*", "role.*", "subordinados.*.*"],
          filter: {},
        })
      )
      .then((values) => {
        console.log(values.role);
        if (values.role.name == "vendedor") {

          //consulta como Venedor
                   let objf = {
                     date_created: {
                       _between: [startDate, endDate],
                     },
                     vendedor: {
                         id: userselect,
                     },
                   };

                   directus
                     .request(
                       readItems("gestion_cliente_rutero", {
                         fields: ["*.*"],
                         aggregate: {
                           count: ["*"],
                         },
                         filter: objf,
                       })
                     )
                     .then((res) => {
                       console.log(res);
                       setTotal(res[0].count);
                     })
                     .catch((err) => console.log(err));

        

      

                   directus
                     .request(
                       readItems("estados_gestion", {
                         fields: ["*"],
                         limit: -1,
                         sort: "id",
                       })
                     )
                     .then((estados) => {
                       //  datachart.push()

                       estados.map((estado) => {
                         var objf2 = {};

                         objf2 = {
                           date_created: {
                             _between: [startDate, endDate],
                           },
                           vendedor: {
                             id: userselect,
                           },
                           estado: estado.id,
                         };

                         directus
                           .request(
                             readItems("gestion_cliente_rutero", {
                               fields: ["*.*"],
                               aggregate: {
                                 count: ["*"],
                               },
                               filter: objf2,

                               limit: -1,
                             })
                           )
                           .then((data) => {
                             datachart.push([
                               estado.estado,
                               parseInt(data[0].count),
                             ]);
                           });
                       });
                     });

                   console.log(datachart);
          setTimeout(() => {
                     setLoanding(false)
                     setDatareporte(datachart);
                   }, 2000);
          
        } else if (values.role.name == "supervisor_ruta") {
          console.log("supervisor");
//Consulta gestiones de supervisor
          let objf = {
            date_created: {
              _between: [startDate, endDate],
            },
            vendedor: {
              superior: {
                id: userselect,
              },
            },
          };

          directus
            .request(
              readItems("gestion_cliente_rutero", {
                fields: ["*.*"],
                aggregate: {
                  count: ["*"],
                },
                filter: objf,
              })
            )
            .then((res) => {
              console.log(res);
              setTotal(res[0].count);
            })
            .catch((err) => console.log(err));






            directus
              .request(
                readItems("estados_gestion", {
                  fields: ["*"],
                  limit: -1,
                  sort: "id",
                })
              )
              .then((estados) => {
                //  datachart.push()

                estados.map((estado) => {
                  var objf2 = {};

                  objf2 = {
                    date_created: {
                      _between: [startDate, endDate],
                    },
                    vendedor: {
                      superior: {
                        id: userselect,
                      },
                    },
                    estado: estado.id,
                  };

                  directus
                    .request(
                      readItems("gestion_cliente_rutero", {
                        fields: ["*.*"],
                        aggregate: {
                          count: ["*"],
                        },
                        filter: objf2,

                        limit: -1,
                      })
                    )
                    .then((data) => {
                      datachart.push([estado.estado, parseInt(data[0].count)]);
                    });
                });
              });
          
          
    console.log(datachart);
          setTimeout(() => {
       setLoanding(false);
      setDatareporte(datachart);
    }, 2000);

        } else if (values.role.name == "coordinador_ruta") {
          //Consulta gestiones de supervisor
          let objf = {
            date_created: {
              _between: [startDate, endDate],
            },
            vendedor: {
              superior: {
                superior: {
                   id: userselect,
                }
               
              },
            },
          };

          directus
            .request(
              readItems("gestion_cliente_rutero", {
                fields: ["*.*"],
                aggregate: {
                  count: ["*"],
                },
                filter: objf,
              })
            )
            .then((res) => {
              console.log(res);
              setTotal(res[0].count);
            })
            .catch((err) => console.log(err));

          directus
            .request(
              readItems("estados_gestion", {
                fields: ["*"],
                limit: -1,
                sort: "id",
              })
            )
            .then((estados) => {
              //  datachart.push()

              estados.map((estado) => {
                var objf2 = {};

                objf2 = {
                  date_created: {
                    _between: [startDate, endDate],
                  },
                  vendedor: {
                    superior: {
                      superior: {
                        id: userselect,
                      },
                    },
                  },
                  estado: estado.id,
                };

                directus
                  .request(
                    readItems("gestion_cliente_rutero", {
                      fields: ["*.*"],
                      aggregate: {
                        count: ["*"],
                      },
                      filter: objf2,

                      limit: -1,
                    })
                  )
                  .then((data) => {
                    datachart.push([estado.estado, parseInt(data[0].count)]);
                  });
              });
            });

          console.log(datachart);
          setTimeout(() => {
             setLoanding(false);
            setDatareporte(datachart);
          }, 2000);
        }
      });
  };
  
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setLoanding(true)
      setHoys(false)
     setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
      cargarReporte(userselect, dateStrings[0], dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };
    return (
      <Layouts>
        <Space>
          <Button
            onClick={() => {
              let fecha = moment().format("YYYY/MM/DD");
              let fechayhorainicial = fecha + " " + "12:00:00";
              let fechayhorafinal = fecha + " " + "23:59:00";
              console.log(fechayhorainicial);
              setStartDate(fechayhorainicial);
              setEndDate(fechayhorafinal);
              console.log(fechayhorafinal);
              setHoys(true);
              if (userselect) {
                cargarReporte(userselect, fechayhorainicial, fechayhorafinal);
              }
              // cargarReporte(userselect,fechayhorainicial, fechayhorafinal);
            }}
          >
            Hoy
          </Button>

          <RangePicker
            disabled={!estadodatapicker}
            format={{ format: "YYYY/MM/DD HH:mm:ss", type: "mask" }}
            onChange={onRangeChange}
            placeholder={["Fecha inicial", "Feha final"]}
          />
        </Space>
        <br></br>
        <Space>
          <DirectoryTree
            showLine={true}
            onSelect={selectTree}
            onExpand={(e) => console.log()}
            treeData={treeData}
          />
        </Space>

        <br />

        <Spin spinning={loanding} delay={500}>
          <Space>
            <h3>
              {hoys
                ? "Mostrando datos de hoy: " + moment().format("YYYY-MM-DD")
                : "Mostrando filtro entre fechas " + startDate + "--" + endDate}
            </h3>
            <h3 style={{ color: "green" }}>Total: {total}</h3>
          </Space>
          <Chart
            chartType="PieChart"
            width={"100%"}
            height={"400px"}
            loader={<div>Cargando gráfica</div>}
            data={datareporte}
            options={{
              chart: {
                title: "Reporte de portabilidades por estado",
              },
            }}
          />
        </Spin>
      </Layouts>
    );
}

export default Reportetree;