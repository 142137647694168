import React, { useEffect, useState } from "react";
import Layouts from "../../components/Layouts";
import { useLocation } from "react-router-dom";
import { getDirectusClient } from "../../lib/directus";
import Icon, {
  StopOutlined,
  CheckOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import { Popover, Button, Table, Row, Col } from "antd";
import Column from "antd/es/table/Column";
function Permisions(props) {
  const location = useLocation();
  console.log(location.state.id);
  const [collections, setCollections] = useState([]);
  const [permisions, setPermisions] = useState([]);

  //

  const getCollections = async () => {
    const directus = await getDirectusClient();

    directus
      .items("directus_collections")
      .readByQuery({
        fields: ["*.*"],
        limit: 200,
        filter: {
          role: {
            _eq: location.state.id,
          },
        },
      })
      .then((res) => {
        console.log(res);

        const filtrado = res.data.filter(
          (modelo) =>
            modelo.meta.system === undefined && modelo.meta.note === "app"
        );

        setCollections(filtrado);
      })
      .catch((err) => console.log(err));
  };

  const getPermisions = async () => {
    const directus = await getDirectusClient();

    directus
      .items("directus_permissions")
      .readByQuery({
        fields: ["*.*"],
        limit: 200,
        filter: {
          role: {
            _eq: location.state.id,
          },
        },
      })
      .then((res) => {
        console.log(res);
        setPermisions(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPermisions();
    getCollections();
    // console.log(validarpermisosdecoleccion());
  }, []);

  const validarpermisosdecoleccioncrear = (collection) => {
    const permision = permisions
      .filter((permision) => permision.collection === collection.collection)
      .find((permision) => permision.action === "create");

    console.log(permision);

    if (permision !== undefined) {
      if (permision.action === "create") {
        return (
          <Popover
            trigger={"click"}
            content={
              <>
                <Button
                  onClick={async () => {
                    const directus = await getDirectusClient();
                    directus
                      .items("directus_permissions")
                      .deleteOne(permision.id)
                      .then((res) => {
                        console.log(res);
                        getPermisions();
                        getCollections();
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  Sin Acceso
                </Button>
              </>
            }
          >
            <CheckOutlined
              onClick={() => console.log("modificar")}
              key={permision.id}
            />
          </Popover>
        );
      } else {
        return (
          <Popover
            trigger={"click"}
            content={
              <>
                <Button
                  onClick={async () => {
                    const directus = await getDirectusClient();
                    directus
                      .items("directus_permissions")
                      .createOne({
                        role: location.state.id,
                        collection: collection.collection,
                        action: "create",
                        fields: "*",
                        permissions: {},
                        validation: {},
                      })
                      .then((res) => {
                        getPermisions();
                        getCollections();
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  Acceso Total
                </Button>
              </>
            }
          >
            <StopOutlined onClick={() => console.log("modificar")} />
          </Popover>
        );
      }
    } else {
      return (
        <Popover
          trigger={"click"}
          content={
            <>
              <Button
                onClick={async () => {
                  const directus = await getDirectusClient();
                  directus
                    .items("directus_permissions")
                    .createOne({
                      role: location.state.id,
                      collection: collection.collection,
                      action: "create",
                      fields: "*",
                      permissions: {},
                      validation: {},
                    })
                    .then((res) => {
                      getPermisions();
                      getCollections();
                    })
                    .catch((err) => console.log(err));
                }}
              >
                Acceso Total
              </Button>
            </>
          }
        >
          <StopOutlined onClick={() => console.log("modificar")} />
        </Popover>
      );
    }
  };

  // funcion para validar read
  const validarpermisosdecoleccionread = (collection) => {
    const permision = permisions
      .filter((permision) => permision.collection === collection.collection)
      .find((permision) => permision.action === "read");

    console.log(permision);
    if (permision !== undefined) {
      if (permision.action === "read") {
        return (
          <Popover
            trigger={"click"}
            content={
              <>
                <Button
                  onClick={async () => {
                    const directus = await getDirectusClient();
                    directus
                      .items("directus_permissions")
                      .deleteOne(permision.id)
                      .then((res) => {
                        console.log(res);
                        getPermisions();
                        getCollections();
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  Sin Acceso
                </Button>
              </>
            }
          >
            <CheckOutlined
              onClick={() => console.log("modificar")}
              key={permision.id}
            />
          </Popover>
        );
      } else {
        return (
          <Popover
            trigger={"click"}
            content={
              <>
                <Button
                  onClick={async () => {
                    const directus = await getDirectusClient();
                    directus
                      .items("directus_permissions")
                      .createOne({
                        role: location.state.id,
                        collection: collection.collection,
                        action: "read",
                        fields: "*",
                        permissions: {},
                        validation: {},
                      })
                      .then((res) => {
                        getPermisions();
                        getCollections();
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  Acceso Total
                </Button>
              </>
            }
          >
            <StopOutlined onClick={() => console.log("modificar")} />
          </Popover>
        );
      }
    } else {
      return (
        <Popover
          trigger={"click"}
          content={
            <>
              <Button
                onClick={async () => {
                  const directus = await getDirectusClient();
                  directus
                    .items("directus_permissions")
                    .createOne({
                      role: location.state.id,
                      collection: collection.collection,
                      action: "read",
                      fields: "*",
                      permissions: {},
                      validation: {},
                    })
                    .then((res) => {
                      getPermisions();
                      getCollections();
                    })
                    .catch((err) => console.log(err));
                }}
              >
                Acceso Total
              </Button>
            </>
          }
        >
          <StopOutlined onClick={() => console.log("modificar")} />
        </Popover>
      );
    }
  };

  // funcion para validar update
  const validarpermisosdecoleccionupdate = (collection) => {
    const permision = permisions
      .filter((permision) => permision.collection === collection.collection)
      .find((permision) => permision.action === "update");

    console.log(permision);
    if (permision !== undefined) {
      if (permision.action === "update") {
        return (
          <Popover
            trigger={"click"}
            content={
              <>
                <Button
                  onClick={async () => {
                    const directus = await getDirectusClient();
                    directus
                      .items("directus_permissions")
                      .deleteOne(permision.id)
                      .then((res) => {
                        console.log(res);
                        getPermisions();
                        getCollections();
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  Sin Acceso
                </Button>
              </>
            }
          >
            <CheckOutlined
              onClick={() => console.log("modificar")}
              key={permision.id}
            />
          </Popover>
        );
      } else {
        return (
          <Popover
            trigger={"click"}
            content={
              <>
                <Button
                  onClick={async () => {
                    const directus = await getDirectusClient();
                    directus
                      .items("directus_permissions")
                      .createOne({
                        role: location.state.id,
                        collection: collection.collection,
                        action: "update",
                        fields: "*",
                        permissions: {},
                        validation: {},
                      })
                      .then((res) => {
                        getPermisions();
                        getCollections();
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  Acceso Total
                </Button>
              </>
            }
          >
            <StopOutlined onClick={() => console.log("modificar")} />
          </Popover>
        );
      }
    } else {
      return (
        <Popover
          trigger={"click"}
          content={
            <>
              <Button
                onClick={async () => {
                  const directus = await getDirectusClient();
                  directus
                    .items("directus_permissions")
                    .createOne({
                      role: location.state.id,
                      collection: collection.collection,
                      action: "update",
                      fields: "*",
                      permissions: {},
                      validation: {},
                    })
                    .then((res) => {
                      getPermisions();
                      getCollections();
                    })
                    .catch((err) => console.log(err));
                }}
              >
                Acceso Total
              </Button>
            </>
          }
        >
          <StopOutlined onClick={() => console.log("modificar")} />
        </Popover>
      );
    }
  };

  const validarpermisosdecolecciondelete = (collection) => {
    const permision = permisions
      .filter((permision) => permision.collection === collection.collection)
      .find((permision) => permision.action === "delete");

    console.log(permision);
    if (permision !== undefined) {
      if (permision.action === "delete") {
        return (
          <Popover
            trigger={"click"}
            content={
              <>
                <Button
                  onClick={async () => {
                    const directus = await getDirectusClient();
                    directus
                      .items("directus_permissions")
                      .deleteOne(permision.id)
                      .then((res) => {
                        console.log(res);
                        getPermisions();
                        getCollections();
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  Sin Acceso
                </Button>
              </>
            }
          >
            <CheckOutlined
              onClick={() => console.log("modificar")}
              key={permision.id}
            />
          </Popover>
        );
      } else {
        return (
          <Popover
            trigger={"click"}
            content={
              <>
                <Button
                  onClick={async () => {
                    const directus = await getDirectusClient();
                    directus
                      .items("directus_permissions")
                      .createOne({
                        role: location.state.id,
                        collection: collection.collection,
                        action: "delete",
                        fields: "*",
                        permissions: {},
                        validation: {},
                      })
                      .then((res) => {
                        getPermisions();
                        getCollections();
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  Acceso Total
                </Button>
              </>
            }
          >
            <StopOutlined onClick={() => console.log("modificar")} />
          </Popover>
        );
      }
    } else {
      return (
        <Popover
          trigger={"click"}
          content={
            <>
              <Button
                onClick={async () => {
                  const directus = await getDirectusClient();
                  directus
                    .items("directus_permissions")
                    .createOne({
                      role: location.state.id,
                      collection: collection.collection,
                      action: "delete",
                      fields: "*",
                      permissions: {},
                      validation: {},
                    })
                    .then((res) => {
                      getPermisions();
                      getCollections();
                    })
                    .catch((err) => console.log(err));
                }}
              >
                Acceso Total
              </Button>
            </>
          }
        >
          <StopOutlined onClick={() => console.log("modificar")} />
        </Popover>
      );
    }
  };

  return (
    <Layouts>
      <div>
        <h1>
          {" "}
          Permisos Rol{" "}
          <span style={{ color: "green" }}>
            {location.state.role.name}
          </span>{" "}
        </h1>
        <Row>
          <Col span={12}>
            <h3>Modulos </h3>
          </Col>
          <Col
            span={12}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <p>Crear</p>
            <p>Leer</p>
            <p>Editar</p>
            <p>Eliminar</p>
          </Col>
        </Row>

        {collections.map((collection) => (
          <Row key={collection.collection}>
            <Col span={12}>
              <div>
                <h2>{collection.collection}</h2>
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              {validarpermisosdecoleccioncrear(collection)}
              {validarpermisosdecoleccionread(collection)}
              {validarpermisosdecoleccionupdate(collection)}
              {validarpermisosdecolecciondelete(collection)}
            </Col>
          </Row>
        ))}
      </div>
    </Layouts>
  );
}

export default Permisions;
