import React, { Component, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import CSVReader from "react-csv-reader";
import { getDirectusClient } from "../../../lib/directus";
import { Button, Modal, Space, Spin } from "antd";

const Addfileproducts = (props) => {
  const fileselect = useRef();
  const [totalerrores, setTotalErrores] = useState(0);
  const [loanding, setLoanding] = useState(false);
  const [archivocargado, setArchivocargado] = useState(false);
  const papaparseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  useEffect(() => {
    if (props.modal == false) {
      fileselect.current.value = "";
    }
  }, [props.modal]);

  const handleForce = async (data, fileInfo) => {
    var largodedatos = data.length;
    var datoprocesados = 0;
    setLoanding(true);
    const directus = await getDirectusClient();

    const errores = [];
    const preciot =
      parseFloat(props.producto.producto_detalle.preciosinimp) +
      (parseFloat(props.producto.producto_detalle.preciosinimp) * 5) / 100;

    const f =
      (parseFloat(props.producto.producto_detalle.preciosinimp) * 5) / 100;
    console.log(preciot);

    if (
      data.length > props.producto.cantidad ||
      data.length > props.producto.cantidad - props.producto.seriales.length
    ) {
      Modal.error({
        title: "Validacion de tamaño de archivo",
        content: `Validamos el archivo y contiene ${
          data.length
        } registros y la cantidad de registros a subir son ${
          props.producto.cantidad - props.producto.seriales.length
        }!`,
      });
      setLoanding(false);
    } else {
      data.map(async (serial) => {
        if (props.producto.producto_detalle.grupo.impuesto == 5) {
        }
        if (props.producto.producto_detalle.grupo.codebar == true) {
          if (
            serial[0].length >=
              props.producto.producto_detalle.grupo.codebarmin &&
            serial[0].length <= props.producto.producto_detalle.grupo.codebarmax
          ) {
            if (props.producto.producto_detalle.grupo.iccid == true) {
              if (
                serial[0].length >=
                  props.producto.producto_detalle.grupo.iccidmin &&
                serial[0].length <=
                  props.producto.producto_detalle.grupo.iccidmax
              ) {
                await directus
                  .items("seriales")
                  .createOne({
                    serial: serial[0],
                    iccid: serial[1],
                    min: serial[2],
                    caja: serial[3],
                    estado: 5,
                    orden_compra_ingreso: props.order,
                    producto: props.producto.producto_detalle.id,
                    producto_odc: props.producto.id,
                  })
                  .then((res) => {
                    console.log("creado " + serial[0]);
                    datoprocesados = datoprocesados + 1;
                  })
                  .catch((err) => {
                    if (err.errors[0].extensions.code == "RECORD_NOT_UNIQUE") {
                      errores.push(serial[0]);
                      datoprocesados = datoprocesados + 1;
                    }
                  });
              } else {
                errores.push(serial[0]);
                console.log("error iccid " + serial[0]);
              }
            } else {
              await directus
                .items("seriales")
                .createOne({
                  serial: serial[0],
                  iccid: serial[1],
                  min: serial[2],
                  caja: serial[3],
                  estado: 5,
                  orden_compra_ingreso: props.order,
                  producto: props.producto.producto_detalle.id,
                  producto_odc: props.producto.id,
                })
                .then((res) => {
                  console.log("creado " + serial[0]);
                  datoprocesados = datoprocesados + 1;
                })
                .catch((err) => {
                  if (err.errors[0].extensions.code == "RECORD_NOT_UNIQUE") {
                    errores.push(serial[0]);
                    datoprocesados = datoprocesados + 1;
                  }
                });
            }
          } else {
            errores.push(serial[0]);
            console.log("error serial " + serial[0]);
            datoprocesados = datoprocesados + 1;
          }
        }

        /*
         */

        console.log("larogo " + largodedatos);
        console.log("proce " + datoprocesados);

        if (parseInt(datoprocesados) === parseInt(largodedatos)) {
          console.log(errores.length);
          setTotalErrores(errores.length);
          setLoanding(false);
          setArchivocargado(true);
          fileselect.current.value = "";
        } else {
          console.log("no es igual");
          console.log("procefuera" + datoprocesados);
          console.log("l" + largodedatos);
        }
      });
    }
  };

  useEffect(() => {
    console.log("ingreso");
    setTotalErrores(0);
    setArchivocargado(false);
    fileselect.current.value = "";
  }, [props.producto]);

  const Error = (ev) => {
    console.log(ev);
  };
  return (
    <>
      {" "}
      <Space direction="vertical">
        <Space>
          <CSVReader
            ref={fileselect}
            cssClass="custom-file-input"
            label="
            Seleccionar archivo en Formato csv con las columnas SERIAL,ICCID,MIN,CAJA,GRUPO  separadas por (,)
             "
            onFileLoaded={handleForce}
            //onError={handleDarkSideForce}
            parserOptions={papaparseOptions}
            inputId="ObiWan"
            inputName="ObiWan"
            inputStyle={{ color: "#555" }}
          />
          <Button
            onClick={() => {
              fileselect.current.value = "";
            }}
          >
            reset
          </Button>
        </Space>
        <br></br>
        <Spin
          spinning={loanding}
          tip="Procesando Archivo por favor espere"
          size="large"
        >
          <div className="content" />
        </Spin>
        <p style={{ color: "green" }}>
          {archivocargado ? `Se termino de procesar el archivo` : null}
        </p>
        <p style={{ color: "red" }}>
          {totalerrores > 0
            ? `No se pudieron agregar ${totalerrores} Registros, por favor verifique el archivo es posible que contenga registros duplicados o que existan en el sistema"`
            : null}
        </p>
      </Space>
    </>
  );
};

export default Addfileproducts;
