import { Form, Modal, Input, Button, Select, Space, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { getDirectusClient } from "../../../lib/directus";
import { useForm } from "antd/es/form/Form";
import { createUser, readItems, readRoles } from "@directus/sdk";
import { useSelector } from "react-redux";
import moment from "moment";

const { Option } = Select;

function Createseller(props) {
  const [roles, setRoles] = useState([]);
  const [bodegas, setBodegas] = useState([]);
  const [prestamo, setPrestamo] = useState(false);
  const userdata = useSelector(state=>state.userdata)
  
  const [form] = useForm();

  const Roles = async () => {
    const directus = await getDirectusClient();

    directus
      .request(
        readRoles({
          filter: {
            name: {
            _eq: "clientes"
          }
        }
      }))
      .then((res) => {
        console.log(res);
        setRoles(res);
      })
      .catch((err) => console.log(err));
  };

  const Bodegas = async () => {
    const directus = await getDirectusClient();

    directus
      .request(
        readItems("bodegas", {
          filter: {
            id: {
            _eq: userdata.bodega.id
          }
        }
      }))
      .then((res) => {
        console.log(res);
        setBodegas(res);
      })
      .catch((err) => console.log(err));
  };

  const onFinish = async (values) => {
    const directus = await getDirectusClient();

    directus
      .request(
     createUser(values))
      .then((res) => {
        console.log(res);
        if (res.id) {
          props.closeCreate();
          form.resetFields();
          props.getSellers();
        }
      })
      .catch((err) => console.log(err));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    form.setFieldValue("email", new Date().getTime() + "client@emesmart.com");  
    form.setFieldValue("password", new Date().getTime() + "client");
    form.setFieldValue("latitude", 0)
    form.setFieldValue("longitude", 0)
    Roles();
    Bodegas();
  }, []);
  return (
    <Modal
      open={props.opencreate}
      onCancel={props.closeCreate}
      title="Crear Cliente"
      footer={[
        <Button key="back" onClick={props.closeCreate}>
          cancelar
        </Button>,
      ]}
    >
      <div style={{ margin: 20 }}>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="nombre"
            name="first_name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="apellidos"
            name="last_name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Documento"
            name="documento"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Correo"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="role" label="Rol" rules={[{ required: true }]}>
            <Select
              placeholder="seleccione un rol"
              allowClear
              onChange={(select) => {
                console.log(select);
                if (select == "7fccd349-4875-4618-b770-344c5cc2e0d8") {
                  setPrestamo(true);
                } else {
                  setPrestamo(false);
                }
              }}
            >
              {roles?.map((rol) => {
                return (
                  <Option key={rol.id} value={rol.id}>
                    {rol.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="bodega" label="Bodega" rules={[{ required: true }]}>
            <Select placeholder="seleccione una Bodega" allowClear>
              {bodegas?.map((bodega) => {
                return <Option value={bodega.id}>{bodega.nombre}</Option>;
              })}
            </Select>
          </Form.Item>

          {prestamo ? (
            <>
              <Form.Item
                label="Tope de prestamo"
                name="tope_de_prestamo"
                rules={[{ required: false }]}
              >
                <InputNumber />
              </Form.Item>
            </>
          ) : null}

          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Latitude"
            name="latitude"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Longitude"
            name="longitude"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Guardar Cliente
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default Createseller;
