import { Modal } from "antd";
import React from "react";

function Viewproduct(props) {
  console.log(props.productselect);
  return (
    <Modal
      title="Basic Modal"
      open={props.open}
      onOk={props.close}
      onCancel={props.close}
    >
      <p>
        <span style={{ color: "green" }}>Nombre: </span>
        {props.productselect.nombre}
      </p>
      <p>
        <span style={{ color: "green" }}>Referencia:</span>{" "}
        {props.productselect.referencia}
      </p>
      <p>
        <span style={{ color: "green" }}>Grupo:</span>{" "}
        {props.productselect.grupo?.nombre}
      </p>
      <p>
        <span style={{ color: "green" }}>Estado:</span>{" "}
        {props.productselect.estado?.nombre}
      </p>
      <p>
        <span style={{ color: "green" }}>Precio:</span>{" "}
        {props.productselect.preciosinimp}
      </p>
    </Modal>
  );
}

export default Viewproduct;
