import { createSlice } from "@reduxjs/toolkit";

export const productosfacturaSlice = createSlice({
  name: "productosfacturar",
  initialState: [],
  reducers: {
    productosfacturaAdd: (state, action) => {
      console.log(action);
      return action.payload;
    },
  },
});
export const { productosfacturaAdd } = productosfacturaSlice.actions;
export default productosfacturaSlice.reducer;
