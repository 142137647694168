import { Form, Modal, Input, Select, Button, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { getDirectusClient } from "../../../lib/directus";
import { createItem, readItems } from "@directus/sdk";
const { Option } = Select;

function Addproduct({ openadd, addModal, getProducts }) {
  //const [openadd, setOpenadd] = useState(false);
  const [form] = Form.useForm();
  const [grupos, setGrupos] = useState([]);
  const [estadoproducto, setEstadoproducto] = useState([]);

  const onFinish = async (values) => {
    const directus = await getDirectusClient();
    directus
      .request(
      createItem("productos",{
        referencia: values.referencia,
        nombre: values.nombre,
        grupo: values.grupo,
        preciosinimp: values.preciosinimp,
        estado: values.estado,
        min: values.min,
      }))
      .then((res) => {
        getProducts();
        onReset();
        addModal();
      })
      .then((err) => {
        console.log(err);
      });
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      note: "Hello world!",
      gender: "male",
    });
  };
  const getGrupos = async () => {
    const directus = await getDirectusClient();
    directus
      .request(
      readItems("grupos"))
      .then((res) => {
        console.log(res);
        setGrupos(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEstadoproducto = async () => {
    const directus = await getDirectusClient();
    directus
      .request(
      readItems("estadoproducto"))
      .then((res) => {
        console.log(res);
        setEstadoproducto(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getGrupos();
    getEstadoproducto();
  }, []);

  return (
    <Modal
      title="Crear producto"
      open={openadd}
      onCancel={addModal}
      footer={false}
    >
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
      >
        <Form.Item
          name="referencia"
          label="Referencia"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="nombre"
          label="Nombre deproducto"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="grupo"
          label="Grupo"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="selecione un grupo" allowClear>
            {grupos.map((grupo) => {
              return <Option value={grupo.id}>{grupo.nombre}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="preciosinimp"
          label="Precio sin imp"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="estado"
          label="Estado de producto"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="seleccione un estado " allowClear>
            {estadoproducto.map((estadoproducto) => {
              return (
                <Option value={estadoproducto.id}>
                  {estadoproducto.nombre}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name="min"
          label="Min"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox></Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Crear
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Addproduct;
