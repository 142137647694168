import React, { useEffect, useState } from "react";
import Layouts from "../../components/Layouts";
import { Button, Modal, Space, Table, Tag } from "antd";
import { getDirectusClient } from "../../lib/directus";
import { Link } from "react-router-dom";

function Traslados(props) {
  const [modal, setModal] = useState(false);
  const [seriales, setSeriales] = useState([]);
  const columns = [
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
      render: (record) => {
        return <p>{record.nombre}</p>;
      },
    },
    {
      title: "Bodega destino",
      dataIndex: "bodega_destino",
      key: "bodega_destino",
      render: (record) => {
        return <p>{record.nombre}</p>;
      },
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (record) => {
        return (
          <Tag
            color={
              record == "anulado"
                ? "red"
                : record == "creado"
                ? "black"
                : "green"
            }
          >
            {record}
          </Tag>
        );
        console.log(record.first_name);
      },
    },
    {
      title: "Creado por",
      dataIndex: "user_created",
      key: "user_created",
      render: (record) => {
        return <p>{record.first_name}</p>;
        console.log(record.first_name);
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              consultarseriales(record.id);
              setModal(true);
            }}
          >
            Ver seriales
          </Button>
          <a
            onClick={() => {
              Aceptartraslado(record.id, false);
            }}
          >
            Anular Traslado
          </a>
        </Space>
      ),
    },
  ];

  const Aceptartraslado = (id) => {
    let aceptar = true;
    consultarseriales(id, aceptar);
  };
  const cerrarModal = () => {
    setModal(false);
  };

  const consultarseriales = async (id, aceptar) => {
    const directus = await getDirectusClient();

    if (aceptar) {
      let user = await directus.users.me.read();
      console.log(user.bodega);
      Modal.confirm({
        title: "Quieres Anular este traslado de seriales?",
        content: "Se anulara el traslado de seriales ID traslado: " + id,
        onOk() {
          directus
            .items("seriales")
            .updateByQuery(
              {
                fields: ["*"],

                filter: {
                  solicitud_traslado: {
                    solicitud_traslado_id: {
                      _eq: id,
                    },
                  },
                },
              },
              {
                estado: 1,
                bodega: user.bodega,
              }
            )
            .then((seriales) => {
              console.log(seriales);
              if (seriales.status == 200) {
                directus
                  .items("solicitud_traslado")
                  .updateOne(id, {
                    estado: "anulado",
                  })
                  .then(() => {
                    Modal.success({
                      title: "Exito",
                      content:
                        "se anulo el traslado de seriales se anulo " +
                        seriales.data.length +
                        " traslados de Seriales y siguen en tu Bodega",
                    });
                    consultaTraslados();
                  });
              }
              // setSeriales(seriales.data);
            });
        },
      });
    } else {
      directus
        .items("seriales")
        .readByQuery({
          fields: ["*"],
          limit: -1,
          filter: {
            solicitud_traslado: {
              solicitud_traslado_id: {
                _eq: id,
              },
            },
          },
        })
        .then((seriales) => {
          console.log(seriales);
          setSeriales(seriales.data);
        });
    }
  };
  const [traslados, setTraslados] = useState([]);

  const consultaTraslados = async () => {
    const directus = await getDirectusClient();
    let user = await directus.users.me.read();
    directus
      .items("solicitud_traslado")
      .readByQuery({
        fields: "*.*",
      })
      .then((res) => {
        console.log(res);
        setTraslados(res.data);
      });
  };

  useEffect(() => {
    consultaTraslados();
  }, []);
  return (
    <Layouts>
      <Link to={"/nuevo-traslado"}>
        <Button>Crear traslado </Button>
      </Link>
      <Link to={"/aceptar-traslado"}>
        <Button>Aceptar traslado traslado </Button>
      </Link>
      <Table columns={columns} dataSource={traslados} />
      <Modal onOk={cerrarModal} onCancel={cerrarModal} open={modal}>
        {seriales.map((serial) => {
          return (
            <Space size={"small"}>
              <p>{"- " + serial.serial} </p>
            </Space>
          );
        })}
      </Modal>
    </Layouts>
  );
}

export default Traslados;
