import React, { useEffect, useState } from "react";
import Layouts from "../../components/Layouts";
import { Button, Divider, Modal, Space, Table, Tag } from "antd";
import { getDirectusClient } from "../../lib/directus";
import Addproduct from "./components/Addproduct";
import Viewproduct from "./components/Viewproduct";
import Editproduct from "./components/Editproduct";
import { deleteItem, readItems } from "@directus/sdk";
function Products(props) {
  const [data, setData] = useState([]);
  const [openadd, setOpenadd] = useState(false);
  const [openview, setOpenview] = useState(false);
  const [productselect, setProductselect] = useState({});
  const [openedit, setOpenedit] = useState(false);

  const addModal = () => {
    openadd ? setOpenadd(false) : setOpenadd(true);
  };

  const viewModal = () => {
    openview ? setOpenview(false) : setOpenview(true);
  };

  const editModal = () => {
    openedit ? setOpenedit(false) : setOpenedit(true);
  };
  const columns = [
    {
      title: "Codigo/Referencia",
      dataIndex: "referencia",
      key: "referencia",
    },
    {
      title: "Producto",
      dataIndex: "nombre",
      key: "age",
    },
    {
      title: "Precio",
      dataIndex: "preciosinimp",
      key: "preciosinimp",
    },
    {
      title: "Grupo",
      key: "grupos",
      dataIndex: "grupos",
    },
    {
      title: "Estado",
      key: "estados",
      dataIndex: "estados",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              setProductselect(record);
              viewModal();
            }}
          >
            ver {record.name}
          </a>
          <a
            onClick={() => {
              setProductselect(record);
              editModal();
            }}
          >
            Editar
          </a>
          <a
            onClick={() => {
              Modal.confirm({
                content: "¿Estas seguro de eliminar este producto?",
                onOk: () => {
                  deleteProduct(record.id);
                },
              });
            }}
          >
            Eliminar
          </a>
        </Space>
      ),
    },
  ];

  const getProducts = async () => {
    const directus = await getDirectusClient();

     await directus.request(readItems("productos",{
      fields: ["*.*.*"],
     })).then((res) => {
     
    res.map((d) => {
      d.key = d.id;
      console.log(d);
      d.grupos = d.grupo.nombre;
      d.estados = d.estado.nombre;
    });

    setData(res);
    });

  };

  const deleteProduct = async (id) => {
    const directus = await getDirectusClient();
    directus
      .request(
      deleteItem("productos",id))
      .then((res) => {
        getProducts();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Layouts>
      <Button onClick={() => addModal()}>Nuevo producto</Button>
      <Editproduct
        openedit={openedit}
        editModal={editModal}
        productselect={productselect}
        getProducts={getProducts}
      />
      <Viewproduct
        close={viewModal}
        open={openview}
        productselect={productselect}
      />
      <Addproduct
        openadd={openadd}
        addModal={addModal}
        getProducts={getProducts}
      />
      <Divider />
      <Table columns={columns} dataSource={data} />
    </Layouts>
  );
}

export default Products;
