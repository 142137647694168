import { Form, Checkbox, Space, Row, Col, Button, Pagination } from "antd";
import React, { useEffect, useState } from "react";

function Seriales(props) {
  const [seriales, setSeriales] = useState([]);

  let arrayserialest = [];

  useEffect(() => {
    console.log(props);
    let arrayseriales = [];
    if (props.productoselect.id > 0) {
      //console.log.log("producto selecs");
      props.seriales.map((serial) => {
        arrayseriales.push({ label: serial.serial, value: serial.serial });
      });
      setSeriales(props.productoselect.seriales);
    }
    //console.log.log(props.totalpagina);
  }, [props.productoselect]);

  return (
    <Form
      onFinish={(ev) => {
        //console.log.log(ev);
      }}
    >
      <Form.Item
        style={{ width: 400 }}
        valuePropName="checked"
        name="afectainventario"
      >
        <Space style={{ width: 400 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(e) => {
              // props.setSerialesagregados(e);
              //console.log.log("agregando");
            }}
          >
            <Row gutter={100}>
              {props.seriales.map((serial) => {
                return (
                  <Col span={8} key={serial.id}>
                    <Checkbox
                      key={serial.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props.agregarserial(e.target.value, e.target.checked);
                        } else {
                          props.agregarserial(e.target.value, e.target.checked);
                        }
                      }}
                      value={serial.id}
                    >
                      {serial.serial}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Space>
        <Pagination
          onChange={(ev) => {
            props.setPagination((prevPagination) => ({
              ...prevPagination,
              current: ev,
            }));
            //console.log.log(ev);
          }}
          current={props.current}
          total={props.totalpagina}
          pageSize={20}
        />
      </Form.Item>
    </Form>
  );
}

export default Seriales;
