import { authentication, createDirectus, rest } from "@directus/sdk";

export const authLocalStorage = (mainKey = "directus_storage") => ({
  // implementation of get, here return json parsed data from localStorage at mainKey (or null if not found)
  get: async () => {
    const data = window.localStorage.getItem(mainKey);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },

  // implementation of set, here set the value at mainKey in localStorage, or remove it if value is null
  set: async (value) => {
    if (!value) {
      return window.localStorage.removeItem(mainKey);
    }
    return window.localStorage.setItem(mainKey, JSON.stringify(value));
  },
});


export function getDirectusClient() {
  return createDirectus("https://backend.emesmart.com") // here set your directus url
    .with(rest()) // add this if you want to use rest api
    .with(
      authentication("json", {
        // add this if you want to use authentication, json is important, it's type of your authentication usage, here JWT
        storage: authLocalStorage(), // here set the storage previously created
      })
    );
}
export class DirectusSingleton {
  static getInstance(rebuild = false) {
    if (!DirectusSingleton.instance || rebuild) {
      DirectusSingleton.instance = getDirectusClient();
    }
    return DirectusSingleton.instance;
  }
}
