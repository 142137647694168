import React, { useState, useEffect, useRef } from "react";
import { Table, Button, notification, Dropdown, Menu } from "antd";
import { getDirectusClient } from "../../lib/directus";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import {
  pdf,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  Image,
} from "@react-pdf/renderer";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import {
  Input,
  Modal,
  Form,
  Space,
  Select,
  Image as Images,
  DatePicker,
} from "antd";
import Layouts from "../../components/Layouts";
import {
  readFiles,
  readItem,
  readItems,
  readRoles,
  updateItem,
  updateSingleton,
  utilsExport,
} from "@directus/sdk";
import { Link } from "react-router-dom";
import { FilePdfOutlined } from "@ant-design/icons";
import CSVReader from "react-csv-reader";
import { useSelector } from "react-redux";
import { CSVDownload, CSVLink } from "react-csv";
import Constants from "../../lib/Constants";
const papaparseOptions = {
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};
const { RangePicker } = DatePicker;

const MyDocumentspn = (props) => (
  <Document>
    <Page>
      <Image
        src={`${Constants.URL}/assets/${props.data.snp_foto.filename_disk}`}
      />
    </Page>
  </Document>
);
const dateFormat = "YYYY-MM-DD ";

const MyDocumentsim = (props) => {
  console.log(props.data);
  return (
    <Document>
      <Page>
        <Image src={`${Constants.URL}/assets/${props.data.filename_disk}`} />
      </Page>
    </Document>
  );
};

const Portabilidadpdf = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [estados, setEstados] = useState([]);
  const [selected, setSelected] = useState({});
  const [fecha1, setFecha1] = useState("2020/11/01 11:50:51");
  const [fecha2, setFecha2] = useState("3000/11/01 11:50:51");
  const [estado, setEstado] = useState(1);
  const [dataexportar, setDataexportar] = useState([]);
  const userdata = useSelector((state) => state.userdata);
  const [roles, setroles] = useState([]);
  const fileselect = useRef();

  const [rolesearch, setRolesearch] = useState("promotores");
  const user = useSelector((state) => state.user);
  useEffect(() => {
    fetchPortabilidades(pagination, estado, fecha1, fecha2, rolesearch);
    listarEstadosPortabilidad();
  }, []);

  const fetchPortabilidades = async (
    paginacion,
    estado,
    fecha1,
    fecha2,
    role
  ) => {
    const directus = await getDirectusClient();

    await directus
      .request(
        readRoles({
          filter: {
            pospago: true,
          },
        })
      )
      .then((res) => {
        console.log(res);
        setroles(res);
      })
      .catch((err) => {
        console.log(err);
      });

    const responsecount = await directus.request(
      readItems("portabilidadespost", {
        filter: {
          user_created: {
            role: {
              name: {
                _eq: role,
              },
            },
          },
          estado: {
            id: {
              _eq: estado,
            },
          },
          bodega: {
            id: {
              _eq: userdata.bodega.id,
            },
          },
          date_created: {
            _between: [fecha1, fecha2],
          },
        },
        aggregate: { count: "*" },
      })
    );

    const response = await directus.request(
      readItems("portabilidadespost", {
        fields: ["*.*", "user_created.role.*"],
        filter: {
          user_created: {
            role: {
              name: {
                _eq: role,
              },
            },
          },
          bodega: {
            id: {
              _eq: userdata.bodega.id,
            },
          },
          estado: {
            id: {
              _eq: estado,
            },
          },
          date_created: {
            _between: [fecha1, fecha2],
          },
        },
        limit: paginacion.pageSize,
        offset: (paginacion.current - 1) * paginacion.pageSize,
        sort: ["-id"],
      })
    );

    console.log(responsecount[0]);
    setData(response);
    setPagination((prev) => ({ ...prev, total: responsecount[0].count }));

    const responseexport = await directus.request(
      readItems("portabilidadespost", {
        fields: [
          "serial",
          "min",
          "nip",
          "spn",
          "estrategia",
          "operador",
          "nombre_cliente",
          "documento",
          "estado",
          "user_created.*",
          "date_created",
        ],
        alias: {
          nombre: "nombre_cliente",
        },
        filter: {
          user_created: {
            role: {
              name: {
                _eq: rolesearch,
              },
            },
          },
          bodega: {
            id: {
              _eq: userdata.bodega.id,
            },
          },
          estado: {
            id: {
              _eq: estado,
            },
          },
          date_created: {
            _between: [fecha1, fecha2],
          },
        },
        limit: -1,
        sort: ["-id"],
      })
    );

    responseexport.map((res) => {
      console.log(res);

      res.date_created = dayjs(res.date_created).format("YYYY-DD-MM HH:mm:ss");
      res.user_created =
        res.user_created.first_name + " " + res.user_created.last_name;
      console.log(res.fecha);
    });
    setTimeout(() => {
      setDataexportar(responseexport);
      console.log("finalizado");
    }, 1000);
  };

  const Menu = (props) => {
    return (
      <Menu>
        <Menu.Item key="1">Option 1</Menu.Item>
        <Menu.Item key="2">Option 2</Menu.Item>
        <Menu.Item key="3">Option 3</Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    //
  }, [pagination]);

  const columns = [
    {
      title: "Fecha",
      dataIndex: "date_created",
      width: 150,
      key: "date_created",
      render: (text) => (
        <span>{dayjs(text).format("YYYY-DD-MM HH:mm:ss")}</span>
      ),
    },
    {
      title: "Estado",
      width: 150,
      dataIndex: "estado",
      render: (text) => <span>{text.nombre}</span>,
    },
    {
      title: "Serial",
      dataIndex: "serial_sim",
      width: 150,
      key: "serial_sim",
    },

    {
      title: "documento",
      dataIndex: "foto_sim",
      width: 110,
      key: "foto_sim",
      render: (_, record) => {
        //console.log(record);
        return (
          <Space direction="vertical">
            <Images
              width={50}
              src={`${Constants.URL}/assets/${record.documento_foto?.filename_disk}`}
            />

            <Button
              icon={<FilePdfOutlined />}
              onClick={() => generatePdfsim(record, "documento_foto")}
            ></Button>
          </Space>
        );
      },
    },
    {
      title: " Contrato",
      dataIndex: "contrato_foto",
      key: "foto spn",
      width: 110,
      render: (_, record) => {
        //  console.log(record);
        return (
          <Space direction="vertical">
            <Images
              width={50}
              src={`${Constants.URL}/assets/${record.contrato_foto?.filename_disk}`}
            />
            <Button
              icon={<FilePdfOutlined />}
              onClick={() => generatePdfsim(record, "contrato_foto")}
            ></Button>
          </Space>
        );
      },
    },
    {
      title: "APC",
      dataIndex: "foto spn",
      key: "foto spn",
      width: 110,
      render: (_, record) => {
        //  console.log(record);
        return (
          <Space direction="vertical">
            <Images
              width={50}
              src={`${Constants.URL}/assets/${record.apc_foto?.filename_disk}`}
            />
            <Button
              icon={<FilePdfOutlined />}
              onClick={() => generatePdfsim(record, "apc_foto")}
            ></Button>
          </Space>
        );
      },
    },
    {
      title: "Sim card",
      dataIndex: "foto spn",
      key: "foto spn",
      width: 110,
      render: (_, record) => {
        //  console.log(record);
        return (
          <Space direction="vertical">
            <Images
              width={50}
              src={`${Constants.URL}/assets/${record.sim_foto?.filename_disk}`}
            />
            <Button
              icon={<FilePdfOutlined />}
              onClick={() => generatePdfsim(record, "sim_foto")}
            ></Button>
          </Space>
        );
      },
    },
    {
      title: "Formulario P/N",
      dataIndex: "foto spn",
      key: "foto spn",
      width: 110,
      render: (_, record) => {
        //  console.log(record);
        return (
          <Space direction="vertical">
            <Images
              width={50}
              src={`${Constants.URL}/assets/${record.formulario_foto?.filename_disk}`}
            />
            <Button
              icon={<FilePdfOutlined />}
              onClick={() => generatePdfsim(record, "formulario_foto")}
            ></Button>
          </Space>
        );
      },
    },
    {
      title: " Terminos /C",
      dataIndex: "foto spn",
      key: "foto spn",
      width: 110,
      render: (_, record) => {
        //  console.log(record);
        return (
          <Space direction="vertical">
            <Images
              width={50}
              src={`${Constants.URL}/assets/${record.terminos_foto?.filename_disk}`}
            />
            <Button
              icon={<FilePdfOutlined />}
              onClick={() => generatePdfsim(record, "terminos_foto")}
            ></Button>
          </Space>
        );
      },
    },
    {
      title: "cliente firmando",
      dataIndex: "foto spn",
      key: "foto spn",
      width: 110,
      render: (_, record) => {
        //  console.log(record);
        return (
          <Space direction="vertical">
            <Images
              width={50}
              src={`${Constants.URL}/assets/${record.firma_foto?.filename_disk}`}
            />
            <Button
              icon={<FilePdfOutlined />}
              onClick={() => generatePdfsim(record, "firma_foto")}
            ></Button>
          </Space>
        );
      },
    },
    {
      title: "cliente contrato",
      dataIndex: "foto spn",
      key: "foto spn",
      width: 110,
      render: (_, record) => {
        //  console.log(record);
        return (
          <Space direction="vertical">
            <Images
              width={50}
              src={`${Constants.URL}/assets/${record.cliente_contrato_foto?.filename_disk}`}
            />
            <Button
              icon={<FilePdfOutlined />}
              onClick={() => generatePdfsim(record, "contrato_ship_foto")}
            ></Button>
          </Space>
        );
      },
    },
    {
      title: "Min",
      width: 150,
      dataIndex: "min",
      key: "min",
    },
    {
      title: "Nip",
      width: 110,
      dataIndex: "nip",
      key: "nip",
    },
    {
      title: "Asesor",
      width: 110,
      dataIndex: "user_created",
      key: "user_created",
      render: (text) => <span>{text.first_name + " " + text.last_name}</span>,
    },
    {
      title: "Ducumento asesor",
      width: 110,
      dataIndex: "user_created",
      key: "user_created",
      render: (text) => <span>{text.documento}</span>,
    },
    {
      title: "Canal",
      width: 110,
      dataIndex: "user_created",
      key: "user_created",
      render: (text) => <span>{text.role.name}</span>,
    },
    {
      title: "Cliente",
      width: 150,
      dataIndex: "nombre_cliente",
      key: "nombre_cliente",
    },
    {
      title: "Documento",
      dataIndex: "documento",
      width: 150,
      key: "documento",
    },
    {
      title: "Direccion",
      width: 150,
      dataIndex: "direccion",
      key: "direccion",
    },
    {
      title: "Correo",
      width: 150,
      dataIndex: "correo",
      key: "estrategia",
    },
    {
      title: "Contacto",
      width: 150,
      dataIndex: "contacto",
      key: "contacto",
    },
    {
      title: "Numero Gestionar",
      width: 150,
      dataIndex: "numero_a_gestionar",
      key: "estrategia",
    },

    {
      title: "Tipo tramite",
      width: 150,
      dataIndex: "tipo_tramite",
      render: (text) => {
        console.log("Tipo", text);
        return <span>{text?.name}</span>;
      },
    },
    {
      title: "sim card",
      width: 150,
      dataIndex: "serial_sim",
    },
    {
      title: "Plan",
      width: 150,
      dataIndex: "plan",
    },

    {
      title: "Ocupacion",
      width: 150,
      dataIndex: "ocupacion",
    },
    {
      title: "Salario",
      width: 150,
      dataIndex: "salario",
    },
    {
      title: "Referidos",
      width: 150,
      dataIndex: "referidos",
      render: (referidos) => {
        console.log("ref", referidos);

        const items = [];

        referidos.map((referido) => {
          items.push({
            label: (
              <>
                {" "}
                <a>
                  <span style={{ color: "green" }}> Nombre:</span>{" "}
                  {referido.name + " "}
                </a>
                <a>
                  {" "}
                  <span style={{ color: "green" }}> Telefono:</span>{" "}
                  {referido.telefono}
                </a>
              </>
            ),
            key: referido.id,
          });
        });

        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Ver
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      },
    },
    {
      title: "Acción",
      key: "action",
      fixed: "right",
      width: 150,
      render: (text, record) => (
        <>
          <Button onClick={() => showModal(record)}>Editar</Button>
        </>
      ),
    },
  ];

  const generatePdfsim = (record, nombre) => {
    console.log("documento", record);

    if (nombre === "contrato_foto") {
      const doc = <MyDocumentsim data={record.contrato_foto} />;
      const asPdf = pdf([]); // {} is important, it resets the previous document
      asPdf.updateContainer(doc);

      asPdf.toBlob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = nombre + "-.pdf";
        link.click();
      });
    } else if (nombre === "documento_foto") {
      const doc = <MyDocumentsim data={record.documento_foto} />;
      const asPdf = pdf([]); // {} is important, it resets the previous document
      asPdf.updateContainer(doc);

      asPdf.toBlob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = nombre + "-.pdf";
        link.click();
      });
    } else if (nombre === "apc-foto") {
      const doc = <MyDocumentsim data={record.apc_foto} />;
      const asPdf = pdf([]); // {} is important, it resets the previous document
      asPdf.updateContainer(doc);

      asPdf.toBlob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = nombre + "-.pdf";
        link.click();
      });
    } else if (nombre === "formulario_foto") {
      const doc = <MyDocumentsim data={record.formulario_foto} />;
      const asPdf = pdf([]); // {} is important, it resets the previous document
      asPdf.updateContainer(doc);

      asPdf.toBlob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = nombre + "-.pdf";
        link.click();
      });
    } else if (nombre === "terminos_foto") {
      const doc = <MyDocumentsim data={record.terminos_foto} />;
      const asPdf = pdf([]); // {} is important, it resets the previous document
      asPdf.updateContainer(doc);

      asPdf.toBlob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = nombre + "-.pdf";
        link.click();
      });
    } else if (nombre === "firma_foto") {
      const doc = <MyDocumentsim data={record.firma_foto} />;
      const asPdf = pdf([]); // {} is important, it resets the previous document
      asPdf.updateContainer(doc);

      asPdf.toBlob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = nombre + "-.pdf";
        link.click();
      });
    } else if (nombre === "contrato_ship_foto") {
      const doc = <MyDocumentsim data={record.contrato_ship_foto} />;
      const asPdf = pdf([]); // {} is important, it resets the previous document
      asPdf.updateContainer(doc);

      asPdf.toBlob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = nombre + "-.pdf";
        link.click();
      });
    } else if (nombre === "sim_foto") {
      const doc = <MyDocumentsim data={record.sim_foto} />;
      const asPdf = pdf([]); // {} is important, it resets the previous document
      asPdf.updateContainer(doc);

      asPdf.toBlob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = nombre + "-.pdf";
        link.click();
      });
    }
  };

  const generatePdfspn = (record) => {
    const doc = <MyDocumentspn data={record} />;
    const asPdf = pdf([]); // {} is important, it resets the previous document
    asPdf.updateContainer(doc);

    asPdf.toBlob().then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "spn.pdf";
      link.click();
    });
  };
  const handleTableChange = (pagination) => {
    console.log(pagination);
    setPagination(pagination);
    fetchPortabilidades(pagination, estado, fecha1, fecha2, rolesearch);
  };

  const [visible, setVisible] = useState(false);
  const [record, setRecord] = useState({});

  const showModal = (record) => {
    setVisible(true);
    setRecord(record);
    setSelected(record);
    console.log(record);
    form.setFieldValue("notas", record.nota);
    form.setFieldValue("estado", record.estado.id);
    form.setFieldValue("serial_sim", record.serial_sim);
    form.setFieldValue("nombre_cliente", record.nombre_cliente);
    form.setFieldValue("correo", record.correo);
    form.setFieldValue("numero_a_gestionar", record.numero_a_gestionar);
  };

  const handleOk = async () => {
    setVisible(false);
    // Aquí puedes agregar la lógica para actualizar el estado y las notas de las portabilidades
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const listarEstadosPortabilidad = async () => {
    const directus = await getDirectusClient();
    const data = await directus.request(readItems("estados_portabilidad"));
    console.log(data);
    setEstados(data);
  };

  const Enviar = async (record) => {
    // Aquí puedes agregar la lógica para enviar los datos
    const directus = await getDirectusClient();
    await directus
      .request(
        updateItem("portabilidadespost", parseInt(selected.id), {
          nota: record.notas,
          estado: record.estado,
          serial_sim: record.serial_sim,
          correo: record.correo,
          nombre_cliente: record.nombre_cliente,
          numero_a_gestionar: record.numero_a_gestionar,
        })
      )
      .then((res) => {
        fetchPortabilidades(pagination, estado, fecha1, fecha2, rolesearch);
        listarEstadosPortabilidad();
      });
    handleCancel();
  };

  const exportarcsv = async () => {
    const directus = await getDirectusClient();
    const fecha = Date.now();
    const milisegundos = fecha;
    const nombre = `portabilidad${milisegundos}`;
    await directus
      .request(
        utilsExport(
          "portabilidadespost",
          "csv",
          {
            filter: {
              bodega: {
                id: {
                  _eq: userdata.bodega.id,
                },
              },
            },
            fields: [
              "min",
              "serial",
              "estrategia",
              "operador",
              "nota",
              "cliente.nombre",
              "nombre_cliente",
              "nip",
              "estado.nombre",
              "year(date_created)",
              "month(date_created)",
              "day(date_created)",
              "documento",
              "user_created.first_name",
              "user_created.last_name",
              "user_created.last_name",
              "user_created.role.name",
              "user_updated.first_name",
              "user_updated.last_name",
              "year(date_updated)",
              "month(date_updated)",
              "day(date_updated)",
            ],
            limit: -1,
            sort: ["id"],
          },

          {
            folder: "e062d3cb-d1f4-4bc4-a150-e329ee9990be",
            filename_download: nombre,
            title: nombre,
          }
        )
      )
      .then((res) => {
        console.log(res.ok);
        if (res.ok) {
          openNotificationWithIcon(
            "success",
            " se estan exportando los datos pronto estaran disponibles para descargarlos."
          );
        }
      })
      .catch((err) => {
        console.log(err);
        openNotificationWithIcon(
          "error",
          "Error al generar datos, hay un archivo en proceso por favor expere e intente de nuevo"
        );
      });
  };

  const exportf = async (paginacion, estado, fecha1, fecha2) => {
    const directus = new getDirectusClient();
  };

  const exportarcsvfilter = async () => {
    const directus = await getDirectusClient();
    const fecha = Date.now();
    const milisegundos = fecha;
    const nombre = `portabilidad${milisegundos}`;
    await directus
      .request(
        utilsExport(
          "portabilidadespost",
          "csv",
          {
            filter: {
              estado: {
                id: {
                  _eq: estado,
                },
              },
              date_created: {
                _between: [fecha1, fecha2],
              },
            },
            fields: [
              "min",
              "date_created",
              "serial",
              "nombre_cliente",
              "nip",
              "documento",
            ],
            limit: 1000,
            sort: ["id"],
            alias: {
              nombre: "nombre_cliente",
            },
          },

          {
            folder: "e062d3cb-d1f4-4bc4-a150-e329ee9990be",
            filename_download: nombre,
            title: nombre,
          }
        )
      )
      .then((res) => {
        console.log(res.ok);
        if (res.ok) {
          openNotificationWithIcon(
            "success",
            " se estan exportando los datos pronto estaran disponibles para descargarlos."
          );
        }
      })
      .catch((err) => {
        console.log(err);
        openNotificationWithIcon(
          "error",
          "Error al generar datos, hay un archivo en proceso por favor expere e intente de nuevo"
        );
      });
  };

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, texto) => {
    api[type]({
      message: "Exportando datos",
      description: texto,
    });
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setFecha1(dateStrings[0]);
      setFecha2(dateStrings[1]);
      fetchPortabilidades(
        pagination,
        estado,
        dateStrings[0],
        dateStrings[1],
        rolesearch
      );
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };

  return (
    <Layouts>
      {contextHolder}
      <Space size={"large"}>
        <Button onClick={exportarcsv}>Exportar todo</Button>

        <CSVLink filename={"portabilidades01.csv"} data={dataexportar}>
          Exportar filtrados
        </CSVLink>
        <Link to={"/listaexportador"}>ver exportados Completos </Link>

        <span>Selecione un estado: </span>

        <Select
          style={{ width: 120 }}
          value={estado}
          onChange={(ev) => {
            console.log(ev);
            setEstado(ev);
            fetchPortabilidades(pagination, ev, fecha1, fecha2, rolesearch);
          }}
        >
          {estados.map((estado) => {
            return (
              <Select.Option key={estado.id} value={estado.id}>
                {estado.nombre}
              </Select.Option>
            );
          })}
        </Select>

        <span style={{ color: "green" }}>
          Total encontrados: {pagination.total}
        </span>
      </Space>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <Space size={"large"}>
          Buscar entre fechas:
          <RangePicker
            format={"YYYY/MM/DD HH:mm:ss"}
            onChange={onRangeChange}
            placeholder={["Fecha inicial", "Feha final"]}
            showTime
          />
          <span>Selecione un tipo: </span>
          <Select
            style={{ width: 120 }}
            value={rolesearch}
            onChange={(ev) => {
              console.log(ev);
              Modal.confirm({
                title: "Estas cambiando a gestionar:  " + ev,

                content: "Estas seguro de este cambio",
                onOk() {
                  console.log("Si");
                  console.log(ev);
                  setRolesearch(ev);

                  fetchPortabilidades(pagination, estado, fecha1, fecha2, ev);
                },
                onCancel() {
                  console.log("Cancelar");
                  setRolesearch(rolesearch);
                },
              });
            }}
          >
            {roles.map((role) => {
              return (
                <Select.Option key={1} value={role.name}>
                  {role.name}
                </Select.Option>
              );
            })}
          </Select>
          <CSVReader
            ref={fileselect}
            cssClass="custom-file-input"
            label="
            Seleccionar archivo en Formato csv separadas por (,)
             "
            onFileLoaded={(data, fileInfo, originalFile) => {
              console.log(data);
              data.map(async (porta) => {
                if (porta[0] !== "") {
                  console.log(porta[1]);
                  console.log(estados);

                  const found = estados.find(
                    (element) => element.nombre === porta[1]
                  );
                  //leer datos por min

                  const directus = await getDirectusClient();

                  await directus
                    .request(
                      readItems("portabilidadespost", {
                        filters: {
                          min: {
                            _eq: porta[0],
                          },
                        },
                      })
                    )
                    .then((res) => {
                      res.map(async (portabilidad) => {
                        await directus
                          .request(
                            updateItem("portabilidadespost", portabilidad.id, {
                              estado: found?.id,
                            })
                          )
                          .then((res) => {
                            fetchPortabilidades(
                              pagination,
                              estado,
                              fecha1,
                              fecha2,
                              rolesearch
                            );
                            listarEstadosPortabilidad();
                          });
                      });
                    });

                  handleCancel();
                }
              });
            }}
            //onError={handleDarkSideForce}
            parserOptions={papaparseOptions}
            inputId="ObiWan"
            inputName="ObiWan"
            inputStyle={{ color: "#555" }}
          />
        </Space>
      </div>

      <Modal
        onCancel={handleCancel}
        title="Editar Portabilidad"
        open={visible}
        footer={null}
      >
        <Form form={form} onFinish={Enviar}>
          <Form.Item name={"serial_sim"} label="Serial">
            <Input
              onChange={(e) =>
                setRecord({ ...record, serial_sim: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item name={"nombre_cliente"} label="Nombre">
            <Input
              onChange={(e) =>
                setRecord({ ...record, nombre_cliente: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item name={"correo"} label="Correo">
            <Input
              onChange={(e) => setRecord({ ...record, correo: e.target.value })}
            />
          </Form.Item>
          <Form.Item name={"numero_a_gestionar"} label="Numero a gestionar">
            <Input
              onChange={(e) =>
                setRecord({ ...record, numero_a_gestionar: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item name={"estado"} label="Estado">
            <Select
              onChange={(value) => setRecord({ ...record, estado: value })}
            >
              {estados.map((estado) => {
                return (
                  <Select.Option key={estado.id} value={estado.id}>
                    {estado.nombre}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name={"notas"} label="Nota">
            <Input
              onChange={(e) => setRecord({ ...record, nota: e.target.value })}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Cambiar
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <h3 style={{ textAlign: "center", color: "green" }}>
        {"Viendo gestiones de " + rolesearch}
      </h3>

      <Space>
        Buscar portabilidad
        <Input
          onChange={async (ev) => {
            console.log(ev.target.value);
            if (ev.target.value !== "") {
              const directus = await getDirectusClient();

              const response = await directus.request(
                readItems("portabilidadespost", {
                  fields: ["*.*"],
                  filter: {
                    min: {
                      _eq: ev.target.value.trim(),
                    },
                  },
                  limit: -1,
                })
              );

              setData(response);
            } else {
              fetchPortabilidades(
                pagination,
                estado,
                fecha1,
                fecha2,
                rolesearch
              );
            }
          }}
          placeholder="Ingrese el min completo"
        />
        Buscar Serial
        <Input
          onChange={async (ev) => {
            console.log(ev.target.value);
            if (ev.target.value !== "") {
              const directus = await getDirectusClient();

              const response = await directus.request(
                readItems("portabilidadespost", {
                  fields: ["*.*"],
                  filter: {
                    serial: {
                      _eq: ev.target.value.trim(),
                    },
                  },
                  limit: -1,
                })
              );

              setData(response);
            } else {
              fetchPortabilidades(
                pagination,
                estado,
                fecha1,
                fecha2,
                rolesearch
              );
            }
          }}
          placeholder="Ingrese el serial completo"
        />
      </Space>

      <Table
        scroll={{ x: 1000, y: "65vh" }}
        columns={columns}
        dataSource={data}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Layouts>
  );
};

export default Portabilidadpdf;
