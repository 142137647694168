import React from "react";

const getIconByName = (iconName) => {
  return (
    <i
      style={{ fontSize: "24px", color: "#00b96b", width: 20 }}
      className="material-icons"
    >
      {iconName}
    </i>
  );
};

export default getIconByName;
