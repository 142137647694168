import { Form, Modal, Input, Button, Select, Space, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { getDirectusClient } from "../../../lib/directus";
import { useForm } from "antd/es/form/Form";
import { readItems, readRoles, updateUser } from "@directus/sdk";

const { Option } = Select;

function Editseller(props) {
  const [roles, setRoles] = useState([]);
  const [bodegas, setBodegas] = useState([]);
  const [prestamo, setPrestamo] = useState(false);

  const [form] = useForm();

  const Roles = async () => {
    const directus = await getDirectusClient();

    directus
      .request(
      readRoles({}))
      .then((res) => {
        console.log(res);
        setRoles(res);
      })
      .catch((err) => console.log(err));
  };

  const Bodegas = async () => {
    const directus = await getDirectusClient();

    directus
      .request(
      readItems("bodegas",{}))
      .then((res) => {
        console.log(res);
        setBodegas(res);
      })
      .catch((err) => console.log(err));
  };

  const onFinish = async (values) => {
    const directus = await getDirectusClient();

    directus
      .request(
      updateUser(props.selectseller.id, values))
      .then((res) => {
        console.log(res);
        if (res.id) {
          props.closeUpdate();
          form.resetFields();
          props.getSellers();
        }
      })
      .catch((err) => console.log(err));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue({ first_name: props.selectseller.first_name });
    form.setFieldsValue({ last_name: props.selectseller.last_name });
    form.setFieldsValue({ documento: props.selectseller.documento });
    form.setFieldsValue({ email: props.selectseller.email });
    form.setFieldsValue({ role: props.selectseller.role });
    form.setFieldsValue({ bodega: props.selectseller.bodega });
    form.setFieldsValue({
      tope_de_prestamo: props.selectseller.tope_de_prestamo,
    });
    if (props.selectseller.role == "7fccd349-4875-4618-b770-344c5cc2e0d8") {
      setPrestamo(true);
    } else {
      setPrestamo(false);
    }
  }, [props.selectseller]);

  useEffect(() => {
    Roles();
    Bodegas();
  }, []);
  return (
    <Modal
      open={props.opencreate}
      onCancel={props.closeUpdate}
      title="Editar Producto"
      footer={[
        <Button
          key="back"
          onClick={() => {
            props.closeUpdate();
            props.setSelectseller({});
          }}
        >
          cancelar
        </Button>,
      ]}
    >
      <div style={{ margin: 20 }}>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="nombre"
            name="first_name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="apellidos"
            name="last_name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Documento"
            name="documento"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Correo"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="role" label="Rol" rules={[{ required: true }]}>
            <Select
              placeholder="seleccione un rol"
              allowClear
              onChange={(select) => {
                console.log(select);
                if (select == "7fccd349-4875-4618-b770-344c5cc2e0d8") {
                  setPrestamo(true);
                } else {
                  setPrestamo(false);
                }
              }}
            >
              {roles?.map((rol) => {
                return (
                  <Option key={rol.id} value={rol.id}>
                    {rol.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="bodega" label="Bodega" rules={[{ required: true }]}>
            <Select placeholder="seleccione una Bodega" allowClear>
              {bodegas?.map((bodega) => {
                return <Option value={bodega.id}>{bodega.nombre}</Option>;
              })}
            </Select>
          </Form.Item>

          {prestamo ? (
            <>
              <Form.Item
                label="Tope de prestamo"
                name="tope_de_prestamo"
                rules={[{ required: false }]}
              >
                <InputNumber />
              </Form.Item>
            </>
          ) : null}

          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: false }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default Editseller;
