import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Layouts from "../../../components/Layouts";
import { Button, Form, Modal, Select, Space } from "antd";
import { getDirectusClient } from "../../../lib/directus";
import { readItem, readItems, updateItem } from '@directus/sdk';

const { Option } = Select;
function OrderAssign(props) {
  let { order_id } = useParams();

  const [order, setOrder] = useState({});
  const [bodegas, setBodegas] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    console.log(order_id);
    const getOrder = async () => {
      const directus = await getDirectusClient();
      await directus
        .request(
        readItem("ordenes_decompra",order_id, { fields: "*.*.*" }))
        .then((res) => {
          console.log(res);
          setOrder(res);
        });
    };

    const getBodegas = async () => {
      const directus = await getDirectusClient();
      await directus
        .request(
        readItems("bodegas",{ fields: "*.*" }))
        .then((res) => {
          console.log(res);
          setBodegas(res);
        });
    };
    getOrder();
    getBodegas();
  }, []);

  const onFinish = async (values) => {
    const directus = await getDirectusClient();
    await directus
      .request(
        updateItem("ordenes_decompra", order_id, {
          bodegas: {
            create: [
              {
                ordenes_decompra_id: order_id,
                bodegas_id: {
                  id: values.bodega,
                },
              },
            ],
            update: [],
            delete: [],
          },
        })
      )
      .then((res) => {
        Modal.success({
          content: "Orden Asignada correctamente",
          onOk() {
            navigate("/orders");
          },
        });
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Layouts>
      <Space>
        <div>
          <p>Orden #: {order.id} </p>
          <p>Factura #: {order.numero_factura} </p>
          <p>proveedor : {order.proveedor ? order.proveedor.nombre : null}</p>
          <p>fecha creacion : {order.date_created}</p>
        </div>
        <div>
          {order.productos
            ? order.productos.map((producto) => {
                return (
                  <p>{producto ? producto.producto_detalle.nombre : null}</p>
                );
              })
            : null}
        </div>
        <div>
          <h2>Asignar Bodega</h2>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="bodega"
              label="Bodega"
              rules={[{ required: true }]}
            >
              <Select placeholder="selecione una bodega" allowClear>
                {bodegas.map((bodega) => {
                  return (
                    <Option key={bodega.id} value={bodega.id}>
                      {bodega.nombre}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Asignar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Space>
    </Layouts>
  );
}

export default OrderAssign;
