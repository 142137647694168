import React, { useEffect, useState } from 'react';
import Layouts from '../../components/Layouts';
import { getDirectusClient } from "../../lib/directus";
import { createItem, readItems, readPermissions } from '@directus/sdk';
import { Button, Modal, Select, Space, Table } from 'antd';
import { Checkbox, Form, Input } from "antd";

import { readUsers } from '@directus/sdk';
import { Link } from 'react-router-dom';
import Editar from './components/Editar';
import { useSelector } from 'react-redux';
import validarpermiso from '../../lib/utils';

function Rutas(props) {

    const [rutas, setRutas] = useState([])
  const [openmodalcrear, setOpenmodalcrear] = useState(false);
    const [openmodaleditar, setOpenmodaleditar] = useState(false);
  const [coordinadores, setCoordinadores] = useState([])
  const [rutaeditar, setRutaeditar] = useState({})
const permisos = useSelector((state)=>state.permisos)
  const [fielderror, setFielderror] = useState("")
  const [error, setError] = useState(false)

  const [listapermisos, setListapermisos] = useState([])
const userdata = useSelector((state)=>state.userdata)
  const getUsers = async () => {
     const directus = await getDirectusClient();
     await directus.request(
      readUsers({
        
        fields: ["*"],
        filter: {
          role: {
            id: "954fe3f5-1726-451e-8979-88dd68c83765",
          },
        },
      })
    )
       .then((result) => {
      console.log(result);
       setCoordinadores(result);
    }).catch((e)=>console.log(e));
   
  }
  
   


  const getRutas = async () => {

    const directus = await getDirectusClient();
   await directus.request(
      readItems("rutas", {
        fields: ["*.*.*"],
        filter: {
       
        },
      })
   ).then((rutasres) => {
      console.log(rutasres);
      setRutas(rutasres);
   }).catch((err) => {
      console.log(err);
    });

      
  };
    
     const handleOk = () => {
       setOpenmodalcrear(false);
     };
     const handleCancel = () => {
       setOpenmodalcrear(false);
     };
  
       const handleCanceleditar = () => {
         setOpenmodaleditar(false);
       };


  useEffect(() => {
    getRutas();
    getUsers()

  }, []);

    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
      },
      {
        title: "coordinador",
        dataIndex: "coordinador",
        render: (_, record) => {
          return <p>{record.coordinador[0].coordinador_id.first_name}</p>;
        },
      },
      {
        title: "Acciones",
        key: "Acciones",
        render: (_, record) => (
          <Space size="middle">
            <Link to={"/circuito/" + record.id + "/" + record.nombre}>
              Circuitos
            </Link>
            <Link to={"/vendedores/" + record.id + "/" + record.nombre}>
              Vendedores
            </Link>

            {validarpermiso("rutas", "update", permisos) ? (
              <a
                onClick={() => {
                  setOpenmodaleditar(true);
                  setRutaeditar(record);
                }}
              >
                editar
              </a>
            ) : null}

            {validarpermiso("rutas", "delete", permisos) ? (
              <a>eliminar</a>
            ) : null}
          </Space>
        ),
      },
    ];
  
  const onFinish = async(r) => {
  const directus = await getDirectusClient();
     await directus.request(
      createItem("rutas", {
        coordinador: {
          create: [
            {
              rutas_id: "+",
              coordinador_id: {
                id: r.coordinador,
              },
            },
          ],
          update: [],
          delete: [],
        },
        nombre: r.nombre,
        descripcion: r.descripcion,
      })
     ).then((res) => {
       console.log(res);
       handleCancel()
     }).catch((er) => {
       console.log(er.errors[0].extensions.field);
       setFielderror(er.errors[0].extensions.field);
       setError(true)
    })
  
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  

    return (
      <Layouts>
        {console.log(userdata)}
        {validarpermiso("rutas", "create", permisos) ? (
          <Button onClick={() => setOpenmodalcrear(true)}>Crear Ruta</Button>
        ) : null}
        {validarpermiso("rutas", "read", permisos)? (
          <Table dataSource={rutas} columns={columns} />
        ) : (
          <h3>No tiene permisos para ver rutas</h3>
        )}

        <Modal
          title="Crear Coordinador"
          open={openmodalcrear}
          onOk={false}
          onCancel={handleCancel}
          footer={null}
        >
          {error ? (
            <p style={{ color: "red" }}>
              El campo {fielderror} ya existe en el sistema{" "}
            </p>
          ) : null}
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "ingresar nombre",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="descripcion"
              name="descripcion"
              rules={[
                {
                  required: true,
                  message: "ingresar descripcion",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="coordinador"
              name="coordinador"
              rules={[
                {
                  required: true,
                  message: "ingresar descripcion",
                },
              ]}
            >
              <Select>
                {coordinadores.map((coordinador) => {
                  return (
                    <Select.Option value={coordinador.id}>
                      {coordinador.first_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Editar
          openmodaleditar={openmodaleditar}
          data={rutaeditar}
          coordinadores={coordinadores}
          handleCanceleditar={handleCanceleditar}
        />
      </Layouts>
    );
}

export default Rutas;