import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Layouts from '../../../components/Layouts';
import { getDirectusClient } from '../../../lib/directus';
import { readUsers } from '@directus/sdk';
import { Space, Table } from 'antd';
import { useSelector } from 'react-redux';

function Vendedores(props) {
  const { idruta, nombreruta } = useParams();
  const userdata = useSelector((state) => state.userdata);
  const [vendedores, setVendedores] = useState([])
    const getVendedores = async() => {
        const directus = await getDirectusClient()
       
            const result = await directus.request(
              readUsers({
                fields: ["*"],
                filter: {
                  bodega: userdata.bodega.id,
                  rutas: {
                    rutas_id: idruta,
                  },
                },
              })
            );
      console.log(result);
      setVendedores(result)
           
     
    }
  
      const columns = [
        {
          title: "Nombre",
          dataIndex: "first_name",
        },
        {
          title: "Apellidos",
          dataIndex: "last_name",
        },
    
     
      ];
  useEffect(() => {
    getVendedores()
  },[])
    return (
      <Layouts>
        Lista de vendedores de ruta {nombreruta}
        <Table dataSource={vendedores} columns={columns} />
      </Layouts>
    );
}

export default Vendedores;