import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDirectusClient } from "../../../lib/directus";
import Layouts from '../../../components/Layouts';
import { Descriptions, Spin, Table, Button, Modal, Form, Input, Select, message, Space } from 'antd';
import { readItem, readItems, createItem, updateItem, deleteItem } from '@directus/sdk'; 
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"; 
const VerEncuesta = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [encuesta, setEncuesta] = useState(null);
  const [preguntas, setPreguntas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tipoRespuesta, setTipoRespuesta] = useState('');
  const [nuevaPregunta, setNuevaPregunta] = useState('');
  const [opciones, setOpciones] = useState([]);
  const directus = getDirectusClient();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editPregunta, setEditPregunta] = useState('');
  const [formedit] =Form.useForm();

  useEffect(() => {
    const fetchEncuesta = async () => {
      try {
         await directus.request(readItem('encuestas', id)).then((response) => {
           setEncuesta(response);
         });
       
        await directus.request(readItems('preguntas', {
           fields: ['*.*.*'],
          filter: { encuesta: id }
        })).then((res) => {
            console.log(res);
            setPreguntas(res);
            
        });
        
      } catch (error) {
        console.error('Error al recuperar la encuesta:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEncuesta();
  }, [id]);


      const fetchEncuesta = async () => {
        try {
          await directus.request(readItem("encuestas", id)).then((response) => {
            setEncuesta(response);
          });

          await directus
            .request(
              readItems("preguntas", {
                fields: ["*.*.*"],
                filter: { encuesta: id },
              })
            )
            .then((res) => {
              console.log(res);
              setPreguntas(res);
            });
        } catch (error) {
          console.error("Error al recuperar la encuesta:", error);
        } finally {
          setLoading(false);
        }
      };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTipoRespuestaChange = value => {
    setTipoRespuesta(value);
  };

  const handlePreguntaChange = e => {
    setNuevaPregunta(e.target.value);
  };

  const handleOpcionesChange = (index, value) => {
    const newOpciones = [...opciones];
    newOpciones[index] = value;
    setOpciones(newOpciones);
  };

  const handleRemoveOption = (remove, index) => {
    remove(index);
  };

  const onFinish = async (values) => {
    console.log(values);
    try {
      let opciones = {};
      if (tipoRespuesta === 'multiple') {
        opciones = {
          opciones_multiple: values.opciones,
        };
      } else if (tipoRespuesta === 'unica') {
        opciones = {
          opcion_multiple_una: values.opciones,
        };
      }

      const nuevaPreguntaData = {
        pregunta: nuevaPregunta,
        tipo_respuesta: tipoRespuesta,
        encuesta: id,
        ...opciones
      };

      await directus.request(createItem('preguntas', nuevaPreguntaData));
      message.success('Pregunta guardada con éxito');
      setPreguntas([...preguntas, nuevaPreguntaData]);
    } catch (error) {
      message.error('Error al guardar la pregunta');
      console.error('Error al guardar la pregunta:', error);
    }
  };

  const handleEdit = (pregunta) => {
    setIsEditModalVisible(true);
    setEditPregunta(pregunta);

    console.log(pregunta);
    formedit.setFieldsValue({
      pregunta: pregunta.pregunta, 
      tipo_respuesta: pregunta.tipo_respuesta,
      opciones: pregunta.tipo_respuesta==='multiple' ? pregunta.opciones_multiple : pregunta.opcion_multiple_una
    });
   
  };

  const handleEditOk = () => {
    setIsEditModalVisible(false);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleEditPreguntaChange = e => {
    setEditPregunta(e.target.value);
  };

  const handleEditTipoRespuestaChange = value => {
  
  };

  const handleEditOpcionesChange = (index, value) => {
  
  };

  const handleEditRemoveOption = (remove, index) => {
    remove(index);
  };

  const onEditFinish = async (values) => {
 console.log(values);
 try {
   let opciones = {};
   if (values.tipo_respuesta === "multiple") {
     opciones = {
       opciones_multiple: values.opciones,
     };
   } else if (values.tipo_respuesta === "unica") {
     opciones = {
       opcion_multiple_una: values.opciones,
     };
   }

   const nuevaPreguntaData = {
     pregunta: values.pregunta,
     tipo_respuesta: values.tipo_respuesta,
     encuesta: id,
     ...opciones,
   };

   await directus.request(updateItem("preguntas",editPregunta.id, nuevaPreguntaData));
   message.success("Pregunta guardada con éxito");

   fetchEncuesta();
   setIsEditModalVisible(false);
   formedit.resetFields();
 } catch (error) {
   message.error("Error al guardar la pregunta");
   console.error("Error al guardar la pregunta:", error);
 }
  };

  const handleDelete = async (pregunta) => {
    Modal.confirm({
      title: '¿Estás seguro de que quieres eliminar esta pregunta?',
      onOk() {
        try {
          directus.request(deleteItem("preguntas", pregunta.id));
          message.success("Pregunta eliminada con éxito");
          setPreguntas(preguntas.filter((p) => p.id !== pregunta.id));
        } catch (error) {
          message.error("Error al eliminar la pregunta");
          console.error("Error al eliminar la pregunta:", error);
        }
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "Pregunta",
      dataIndex: "pregunta",
      key: "pregunta",
    },
    {
      title: "Tipo",
      dataIndex: "tipo_respuesta",
      key: "tipo_respuesta",
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (text, pregunta) => (
        <Space size="middle">
          <Button onClick={() => handleEdit(pregunta)}>Editar</Button>
          <Button type="danger" onClick={() => handleDelete(pregunta)}>Eliminar</Button>
        </Space>
      ),
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <Layouts>
      <h1>Detalles de la Encuesta</h1>
      {encuesta ? (
        <>
          <Descriptions bordered>
            <Descriptions.Item label="Nombre">
              {encuesta.nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Descripción">
              {encuesta.descripcion}
            </Descriptions.Item>
          </Descriptions>
          <Table dataSource={preguntas} columns={columns} rowKey="id" />
          <Button type="primary" onClick={showModal}>
            Agregar Pregunta
          </Button>
          <Modal
            title="Agregar Nueva Pregunta"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form layout="vertical" onFinish={onFinish} initialValues={{ pregunta: '', tipo_respuesta: '', opciones: [] }}>
              <Form.Item name={"pregunta"} label="Pregunta">
                <Input onChange={handlePreguntaChange} />
              </Form.Item>
              <Form.Item name={"tipo_respuesta"} label="Tipo de Respuesta">
                <Select onChange={handleTipoRespuestaChange}>
                  <Select.Option value="multiple">
                    Opción Múltiple
                  </Select.Option>
                  <Select.Option value="unica">Opción Única</Select.Option>
                  <Select.Option value="texto">Texto</Select.Option>
                  <Select.Option value="numero">Número</Select.Option>
                </Select>
              </Form.Item>
              {(tipoRespuesta === "multiple" || tipoRespuesta === "unica") && (
                <Form.List name="opciones">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "respuesta"]}
                            rules={[
                              { required: true, message: "Missing first name" },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                      
                          <MinusCircleOutlined onClick={() => handleRemoveOption(remove, name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              )}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Guardar Pregunta
                </Button>
              </Form.Item>
            </Form>
          </Modal>

        <Modal
          title="Editar Pregunta"
          visible={isEditModalVisible}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
        >
          <Form  form={formedit} layout="vertical" onFinish={onEditFinish} >
            <Form.Item name={"pregunta"} label="Pregunta">
              <Input onChange={handleEditPreguntaChange} />
            </Form.Item>
            <Form.Item name={"tipo_respuesta"} label="Tipo de Respuesta">
              <Select onChange={handleEditTipoRespuestaChange}>
                <Select.Option value="multiple">Opción Múltiple</Select.Option>
                <Select.Option value="unica">Opción Única</Select.Option>
                <Select.Option value="texto">Texto</Select.Option>
                <Select.Option value="numero">Número</Select.Option>
              </Select>
            </Form.Item>
           
              <Form.List name="opciones">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "respuesta"]}
                          rules={[
                            { required: true, message: "Falta una respuesta" },
                          ]}
                        >
                          <Input placeholder="Respuesta" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => handleRemoveOption(remove, name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Añadir opción
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Guardar Cambios
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        </>
      ) : (
        <p>No se encontró la encuesta solicitada.</p>
      )}
    </Layouts>
  );
};

export default VerEncuesta;
