import { createSlice } from "@reduxjs/toolkit";

export const permisosSlice = createSlice({
  name: "permisos",
  initialState: {},
  reducers: {
    permisosadd: (state, action) => {
      console.log(action);
      return action.payload;
    },
  },
});

export const { permisosadd } = permisosSlice.actions;
export default permisosSlice.reducer;
