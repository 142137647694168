import { useEffect, useState } from "react";
import { Button, Space, Table, Tag } from "antd";
import Layouts from "../../../components/Layouts";

import { getDirectusClient } from "../../../lib/directus";
import { Link } from "react-router-dom";
import { readItems } from "@directus/sdk";
const columns = [
  {
    title: "Orden",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Factura",
    dataIndex: "numero_factura",
    key: "numero_factura",
  },
  {
    title: "Bodega",
    dataIndex: "bodegas",
    key: "bodegas",
  },
  {
    title: "Responsable",
    dataIndex: "user_created",
    key: "user_created",
  },
  {
    title: "Estado",
    key: "estado",
    dataIndex: "estado",
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <a>Detalles</a>
      </Space>
    ),
  },
];

const Orders = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchProductos() {
      const directus = await getDirectusClient();
      await directus
        .request(
          readItems("ordenes_decompra", { fields: "*.*.*", limit: -1, sort: "-date_created" })).then((orders) => {
            orders.map((order) => {
        //order.bodegas  =
        order.bodegas =
          order.bodegas.length > 0 ? order.bodegas[0].bodegas_id.nombre : null;
        order.user_created = order.user_created.first_name;
        order.estado = order.estado.nombre;
      });
      setData(orders);
        });

    
    }

    fetchProductos();
  }, []);
  return (
    <Layouts>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Button>
          <Link to="/orders_create">Agregar nueva orden</Link>
        </Button>
        <Table columns={columns} dataSource={data} />
      </Space>
    </Layouts>
  );
};
export default Orders;
