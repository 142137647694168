import React, { useEffect, useState } from 'react';
import Layouts from '../../components/Layouts';
import { getDirectusClient } from '../../lib/directus';
import { readItems } from '@directus/sdk';
import validarpermiso from '../../lib/utils';
import { useSelector } from 'react-redux';
import { Button, DatePicker, Space, Table } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
const { RangePicker } = DatePicker;
function Reporte(props) {

    const [gestiones, setGestiones] = useState([])
  const permisos = useSelector((state) => state.permisos);
  const userdata = useSelector((state)=>state.userdata)
    const [startDate, setStartDate] = useState("2023-01-01");
    var hoy = moment();
    var hoyf = hoy.add(1, "d").format("YYYY-MM-DD");
const navigate = useNavigate();
    const [endDate, setEndDate] = useState(hoyf);
    
    const columns = [
      {
        title: "cliente",
        dataIndex: "cliente",
        render: (_, record) => {
          return <p>{record.cliente?.first_name}</p>;
        },
      },
      {
        title: "Estado",
        dataIndex: "estado",
        render: (_, record) => {
          return <p>{record.estado?.estado}</p>;
        },
      },
      {
        title: "ID",
        dataIndex: "id",
      },

      {
        title: "Acciones",
        key: "Acciones",
        render: (_, record) => (
          <Space size="middle">
            <Link to={"/circuito/" + record.id + "/" + record.nombre}>
              Circuitos
            </Link>
            <Link to={"/vendedores/" + record.id + "/" + record.nombre}>
              Vendedores
            </Link>

            {validarpermiso("rutas", "update", permisos) ? (
              <a
                onClick={() => {
                  // setOpenmodaleditar(true);
                  //setRutaeditar(record);
                }}
              >
                editar
              </a>
            ) : null}

            {validarpermiso("rutas", "delete", permisos) ? (
              <a>eliminar</a>
            ) : null}
          </Space>
        ),
      },
    ];
  const getGestiones = async (startDate, endDate) => {
    const directus = await getDirectusClient();
    await directus
      .request(
        readItems("gestion_cliente_rutero", {
          fields: ["*.*.*"],
          filter: {
            date_created: {
              _between: [startDate, endDate],
            },
          },
        })
      )
      .then((gestiones) => {
        console.log(gestiones);
        setGestiones(gestiones);
      })
      .catch((err) => {
        console.log(err);
      });
  };

    useEffect(() => {
         getGestiones(startDate, endDate);
    },[])

        const onRangeChange = (dates, dateStrings) => {
          if (dates) {
          setStartDate(dateStrings[0]);
          setEndDate(dateStrings[1]);
          getGestiones(dateStrings[0], dateStrings[1]);
          } else {
            console.log("Clear");
          }
        };
    
    return (
      <Layouts>
        { userdata.role.name}
            <Space>
                     <RangePicker
          format={"YYYY/MM/DD HH:mm:ss"}
          onChange={onRangeChange}
          placeholder={["Fecha inicial", "Feha final"]}
          showTime
                />   
                <Button onClick={()=>navigate("/reporte_rutas_chart")} >Ver Grafica</Button>
            </Space>

        {validarpermiso("rutas", "read", permisos) ? (
          <Table dataSource={gestiones} columns={columns} />
        ) : (
          <h3>No tiene permisos para ver rutas</h3>
        )}
      </Layouts>
    );
}

export default Reporte;