import { Button } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Layouts from "../components/Layouts";
import { counterAdd } from "../features/counter/counterSlice";
import { getDirectusClient } from "../lib/directus";
import { readItems } from "@directus/sdk";

function Home(props) {
  const dispatch = useDispatch();

  const peticion = async () => {
    const directus = await getDirectusClient();

  //  const response = await directus.request(readItems('grupos', {  fields: ["*"]}));
    
    
  //  console.log(response);
  };

  useEffect(() => {
    peticion();
  }, []);
  return (
    <Layouts>
      <Button>Bienvenido</Button>
    </Layouts>
  );
}

export default Home;
