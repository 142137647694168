import React, { useEffect, useState } from "react";
import Layouts from "../../../components/Layouts";
import {
  Button,
  Form,
  Modal,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import { getDirectusClient } from "../../../lib/directus";
import {
  MinusCircleOutlined,
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Caja from "../components/Caja";
import Grupo from "../components/Grupo";
import Serial from "../components/Serial";
import Column from "antd/es/table/Column";
import { createItem, readItems, readUsers, updateItem, updateUser } from "@directus/sdk";
const { Title } = Typography;
const { Option } = Select;
const arraytotalproductos = [];
let deletecambio = false;

function LoanCreate(props) {
  const [vendedoresloan, setVendedoresloan] = useState([]);
  const [vendedorselect, setVendedorselect] = useState({});
  const [productoselect, setProductoselect] = useState({});
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [serialeslist, setSerialeslist] = useState([]);
  const [modalseriales, setModalseriales] = useState(false);
  const [productosagregados, setProductosagregados] = useState([]);
  const [productosagregadosver, setProductosagregadosver] = useState([]);

  const [form] = Form.useForm();

  const items = [
    {
      key: "1",
      label: `Por caja`,
      children: (
        <Caja
          productosagregados={productosagregados}
          setProductosagregados={setProductosagregados}
        />
      ),
    },
    {
      key: "2",
      label: `Por serial`,
      children: (
        <Serial
          productosagregados={productosagregados}
          setProductosagregados={setProductosagregados}
        />
      ),
    },
    {
      key: "3",
      label: `Por caja y grupo`,
      children: (
        <Grupo
          productosagregados={productosagregados}
          setProductosagregados={setProductosagregados}
        />
      ),
    },
  ];
  async function fetchProductos() {
    const directus = await getDirectusClient();
    const { data: productos } = await directus.request(
      readItems("productos", { fields: "*.*", limit: -1 })
    );
    setProductos(productos);
  }

  const getVendedoresloan = async () => {
    const directus = await getDirectusClient();
    await directus
      .request(
      readItems("directus_users",{
        fields: ["*.*"],
        filter: {
          role: {
            name: {
              _eq: "vendedor",
            },
          },
          tope_de_prestamo: {
            _gt: 0,
          },
        },
      }))
      .then((res) => {
        console.log(res);
        setVendedoresloan(res);
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = async (value) => {
    console.log("buscando");
    const directus = await getDirectusClient();
    //   setLoading(true);
    const response = await directus.request(readUsers({
      search: value,
      fields: "*.*",
      limit: -1,
      filter: {
        role: {
          name: {
            _eq: "vendedor",
          },
        },
        tope_de_prestamo: {
          _gt: 0,
        },
      },
    }));
    console.log(response);
    setVendedoresloan(response);
    // setLoadings(false);
  };

  useEffect(() => {
    getVendedoresloan();
    fetchProductos();
  }, []);

  const onFinish = async (ev) => {
    const directus = await getDirectusClient();
    if (arraytotalproductos.length <= 0) {
      Modal.warning({
        title: "No hay seriales para prestar",
        content: "Por favor agrege seriales al prestamo e intente de nuevo",
      });
      console.log(ev);
    } else {
      console.log(ev);
      console.log(arraytotalproductos);
      let arrguardar = [];
      let arrguardarp = [];

      let arrproducto = [];

      arraytotalproductos.map((p) => {
        arrguardar.push({ id: p.id, user: "+", estado: 3 });
      });

      productosagregadosver.map((pro) => {
        arrproducto.push({ id: pro.id });
      });

      arraytotalproductos.map((p) => {
        arrguardarp.push({ id: p.id, prestamo: "+", estado: 3 });
      });

      directus
        .request(
        createItem("prestamos",{
          vendedor: ev.vendedor,
        }))
        .then((res) => {
          console.log(res);
          if (res.id > 0) {
            Modal.success({
              title: "Proceso exitoso",
              content: "Se realizo el prestamo correctamente pres",
            });
            setProductos([]);
            setModalseriales(false);
            setProductosagregados([]);
            setProductosagregadosver([]);
            arraytotalproductos.splice(0, arraytotalproductos.length);
            setVendedorselect({});
          }
        })
        .catch((err) => console.log(err));

      console.log(productosagregadosver);
 console.log("prueba funcion");
      console.log(arraytotalproductos);
      
      directus
        
          .request(
            updateUser(vendedorselect.id, {
              vendedor: ev.vendedor,
              seriales_prestados: {
                create: [],
                update: arrguardar,

                delete: [],
              },
              productos_prestados: {
                create: [],
                update: arrproducto,

                delete: [],
              },
            })
          )
          .then((res) => {
            console.log(res);
            if (res.id > 0) {
              Modal.success({
                title: "Proceso exitoso",
                content: "Se realizo el prestamo correctamente user",
              });

              setProductos([]);
              setModalseriales(false);
              setProductosagregados([]);
              setProductosagregadosver([]);
              //arraytotalproductos.splice(0, arraytotalproductos.length);
              setVendedorselect({});
            }
          })
          .catch((err) => console.log(err));
    }
  };

  const selecionado = (ev) => {
    console.log(vendedoresloan.find((item) => item.id === ev));
    setVendedorselect(vendedoresloan.find((item) => item.id === ev));
  };

  const selecionadoproducto = (ev) => {
    console.log(productos.find((item) => item.id === ev));
    setProductoselect(productos.find((item) => item.id === ev));
  };
  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    ejecutaconversion();
  }, [productosagregados]);

  const ejecutaconversion = () => {
    let existe = 0;
    productosagregados.map((producto) => {
      producto.prestamo = "+";
      let found = arraytotalproductos.find(
        (item) => item.serial === producto.serial
      );
      if (found) {
        existe = existe + 1;
      } else {
        arraytotalproductos.push(producto);
      }
    });

    console.log(existe);
    if (!deletecambio) {
      if (existe > 0) {
        Modal.error({
          title: "Seriales existentes",
          content: `Ya existen ${existe} serial(es) de los que intenta agregar al prestamo y se a(n) omitido`,
        });
        //existe = 0;
      } else {
      }
      deletecambio = false;
    }
    const productos = arraytotalproductos.reduce((acc, obj) => {
      console.log(obj);
      const key = obj.producto.nombre;
      if (!acc[key]) {
        acc[key] = { producto: key, seriales: [], precio: 0 };
      }
      acc[key].seriales.push({ serial: obj.serial, id: obj.id });
      acc[key].precio = obj.producto.preciosinimp; // Asumiendo que el precio es de $100
      acc[key].impuesto = obj.producto.grupo.impuesto; // Asumiendo que el precio es de $100
      acc[key].id = obj.producto.id; // Asumiendo que el precio es de $100
      return acc;
    }, {});

    const productosArray = Object.values(productos);

    console.log("largo" + arraytotalproductos.length);
    if (arraytotalproductos.length <= 0) {
      setProductosagregadosver([]);
    } else {
      setProductosagregadosver(productosArray);
      if (productosagregados.length === existe) {
        console.log("iguales");
        existe = 0;
      } else {
        Modal.success({
          title: `${
            productosagregados.length - existe
          } seriales Añadisos correctamente`,
        });
      }
    }
  };
  var total = 0;

  productosagregadosver.map((producto) => {
    producto.cantidad = producto.seriales.length;

    let preciot =
      parseFloat(producto.seriales.length) * parseFloat(producto.precio);
    let totalp = parseFloat(
      preciot + (preciot * producto.impuesto) / 100
    ).toFixed(2);
    producto.totalparcial = totalp;

    total = parseFloat(total) + parseFloat(totalp);
  });

  return (
    <Layouts>
      <Title level={4}>Crear Prestamo</Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="vendedor"
          label="Vendedor"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Selecciona un vendedor"
            showSearch={true}
            filterOption={false}
            onSearch={handleSearch}
            onChange={selecionado}
            style={{ width: 200 }}
          >
            {vendedoresloan.map((vendedor) => (
              <Option key={vendedor.id} value={vendedor.id} allowClear>
                {vendedor.first_name}
                <span style={{ color: "#20c77c", fontSize: 12 }}>
                  {vendedor.last_name}
                </span>
              </Option>
            ))}
          </Select>
        </Form.Item>
        {vendedorselect.id ? (
          <>
            {" "}
            <Space size={"large"}>
              Maximo de prestamo: {vendedorselect.tope_de_prestamo}
              <span style={{ color: "red" }}>
                {"      "}
                Prestamo usado: {vendedorselect.prestamo_usado}
              </span>
              <span style={{ color: "green" }}>
                Disponible:{" "}
                {vendedorselect.tope_de_prestamo -
                  vendedorselect.prestamo_usado}{" "}
              </span>
            </Space>
          </>
        ) : null}
        <Table
          columns={[
            { title: "Producto", dataIndex: "producto" },
            { title: "Cantidad", dataIndex: "cantidad" },
            { title: "Precio unitario ", dataIndex: "precio" },
            { title: "impuesto", dataIndex: "impuesto" },
            { title: "Total parcial", dataIndex: "totalparcial" },
            {
              title: "Action",
              key: "action",
              render: (_, record) => (
                <Space size="middle">
                  <Button
                    onClick={() => {
                      setSerialeslist(record.seriales);
                      setModalseriales(true);
                    }}
                    icon={<EyeOutlined />}
                    type="primary"
                  >
                    Ver seriales
                  </Button>
                  <Button
                    onClick={() => {
                      let contador = 0;
                      record.seriales.map((serial) => {
                        contador = contador + 1;
                        console.log("quitando" + serial.serial);

                        let nuevosseriales = productosagregados.filter(
                          (item) => item.serial !== serial.serial
                        );

                        let arran = arraytotalproductos.filter(
                          (item) => item.serial !== serial.serial
                        );

                        arraytotalproductos.splice(
                          0,
                          arraytotalproductos.length
                        );

                        arran.map((a) => {
                          arraytotalproductos.push(a);
                        });

                        setSerialeslist([]);

                        console.log(arraytotalproductos);

                        setProductosagregados(arraytotalproductos);

                        deletecambio = true;
                      });

                      console.log(contador);
                      if (arraytotalproductos.length <= 0) {
                        ejecutaconversion();
                      }
                    }}
                    icon={<DeleteOutlined />}
                    type="primary"
                  >
                    Quitar producto
                  </Button>
                </Space>
              ),
            },
          ]}
          dataSource={productosagregadosver}
        ></Table>
        Total a prestar : {total}{" "}
        <span style={{ color: "red" }}>
          {parseFloat(total) >
          parseFloat(
            vendedorselect.tope_de_prestamo - vendedorselect.prestamo_usado
          )
            ? "Se excedió el limite de prestamo para el vendedor seleccionado"
            : console.log(
                parseFloat(
                  vendedorselect.tope_de_prestamo -
                    vendedorselect.prestamo_usado
                )
              )}
        </span>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            disabled={
              parseFloat(total) >
              parseFloat(
                vendedorselect.tope_de_prestamo - vendedorselect.prestamo_usado
              )
                ? true
                : false
            }
            type="primary"
            htmlType="submit"
          >
            Crear prestamo
          </Button>
        </Form.Item>
      </Form>

      <h4>Añadir productos al prestamo</h4>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      <Modal
        title="Seriales"
        onOk={() => {
          setSerialeslist([]);
          setModalseriales(false);
          deletecambio = false;
        }}
        onCancel={() => {
          setModalseriales(false);
          deletecambio = false;
        }}
        open={modalseriales}
      >
        {serialeslist.map((serial) => {
          return (
            <Space size={"small"}>
              {" "}
              <span>{serial.serial}</span>
              <Button
                onClick={() => {
                  console.log("eliminar serial " + serial.serial);
                  console.log(productosagregados);

                  Modal.confirm({
                    title: "Quieres eliminar serial " + serial.serial,
                    onOk() {
                      let nuevosseriales = productosagregados.filter(
                        (item) => item.serial !== serial.serial
                      );

                      let arran = arraytotalproductos.filter(
                        (item) => item.serial !== serial.serial
                      );

                      arraytotalproductos.splice(0, arraytotalproductos.length);

                      arran.map((a) => {
                        arraytotalproductos.push(a);
                      });

                      let lisserrialnew = serialeslist.filter(
                        (item) => item.serial !== serial.serial
                      );

                      setSerialeslist(lisserrialnew);

                      console.log(productosagregados.length);

                      setProductosagregados(nuevosseriales);
                      deletecambio = true;
                    },
                    onCancel() {
                      console.log("cancelar");
                    },
                  });
                }}
              >
                Eliminar
              </Button>
            </Space>
          );
        })}
      </Modal>
    </Layouts>
  );
}

export default LoanCreate;
