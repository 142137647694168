

import React, { useEffect, useState } from 'react';
import Layouts from '../../components/Layouts';
import { getDirectusClient } from "../../lib/directus";
import { createItem, readItems, readPermissions, updateItem } from '@directus/sdk';
import { Button, Col, DatePicker, InputNumber, Modal, Radio, Row, Select, Space, Table } from 'antd';
import { Checkbox, Form, Input } from "antd";

import { readUsers } from '@directus/sdk';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


function Circuitos(props) {

     let { idruta, nombreruta} = useParams();
  const [formcreate] = Form.useForm()
  const [form]= Form.useForm()
    const [rutas, setRutas] = useState([])
  const [openmodalcrear, setOpenmodalcrear] = useState(false);
  const [openmodalclientes, setOpenmodalclientes] = useState(false);
  const [vendedores, setVendedores] = useState([])
  const [clientes, setClientes] = useState([]);
   const [clientescircuito, setClientescircuito] = useState([]);

  const [circuitoselect, setCircuitoselect]=useState({})
 
const [dias, setDias] = useState([])
  const [fielderror, setFielderror] = useState("")
  const [error, setError] = useState(false)
  const [tipo, setTipo] = useState("");

  const userdata = useSelector((state)=>state.userdata)
  
  const getUsers = async () => {
    const directus = await getDirectusClient();
    
  await directus
    .request(
      readUsers({
        fields: ["*"],
        filter: {
          role: {
            name: "vendedor",
          },
        },
      })
    )
    .then((result) => {
      console.log(result);
      setVendedores(result);
    })
    .catch((err) => {
      console.log(err);
    });
   
  }

  const getClientescircuito = async (idcircuito) => {

    const directus = await getDirectusClient();

    await directus
      .request(
        readItems("circuitos_clientes", {
          fields: ["*", "clientes_id.*"],
          filter: {
            circuitos_id: {
              id: {
                _eq: idcircuito,
              },
            },

     
          },
        })
      )
      .then((result) => {
        console.log(result);
        setClientescircuito(result);
         getClientes(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };


    const getCoordinadores = async () => {
      const directus = await getDirectusClient();

      await directus
        .request(
          readUsers({
            fields: ["*"],
            filter: {
              role: {
                name: "vendedor ruta",
              },
            },
          })
        )
        .then((result) => {
          console.log(result);
          setVendedores(result);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getDias = async () => {
    const directus= await getDirectusClient();
     await directus.request(
        readItems("dias", {
       
        })
      ).then((dias) => {
         console.log(dias);
         setDias(dias);
      }).catch((err)=>console.log(dias))

     
    };
  
  

  
  const getRutas = async () => {
    const directus = await getDirectusClient();
    await directus.request(
      readItems("circuitos", {
        filter: {
          ruta: {
            id: idruta
          }
        },
      })
    ).then((rutasres) => {
     
      console.log(rutasres);
      setRutas(rutasres) 
    }).catch((err) => {
      console.log(err);
    });

  };
    
     const handleOk = () => {
       setOpenmodalcrear(false);
     };
     const handleCancel = () => {
       setOpenmodalcrear(false);
     };

  useEffect(() => {
    getRutas();
    getDias()
    getUsers()
    if (userdata) {
      console.log(userdata);
    }
    
  }, []);

    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
      },
      {
        title: "Acciones",
        key: "Acciones",
        render: (_, record) => (
          <Space size="middle">
            <Link to={"/circuito/" + record.id}>Ver en mapa</Link>
            <a>Vendedores</a>
            <a onClick={() => {
              console.log(record)
              setCircuitoselect(record)
             
              getClientescircuito(record.id)
            }}>Clientes</a>
            <a>editar</a>
            <a>eliminar</a>
          </Space>
        ),
      },
    ];
  
  const getClientes = async (clientesexistentes) => {
    
    var clientesexistearray = []
    
    clientesexistentes.map((cliente) => {
      clientesexistearray.push(cliente.clientes_id.id);
    })
    
    const directus = getDirectusClient()
      await directus
    .request(
      readUsers({
        fields: ["*"],
        filter: {
          role: {
            name: "clientes",
          },
        },
      })
    )
    .then((result) => {
      console.log(result);

      clientesexistearray.map((cliente) => {
        result = result.filter((item) => item.id != cliente);
      })
      console.log("dataresult");
       console.log(clientesexistearray);
console.log(result);

      setClientes(result);
      setOpenmodalclientes(true);
    })
    .catch((err) => {
      console.log(err);
    });
  }
  
  const onFinish = async (r) => {
    console.log(r);

    if (r.tipo=="dias") {
       var created = [];
       r.dias.map((dia) => {
         created.push({
           circuitos_id: "+",
           dias_id: {
             id: dia,
           },
         });
       });
    } else if (r.tipo == "tiempo") {
      console.log("tiempo");
    }
   
  const directus = await getDirectusClient();
     await directus
       .request(
         createItem("circuitos", {
           dias: {
             create: created,
             update: [],
             delete: [],
           },
           nombre: r.nombre,
           descripcion: r.descripcion,
           tipo: r.tipo,
           tiempo: r.tiempo,
           dia_tiempo: r.dia,
           fecha_inicio: r.fecha_inicio,
           vendedor: r.vendedor
         })
       )
       .then((res) => {
         console.log(res);
         handleCancel();
       })
       .catch((er) => {
         console.log(er.errors[0].extensions.field);
         setFielderror(er.errors[0].extensions.field);
         setError(true);
       });
  
  };


  const guardarvendedorenruta = async(r) => {
       const directus = await getDirectusClient();
    await directus
      .request(
        updateItem("rutas", props.data.id, {
          vendedores: {
            create: [
              {
                rutas_id: "+",
                directus_users_id: {
                  id: r.coordinador,
                },
              },
            ],
            update: [],
            delete: [
              props.data.coordinador ? props.data.coordinador[0].id : null,
            ],
          },

          nombre: r.nombre,

          descripcion: r.descripcion,
        })
      )
      .then((res) => {
        console.log(res);
        props.handleCanceleditar();
        setError("");
      })
      .catch((er) => {
        console.log(er.errors[0].extensions.field);
        setFielderror(er.errors[0].extensions.field);
        setError(true);
      });
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

   const handleSearch = async (value) => {
     console.log("buscando");


     var clientesexistearray = [];

     clientescircuito.map((cliente) => {
       clientesexistearray.push(cliente.clientes_id.id);
     });


     const directus = await getDirectusClient();
     //   setLoading(true);
  await directus
    .request(
      readUsers({
        search: value,
        fields: "*.*",
        limit: -1,
        filter: {
          role: {
            name: {
              _eq: "clientes",
            },
          },
        },
      })
    )
    .then((response) => {
            clientesexistearray.map((cliente) => {
              response = response.filter((item) => item.id != cliente);
            });
      console.log(response);
      setClientes(response);
    });
    
     // setLoadings(false);
   };
  
  const anadirclientes = async(v) => {
    console.log(v.clientes.length);
     const directus = await getDirectusClient();
    //   setLoading(true);
 var creates = []   
    v.clientes.map((cliente) => {
      creates.push({
        circuitos_id: circuitoselect.id,
        clientes_id: {
          id: cliente,
        },
      });
})

  await directus
    .request(
      updateItem("circuitos", circuitoselect.id, {
        clientes: {
          create: creates,
          update: [],
          delete: [],
        },
      })
    )
    .then((response) => {
      console.log(response);
      getClientescircuito(circuitoselect.id)
       form.resetFields();
    });
    
     // setLoadings(false);
 }

    return (
      <Layouts>
        <h3 style={{ color: "#555" }}>Circuitos de ruta: {nombreruta}</h3>
        <Button onClick={() => setOpenmodalcrear(true)}>Crear Circuito</Button>
        <Table dataSource={rutas} columns={columns} />;
        <Modal
          title={
            <p>
              Crear Ciruito para Ruta:{" "}
              <span style={{ color: "green" }}>{nombreruta}</span>
            </p>
          }
          open={openmodalcrear}
          onOk={false}
          onCancel={handleCancel}
          footer={null}
        >
          {error ? (
            <p style={{ color: "red" }}>
              El campo {fielderror} ya existe en el sistema{" "}
            </p>
          ) : null}

          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Vendedor"
              name="vendedor"
              rules={[
                {
                  required: true,
                  message: "ingresar descripcion",
                },
              ]}
            >
              <Select>
                {vendedores.map((vendedor) => {
                  return (
                    <Select.Option value={vendedor.id}>
                      {vendedor.first_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "ingresar nombre",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Descripcion"
              name="descripcion"
              rules={[
                {
                  required: true,
                  message: "ingresar descripcion",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Tipo de visitas"
              name="tipo"
              rules={[
                {
                  required: true,
                  message: "ingresar descripcion",
                },
              ]}
            >
              <Select
                onChange={(e) => {
                  setTipo(e);
                }}
              >
                <Select.Option value="dias">Por dias</Select.Option>
                <Select.Option value="tiempo">por Tiempo</Select.Option>
              </Select>
            </Form.Item>
            {tipo == "dias" ? (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "no puede estar vacio ",
                  },
                ]}
                name="dias"
                label="Dias de visitas"
              >
                <Checkbox.Group>
                  <Row>
                    {dias.map((dia) => {
                      return (
                        <Col span={8}>
                          <Checkbox
                            value={dia.id}
                            style={{ lineHeight: "32px" }}
                          >
                            {dia.dia}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            ) : null}
            {tipo == "tiempo" ? (
              <>
                <Form.Item
                  label="Fecha de inicio"
                  name="fecha_inicio"
                  rules={[
                    {
                      required: true,

                      message: "no puede estar vacio ",
                    },
                    {
                      type: "date",
                      message: "Debe ser una fecha ",
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>
                <Form.Item
                  label="Tiempo"
                  name="tiempo"
                  rules={[
                    {
                      required: true,

                      message: "no puede estar vacio ",
                    },
                    {
                      type: "integer",
                      message: "Debe ser numericoo ",
                    },
                  ]}
                >
                  <InputNumber min={1} max={365} />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "no puede estar vacio ",
                    },
                  ]}
                  name="dia"
                  label="El siguiente"
                >
                  <Radio.Group>
                    <Row>
                      {dias.map((dia) => {
                        return (
                          <Col span={8}>
                            <Radio
                              value={dia.id}
                              style={{ lineHeight: "32px" }}
                            >
                              {dia.dia}
                            </Radio>
                          </Col>
                        );
                      })}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </>
            ) : null}
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={<p>Añadir clientes</p>}
          open={openmodalclientes}
          onOk={false}
          onCancel={() => setOpenmodalclientes(false)}
          footer={null}
        >
          {circuitoselect.nombre}
          <Form onFinish={anadirclientes} form={form}>
            <Form.Item name="clientes" label="Seleccionar clientes">
              <Select
                mode="multiple"
                name={"clientes"}
                showSearch={true}
                filterOption={false}
                onSearch={handleSearch}
                style={{ width: "100%" }}
                placeholder="Seleccionar clientes"
              >
                {clientes?.map((cliente) => (
                  <Select.Option key={cliente.id} value={cliente.id}>
                    {cliente.first_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                asignar
              </Button>
            </Form.Item>
          </Form>
          <Table dataSource={clientescircuito}>
            <Table.Column
              title="Nombre"
              dataIndex="first_name"
              render={(_, record) => {
                return record.clientes_id.first_name;
              }}
            />
            <Table.Column
              title="Apellido"
              dataIndex="last_name"
              render={(_, record) => {
                return record.clientes_id.last_name;
              }}
            />
            <Table.Column
              title="Acciones"
              key="acciones"
              render={(_, record) => (
                <Space size="middle">
                  <a
                    onClick={async () => {
                      const directus = await getDirectusClient();
                      //   setLoading(true);

                      await directus
                        .request(
                          updateItem("circuitos", circuitoselect.id, {
                            clientes: {
                              create: [],
                              update: [],
                              delete: [
                               record.id
                              ],
                            },
                          })
                        )
                        .then((response) => {
                          console.log(response);
                          getClientescircuito(circuitoselect.id);
                          form.resetFields();
                        });
                    }}
                  >
                    eliminar
                  </a>
                </Space>
              )}
            />
          </Table>
        </Modal>
      </Layouts>
    );
}

export default Circuitos;