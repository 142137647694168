import React, { useEffect, useState } from "react";
import Layouts from "../../../components/Layouts";
import {
  Button,
  Form,
  Modal,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import { getDirectusClient } from "../../../lib/directus";
import {
  MinusCircleOutlined,
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Caja from "../components/Caja";
import Grupo from "../components/Grupo";
import Serial from "../components/Serial";
import Column from "antd/es/table/Column";
import Search from "antd/es/input/Search";
import { readItems, readUser, readUsers, updateItem } from "@directus/sdk";
const { Title } = Typography;
const { Option } = Select;
const arraytotalproductos = [];
let deletecambio = false;

function Loanrepay(props) {
  const [vendedor, setVendedor] = useState({});
  const [productos, setProductos] = useState([]);
  const [prestamos, setPrestamos] = useState([]);
  //prueba

  const [vendedoresloan, setVendedoresloan] = useState([]);
  const [serialess, setSerialess] = useState([]);
  const [vendedorselect, setVendedorselect] = useState({});
  const [productoselect, setProductoselect] = useState({});

  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [serialeslist, setSerialeslist] = useState([]);
  const [modalseriales, setModalseriales] = useState(false);
  const [productosagregados, setProductosagregados] = useState([]);
  const [productosagregadosver, setProductosagregadosver] = useState([]);

  const Buscarvendedor = async (ev) => {
    const directus = await getDirectusClient();
    directus
      .request(
        readUsers({
          fields: [
            "*",
            "seriales_prestados.*",
            "seriales_prestados.producto.*",
            "seriales_prestados.producto.grupo.impuesto",
          ],

          filter: {
            documento: {
              _eq: ev,
            },
          },
        })
      )
      .then((res) => {
        console.log(res);

        if (res.length > 0) {
          console.log(res);
          setVendedor(res[0]);
          setSerialess(res[0].seriales_prestados);
        } else {
          setProductos([]);
          setVendedor({ prestamos: [] });
          Modal.warning({
            title: "No se encontraron prestamos Asociados al este vendedor",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    console.log("eff");

   getp();
  }, [vendedor]);

  const getp = async (type = null) => {
    let directus = await getDirectusClient();
    directus
      .request(
        readItems("prestamos", {
          fields: "*.*.*.*",
          filter: {
            vendedor: {
              _eq: vendedor.id,
            },
            estado: {
              _eq: "activo",
            },
            seriales: {
              estado: {
                _eq: 3,
              },
            },
          },
        })
      )
      .then((res) => {
        console.log(res);
        if (type != null) {
          console.log("cerrar prestamo" + type);
          setPrestamos(res);

          directus
            .request(
              readItems("prestamos", {
                filter: {
                  _and: [
                    {
                      count: {
                        seriales: {
                          _lt: 0,
                        },
                      },
                    },
                  ],
                },
              })
            )
            .then((res) => {
              console.log(res[0].seriales.length);
            });
        }
        if (res.length === 0) {
        } else {
          setPrestamos(res);
        }
      })
      .catch((err) => console.log(err));
  };

  const seriales = async (id_prestamo) => {};

  const procesarseriales = (seriales) => {
    console.log("seriales pro");
    console.log(seriales);
    if (seriales.length > 0) {
      const productos = seriales.reduce((acc, obj) => {
        const key = obj.producto.nombre;
        if (!acc[key]) {
          acc[key] = { producto: key, seriales: [], precio: 0 };
        }
        console.log(obj.estado.estado);
        if (obj.estado == 3) {
          acc[key].seriales.push({ serial: obj.serial, id: obj.id });
        }

        acc[key].precio = obj.producto.preciosinimp; // Asumiendo que el precio es de $100
        acc[key].impuesto = obj.producto.grupo.impuesto; // Asumiendo que el precio es de $100

        acc[key].id = obj.producto.id; // Asumiendo que el precio es de $100
        return acc;
      }, {});

      console.log(productos.seriales);

      const productosArray = Object.values(productos);
      let total = 0;
      productosArray.map((producto) => {
        producto.cantidad = producto.seriales.length;

        let preciot =
          parseFloat(producto.seriales.length) * parseFloat(producto.precio);
        let totalp = parseFloat(
          preciot + (preciot * producto.impuesto) / 100
        ).toFixed(2);
        producto.totalparcial = totalp;

        total = parseFloat(total) + parseFloat(totalp);
      });
      return productosArray;
    }
  };

  return (
    <Layouts>
      <Title level={4}>Devolver Seriales Prestados</Title>
      <Search
        allowClear
        placeholder="Escriba el numero de documento del vendedor"
        onSearch={Buscarvendedor}
        style={{ width: 400 }}
      />

      <Table
        dataSource={procesarseriales(serialess)}
        columns={[
          { title: "Producto", dataIndex: "producto" },
          { title: "Cantidad", dataIndex: "cantidad" },
          { title: "Precio unitario ", dataIndex: "precio" },
          { title: "impuesto", dataIndex: "impuesto" },
          { title: "Total parcial", dataIndex: "totalparcial" },
          {
            title: "Action",
            key: "action",
            render: (_, record) => (
              <Space size="middle">
                {
                  <Button
                    onClick={() => {
                      setSerialeslist(record.seriales);
                      setModalseriales(true);
                    }}
                    icon={<EyeOutlined />}
                    type="primary"
                  >
                    Ver seriales
                  </Button>
                }
                <Button
                  onClick={async () => {
                    const directus = await getDirectusClient();
                    let cuenta = 0;
                    let eliminados = [];
                    record.seriales.map((serial) => {
                      console.log(serial);

                      directus
                        .request(
                       updateItem("seriales",serial.id, {
                          estado: 1,
                          prestamo: null,
                          user: null,
                        }))
                        .then((res) => {
                          console.log(res);
                          cuenta = cuenta + 1;
                          eliminados.push(res);
                          if (cuenta === record.seriales.length) {
                            Buscarvendedor(vendedor.documento);
                          }
                        })
                        .catch((err) => console.log(err));
                    });
                  }}
                  icon={<DeleteOutlined />}
                  type="primary"
                >
                  Devolver Todos los seriales del productos
                </Button>
              </Space>
            ),
          },
        ]}
      />

      <Modal
        title="Seriales"
        onOk={() => {
          setSerialeslist([]);
          setModalseriales(false);
          deletecambio = false;
        }}
        onCancel={() => {
          setModalseriales(false);
          deletecambio = false;
        }}
        open={modalseriales}
      >
        {serialeslist.map((serial) => {
          return (
            <Space size={"small"}>
              {" "}
              <span>{serial.serial}</span>
              {/* <Button
                onClick={() => {
                  console.log("eliminar serial " + serial.serial);
                  console.log(productosagregados);

                  Modal.confirm({
                    title: "Quieres eliminar serial " + serial.serial,
                    onOk() {
                      let nuevosseriales = productosagregados.filter(
                        (item) => item.serial !== serial.serial
                      );

                      let arran = arraytotalproductos.filter(
                        (item) => item.serial !== serial.serial
                      );

                      arraytotalproductos.splice(0, arraytotalproductos.length);

                      arran.map((a) => {
                        arraytotalproductos.push(a);
                      });

                      let lisserrialnew = serialeslist.filter(
                        (item) => item.serial !== serial.serial
                      );

                      setSerialeslist(lisserrialnew);

                      console.log(productosagregados.length);

                      setProductosagregados(nuevosseriales);
                      deletecambio = true;
                    },
                    onCancel() {
                      console.log("cancelar");
                    },
                  });
                }}
              >
                Eliminar
            </Button>*/}
            </Space>
          );
        })}
      </Modal>
    </Layouts>
  );
}

export default Loanrepay;
