import { createSlice } from "@reduxjs/toolkit";

export const productosvendedorSlice = createSlice({
  name: "productosvendedor",
  initialState: [],
  reducers: {
    productosvendedorAdd: (state, action) => {
      console.log(action);
      return action.payload;
    },
  },
});
export const { productosvendedorAdd } = productosvendedorSlice.actions;
export default productosvendedorSlice.reducer;
