import React, { useEffect, useState } from 'react';
import Layouts from '../../components/Layouts';
import { getDirectusClient } from '../../lib/directus';
import { readItems, readUsers } from '@directus/sdk';
import { useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Flex,
  Space,
  Spin,
  Table,
  Tree,
  Image as Images,
  Descriptions,
  Col,
  Row,
} from "antd";
import { readUser } from '@directus/sdk';
import dayjs from "dayjs";
import { DatePicker, Modal, Select } from "antd";
import moment from 'moment';
import Chart from 'react-google-charts';
import Search from 'antd/es/input/Search';
const { RangePicker } = DatePicker;
const {DirectoryTree} = Tree






function Reporte_tropas(props) {

  let nuevoObjeto = {};

  const [hoys, setHoys] = useState(true)
  const [userselect, setUserselect] = useState()
  const [userrol, setUserrol] = useState();
  const [total, setTotal] =useState(0)
  const userdata = useSelector((state) => state.userdata)
  const [datareporte, setDatareporte] = useState([]);
  const [datatable, setDatatable] = useState([]);
  const [loanding, setLoanding] = useState(false);
  const [estadodatapicker, setestadodatapicker] = useState(false)
  const [nombreuser, setNombreuser]=useState("")
    const [treeData, setTreeData] = useState([


    ]);
  
  
  const columns = [
    {
      title: "Datos de portabilidades",
      render: (record) => (
        <Descriptions>
          <Descriptions.Item label="Fecha">
            {dayjs(record.date_created).format("YYYY-DD-MM HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item label="Serial">{record.serial}</Descriptions.Item>
          <Descriptions.Item label="Documento">{record.documento}</Descriptions.Item>
          <Descriptions.Item label="min">{record.min}</Descriptions.Item>
          <Descriptions.Item label="nip">{record.nip}</Descriptions.Item>
          <Descriptions.Item label="Asesor">{record.user_created.first_name + " " + record.user_created.last_name}</Descriptions.Item>
          <Descriptions.Item label="Cliente">{record.nombre_cliente}</Descriptions.Item>
          <Descriptions.Item label="Operador">{record.operador}</Descriptions.Item>
          <Descriptions.Item label="Estrategia">{record.estrategia}</Descriptions.Item>
          <Descriptions.Item label="Estado">{record.estado.nombre}</Descriptions.Item>
          
        </Descriptions>
      ),
      responsive: ["xs"],
    },
    {
      title: "Fecha",
      dataIndex: "date_created",

      key: "date_created",
      render: (text) => (
        <span>{dayjs(text).format("YYYY-DD-MM HH:mm:ss")}</span>
      ),
      responsive: ["lg"],
    },

    {
      title: "Serial",
      dataIndex: "serial",

      key: "serial",
      responsive: ["lg"],
    },
    {
      title: "Documento",
      dataIndex: "documento",

      key: "documento",
      responsive: ["lg"],
    },

    {
      title: "Min",

      dataIndex: "min",
      key: "min",
      responsive: ["lg"],
    },
    {
      title: "Nip",

      dataIndex: "nip",
      key: "nip",
      responsive: ["lg"],
    },
    {
      title: "Asesor",

      dataIndex: "user_created",
      key: "user_created",
      render: (text) => <span>{text.first_name + " " + text.last_name}</span>,
      responsive: ["lg"],
    },
    {
      title: "Cliente",

      dataIndex: "nombre_cliente",
      key: "nombre_cliente",
      responsive: ["lg"],
    },
    {
      title: "Operador",

      dataIndex: "operador",
      key: "operador",
      responsive: ["lg"],
    },
    {
      title: "Estrategia",

      dataIndex: "estrategia",
      key: "estrategia",
      responsive: ["lg"],
    },
    {
      title: "Estado",

      dataIndex: "estado",
      fixed: "right",
      key: "estado",
      render: (text) => <span>{text.nombre}</span>,
      responsive: ["lg"],
    },
  ];
  
  const [tipopersonal, setTipopersonal] = useState("tropas")
  const [estados, setEstados] = useState([])
  const [loadingbuscar, setLoadingbuscar] = useState(false)
 const [startDate, setStartDate] = useState("2023-01-01");
 var hoy = moment();
 var hoyf = hoy.add(1, "d").format("YYYY-MM-DD");

 const [endDate, setEndDate] = useState(hoyf);
  const getEstados = () => {
    const directus = new getDirectusClient();
     directus
       .request(
         readItems("estados_portabilidad", {
           fields: ["*.*"],
         })
       )
       .then((res) => {
         // console.log(resPagina);
         // Aquí puedes agregar la lógica para manejar los datos paginados, por ejemplo, concatenarlos con los ya existentes
         setEstados(res);
       })
       .catch((err) => console.log(err));
  }


  const getUsers = () => {
      
    let arraycompleto = []
         
        const directus = new getDirectusClient();
        

        console.log(userdata.role.name);
    if (userdata.role.name=="") {
      
    } else if (userdata.role.name == "") {
    }
            directus
            .request(
                readUsers({
                    fields: ["*","role.*", "subordinados.*.*"],
                    filter: {
                        superior: {
                            id: {
                                _eq: userdata.id
                            }
                        }
                    }
                }))
                    .then((values) => {
                        
                    let arraysimple = []
                
                          values.map((value) => {
                             // console.log(value);
                            if (value.role.name == "coordinador tropas") {
                              console.log("coordinadores", value);


                              //Generando supervisores
                              let arraysub = []
                              console.log("total", value.subordinados.length);
                              value.subordinados.map((sub) => {

                                let promoarr = []
                                sub.subordinados.map((promo) => {

                                  console.log("promotor", promo);
                                              if (
                                                !nuevoObjeto.hasOwnProperty(
                                                  promo.user_created?.first_name
                                                )
                                              ) {
                                                nuevoObjeto[
                                                  promo.user_created?.first_name
                                                ] = {
                                                  iduser: promo.user_created?.id,
                                                  portabilidades: [],
                                                };
                                              }
                                  promoarr.push({
                                      
                                    title: promo.first_name ? promo.first_name : promo,
                                    key: promo.id ? promo.id : promo,
                                  });
                                })


                                console.log("sub", sub.id);
                                arraysub.push({
                                  title: sub.first_name,
                                  key: sub.id,
                                  children: promoarr
                                });
                              });
                              //Generando //coordinadores
                              setTimeout(() => {
                                console.log("subs", arraysub);
                                arraysimple.push({
                                  title: value.first_name,
                                  key: value.id,
                                  // disabled: true,
                                  children: arraysub,
                                });
                              }, 0);

                        
                            } else if (
                              value.role.name == "supervisor tropas"
                            ) {
                              console.log("supervisores", value);
                              //Generando supervisores
                              let arraysub = [];
                              console.log("total", value.subordinados.length);
                              value.subordinados.map((sub) => {

                                let promoarr = [];
                                sub.subordinados.map((promo) => {
                                  console.log("promotor", promo);
                                  promoarr.push({
                                    title: promo.first_name
                                      ? promo.first_name
                                      : promo,
                                    key: promo.id ? promo.id : promo,
                                  });
                                });

                                console.log("sub", sub.id);
                                arraysub.push({
                                  title: sub.first_name,
                                  key: sub.id,
                                  children: promoarr,
                                });
                              });
                              //Generando //coordinadores
                              setTimeout(() => {
                                console.log("subs", arraysub);
                                arraysimple.push({
                                  title: value.first_name,
                                  key: value.id,
                                  // disabled: true,
                                  children: arraysub,
                                });
                              }, 0);
                            } else if (
                              value.role.name == "tropas"
                            ) { 
                               let arraysub = [];
                               console.log("total", value.subordinados.length);
                              value.subordinados.map((sub) => {
                                 
                                 let promoarr = [];
                                sub.subordinados.map((promo) => {
                                   console.log("promotor", promo);
                                   promoarr.push({
                                     title: promo.first_name
                                       ? promo.first_name
                                       : promo,
                                     key: promo.id ? promo.id : promo,
                                   });
                                 });

                                 console.log("sub", sub.id);
                                 arraysub.push({
                                   title: sub.first_name,
                                   key: sub.id,
                                   children: promoarr,
                                 });
                               });
                               //Generando //coordinadores
                               setTimeout(() => {
                                 console.log("subs", arraysub);
                                 arraysimple.push({
                                   title: value.first_name,
                                   key: value.id,
                                   // disabled: true,
                                   children: arraysub,
                                 });
                               }, 0);
                            }
                          })
                      
                      
                      setTimeout(() => {
                            console.log("simple", arraysimple);
                            arraycompleto.push({
                              title: userdata?.first_name,
                              key: userdata.id,
                             // disabled: userdata.role.name=="gerente"?true:false,
                              children: arraysimple,
                            });
                          }, 0);
              

                      
                      setTimeout(() => {
                        console.log("total", arraycompleto);
                        setTreeData(arraycompleto)
                      }, 0);
                })
          .catch((err) => {
            console.log(err);
          });  
        

    }

  useEffect(() => {
    setUserselect(userdata.id)
    setUserrol(userdata.role.name)
      getUsers()
      getEstados()

       let fecha = moment().format("YYYY-MM-DD");
       let fechayhorainicial = fecha + " " + "12:00:00";
       let fechayhorafinal = fecha + " " + "23:59:00";
      
       setStartDate(fechayhorainicial);
      setEndDate(fechayhorafinal);
      
      cargarReporte(userdata.id, fechayhorainicial, fechayhorafinal);
    }, [])
  
  
  const selectTree = (ev) => {
    setLoanding(true)
    setestadodatapicker(true)
   console.log(ev);
    setUserselect(ev[0])
   // setUserrol()
   cargarReporte(ev[0], startDate, endDate)
  }
  //Cargar reporte
  const cargarReporte = (userselect, startDate, endDate, buscar) => {

    console.log("usuario ",userselect);
        const datachart = [["estado", "Cantidad"]];
    const directus = new getDirectusClient();
    directus
      .request(
        readUser(userselect, {
          fields: ["*", "role.*", "subordinados.*.*"],
          filter: {},
        })
      )
      .then((values) => {

        setUserrol(values.role.name)

        console.log(values.role);
        if (values.role.name == "tropas") {
          //consulta como Venedor
          let objf = {
            date_created: {
              _between: [startDate, endDate],
            },
            user_created: {
              id: userselect,
            },
          };


                    directus
                      .request(
                        readItems("portabilidades", {
                          fields: ["*.*"],
                          aggregate: {},
                          filter: objf,
                          limit: -1,
                        })
                      )
                      .then((res) => {
                        console.log(res);
                        setDatatable(res);
                      })
                      .catch((err) => console.log(err));

          directus
            .request(
              readItems("portabilidades", {
                fields: ["*.*"],
                aggregate: {
                  count: ["*"],
                },
                filter: objf,
              })
            )
            .then((res) => {
              console.log(res);
              setTotal(res[0].count);
            })
            .catch((err) => console.log(err));

          directus
            .request(
              readItems("estados_portabilidad", {
                fields: ["*"],
                limit: -1,
                sort: "id",
              })
            )
            .then((estados) => {
              //  datachart.push()

              estados.map((estado) => {
                var objf2 = {};

                objf2 = {
                  date_created: {
                    _between: [startDate, endDate],
                  },
                  user_created: {
                    id: userselect,
                  },
                  estado: estado.id,
                };

                directus
                  .request(
                    readItems("portabilidades", {
                      fields: ["*.*"],
                      aggregate: {
                        count: ["*"],
                      },
                      filter: objf2,

                      limit: -1,
                    })
                  )
                  .then((data) => {
                    datachart.push([estado.nombre, parseInt(data[0].count)]);
                  });
              });
            });

          console.log(datachart);
          setTimeout(() => {
            setLoanding(false);
            setDatareporte(datachart);
          }, 2000);
        } else if (values.role.name == "supervisor tropas") {
          console.log("supervisor");
          //Consulta gestiones de supervisor
          let objf = {
            date_created: {
              _between: [startDate, endDate],
            },
            user_created: {
              superior: {
                id: userselect,
              },
            },
          };



                    directus
                      .request(
                        readItems("portabilidades", {
                          fields: ["*.*"],
                          aggregate: {},
                          filter: objf,
                          limit: -1,
                        })
                      )
                      .then((res) => {
                        console.log(res);
                        setDatatable(res);
                      })
                      .catch((err) => console.log(err));

          directus
            .request(
              readItems("portabilidades", {
                fields: ["*.*"],
                aggregate: {
                  count: ["*"],
                },
                filter: objf,
              })
            )
            .then((res) => {
              console.log(res);
              setTotal(res[0].count);
            })
            .catch((err) => console.log(err));

          directus
            .request(
              readItems("estados_portabilidad", {
                fields: ["*"],
                limit: -1,
                sort: "id",
              })
            )
            .then((estados) => {
              //  datachart.push()

              estados.map((estado) => {
                var objf2 = {};

                objf2 = {
                  date_created: {
                    _between: [startDate, endDate],
                  },
                  user_created: {
                    superior: {
                      id: userselect,
                    },
                  },
                  estado: estado.id,
                };

                directus
                  .request(
                    readItems("portabilidades", {
                      fields: ["*.*"],
                      aggregate: {
                        count: ["*"],
                      },
                      filter: objf2,

                      limit: -1,
                    })
                  )
                  .then((data) => {
                    datachart.push([estado.nombre, parseInt(data[0].count)]);
                  });
              });
            });

          console.log(datachart);
          setTimeout(() => {
            setLoanding(false);
            setDatareporte(datachart);
          }, 2000);
        } else if (values.role.name == "coordinador tropas") {
          //Consulta gestiones de supervisor
          let objf = {
            date_created: {
              _between: [startDate, endDate],
            },
            user_created: {
              superior: {
                superior: {
                  id: userselect,
                },
              },
            },
          };



          
          //fff
          directus
      .request(
        readItems("portabilidades", {
          fields: ["*.*"],
          aggregate: {},
          filter: objf,
          limit: -1,
        })
      )
      .then((res) => {
        console.log(res);
        setDatatable(res);
      })
      .catch((err) => console.log(err));
  



          directus
            .request(
              readItems("portabilidades", {
                fields: ["*.*"],
                aggregate: {
                  count: ["*"],
                },
                filter: objf,
                limit: -1
              })
            )
            .then((res) => {
              console.log(res);
              setTotal(res[0].count);
            })
            .catch((err) => console.log(err));

          directus
            .request(
              readItems("estados_portabilidad", {
                fields: ["*"],
                limit: -1,
                sort: "id",
              })
            )
            .then((estados) => {
              //  datachart.push()

              estados.map((estado) => {
                var objf2 = {};

                objf2 = {
                  date_created: {
                    _between: [startDate, endDate],
                  },
                  user_created: {
                    superior: {
                      superior: {
                        id: userselect,
                      },
                    },
                  },
                  estado: estado.id,
                };

                directus
                  .request(
                    readItems("portabilidades", {
                      fields: ["*.*"],
                      aggregate: {
                        count: ["*"],
                      },
                      filter: objf2,

                      limit: -1,
                    })
                  )
                  .then((data) => {
                    datachart.push([estado.nombre, parseInt(data[0].count)]);
                  });
              });
            });

          console.log(datachart);
          setTimeout(() => {
            setLoanding(false);
            setDatareporte(datachart);
          }, 2000);
        } else if (values.role.name == "gerente") {
          //Consulta gestiones de supervisor
          console.log("Gerente");
          let objf = {
            date_created: {
              _between: [startDate, endDate],
            },
            user_created: {
              superior: {
                superior: {
                  superior: {
                    id: {
                      _eq: userselect
                    },
                  },
                },
              },
            },
          };


                    directus
                      .request(
                        readItems("portabilidades", {
                          fields: ["*.*"],
                          aggregate: {},
                          filter: objf,
                          limit: -1,
                        })
                      )
                      .then((res) => {
                        console.log(res);
                        setDatatable(res);
                      })
                      .catch((err) => console.log(err));

          directus
            .request(
              readItems("portabilidades", {
                fields: ["*.*"],
                aggregate: {
                  count: ["*"],
                },
                filter: objf,
              })
            )
            .then((res) => {
              console.log(res);
              setTotal(res[0].count);
            })
            .catch((err) => console.log(err));
          
            directus
              .request(
                readItems("portabilidades", {
                  fields: ["*.*"],
                
                  filter: objf,
                })
              )
              .then((res) => {
                console.log("todas las portabilidades", res);
                

                console.log(nuevoObjeto);
                //Recorremos el arreglo
                res.forEach((x) => {
                  //Si la ciudad no existe en nuevoObjeto entonces
                  //la creamos e inicializamos el arreglo de profesionales.
          

                  //Agregamos los datos de profesionales.
                  nuevoObjeto[x.user_created.first_name].portabilidades.push({
                    id: x.id,
                    min: x.min,
                    estado: x.estado,
                  });
                });
                console.log("nuevo", nuevoObjeto);
               // setTotal(res[0].count);
              })
              .catch((err) => console.log(err));

          directus
            .request(
              readItems("estados_portabilidad", {
                fields: ["*"],
                limit: -1,
                sort: "id",
              })
            )
            .then((estados) => {
              //  datachart.push()

              estados.map((estado) => {
                var objf2 = {};

                objf2 = {
                  date_created: {
                    _between: [startDate, endDate],
                  },
                  user_created: {
                    superior: {
                      superior: {
                        superior: {
                          id: {
                            _eq: userselect,
                          },
                        },
                      },
                    },
                  },
                  estado: estado.id,
                };

                directus
                  .request(
                    readItems("portabilidades", {
                      fields: ["*.*"],
                      aggregate: {
                        count: ["*"],
                      },
                      filter: objf2,

                      limit: -1,
                    })
                  )
                  .then((data) => {
                    datachart.push([estado.nombre, parseInt(data[0].count)]);
                  });
              });
            });

          console.log(datachart);
          setTimeout(() => {
            setLoanding(false);
            setDatareporte(datachart);
          }, 2000);
        }
      });
  };
  
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setLoanding(true)
      setHoys(false)
     setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
      cargarReporte(userselect, dateStrings[0], dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };


  //Carga datos tabla 


  let cargarSearch = (userselect, startDate, endDate, buscar, estado) => {


    const directus = new getDirectusClient()
    let objftable;


    console.log("userrol", userrol);
    
    if (userrol == "gerente") {
      if (estado) {
        console.log("buscar nombre y estado o solo estado");
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          estado: {
            id: {
              _eq: estado,
            },
          },
          user_created: {
            superior: {
              superior: {
                superior: {
                  id: userselect,
                },
              },
            },
            first_name: {
              _icontains: buscar,
            },
          },
        };
      } else if (buscar) {
        console.log("buscar solo nombre");
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          user_created: {
            superior: {
              superior: {
                superior: {
                  id: userselect,
                },
              },
            },
            first_name: {
              _icontains: buscar,
            },
          },
         
        };
      } else {
        console.log("sin filtro");
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          user_created: {
            superior: {
              superior: {
                superior: {
                  id: userselect,
                },
              },
            },
          },
        };
      }
    } else if (userrol == "coordinador tropas") {
      if (estado) {
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          estado: {
            id: {
              _eq: estado,
            },
          },
          user_created: {
            superior: {
              superior: {
                id: userselect,
              },
            },
            first_name: {
              _icontains: buscar,
            },
          },
          
        };
      } else if (buscar) {
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          user_created: {
            superior: {
              superior: {
                id: userselect,
              },
            },
            first_name: {
              _icontains: buscar,
            },
          },
         
        };
      } else {
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          user_created: {
            superior: {
              superior: {
                id: userselect,
              },
            },
          },
        };
      }
    } else if (userrol == "supervisor tropas") {
      if (estado) {
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          estado: {
            id: {
              _eq: estado,
            },
          },
          user_created: {
            superior: {
              id: userselect,
            },
            first_name: {
              _icontains: buscar,
            },
          },
          
        };
      } else if (buscar) {
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          user_created: {
            superior: {
              id: userselect,
            },
            first_name: {
              _icontains: buscar,
            },
          },
          
        };
      } else {
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          user_created: {
            superior: {
              id: userselect,
            },
          },
        };
      }
    } else if (userrol == "tropas") {
      if (estado) {
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          estado: {
            id: {
              _eq: estado,
            },
          },
          user_created: {
            id: userselect,
            first_name: {
              _icontains: buscar,
            },
          },
         
        };
      } else if (buscar) {
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          user_created: {
            id: userselect,
            first_name: {
              _icontains: buscar,
            },
          },
         
        };
      } else {
        objftable = {
          date_created: {
            _between: [startDate, endDate],
          },
          user_created: {
            id: userselect,
          },
        };
      }
    }



    directus
      .request(
        readItems("portabilidades", {
          fields: ["*.*"],
          aggregate: {},
          filter: objftable,
          limit: -1,
        })
      )
      .then((res) => {
        console.log(res);
        setDatatable(res);
        setLoadingbuscar(false)
      })
      .catch((err) => console.log(err));
  };


  let onSearch = (ev) => {
    setLoadingbuscar(true)
    setNombreuser(ev.target.value);
    console.log(ev.target.value);
    cargarSearch(userselect, startDate, endDate, ev.target.value);
  }

  let onEstadoseacrh = (ev) => {
     setLoadingbuscar(true);
    console.log(ev);
    console.log(nombreuser);
   cargarSearch(userselect, startDate, endDate, nombreuser, ev);
  }
    return (
      <Layouts>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Space>
            <Button
              onClick={() => {
                let fecha = moment().format("YYYY/MM/DD");
                let fechayhorainicial = fecha + " " + "12:00:00";
                let fechayhorafinal = fecha + " " + "23:59:00";
                console.log(fechayhorainicial);
                setStartDate(fechayhorainicial);
                setEndDate(fechayhorafinal);
                console.log(fechayhorafinal);
                setHoys(true);
                if (userselect) {
                  cargarReporte(userselect, fechayhorainicial, fechayhorafinal);
                }
                // cargarReporte(userselect,fechayhorainicial, fechayhorafinal);
              }}
            >
              Hoy
            </Button>
            <RangePicker
              disabled={!estadodatapicker}
              format={{ format: "YYYY/MM/DD HH:mm:ss", type: "mask" }}
              showTime={{
                format: "HH:mm:ss",
              }}
              onChange={onRangeChange}
              placeholder={["Fecha inicial", "Feha final"]}
              placement="bottomRight"
            />
          </Space>

          <Spin spinning={loanding} delay={500}>
            <Space>
              <h3>
                {hoys
                  ? "Mostrando datos de hoy: " + moment().format("YYYY-MM-DD")
                  : "Mostrando filtro entre fechas " +
                    startDate +
                    "--" +
                    endDate}
              </h3>
              <h3 style={{ color: "green" }}>Total: {total}</h3>
            </Space>
          </Spin>

          <Row justify="space-between" wrap="wrap">
            <Col xs={24} lg={8}>
              <DirectoryTree
                showLine={true}
                onSelect={selectTree}
                onExpand={(e) => console.log()}
                treeData={treeData}
                
              />
            </Col>
            <Col xs={24} lg={16}>
              <Chart
                chartType="BarChart"
                style={{ minWidth: "300px" }}
                height={"400px"}
                loader={<div>Cargando gráfica</div>}
                data={datareporte}
                options={{
                  chart: {
                    title: "Reporte de portabilidades por estado",
                  },
                }}
              />
            </Col>
          </Row>

          <br />
        </Space>

        <Space direction="vertical">
          <Flex>
            <Row direction="horizontal">
              <Search
                enterButton
                loading={loadingbuscar}
                styles={{ width: "600px" }}
                onChange={onSearch}
                allowClear
                placeholder="Buscar por nombre de asesor"
              />
              <label>Selecione un estado para filtrar</label>
              <Select
                onChange={onEstadoseacrh}
                style={{ width: 120 }}
                //   onChange={handleChange}
              >
                <Select.Option value="">{"sin filtro"}</Select.Option>
                {estados.map((estado) => {
                  return (
                    <Select.Option value={estado.id}>
                      {estado.nombre}
                    </Select.Option>
                  );
                })}
              </Select>
              <Button
                onClick={() => {
                  cargarSearch(userselect, startDate, endDate);
                }}
              >
                Borrar filtros
              </Button>
            </Row>
          </Flex>

          <div className="tableresponsive">
            <Table
              style={{ overflow: "scroll" }}
              scroll={{ y: 600 }}
              columns={columns}
              dataSource={datatable}
            />
          </div>
        </Space>
      </Layouts>
    );
}

export default Reporte_tropas;