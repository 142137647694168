import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { getDirectusClient } from "../../lib/directus";
import { readItems } from "@directus/sdk";
import Layouts from "../../components/Layouts";
import { DatePicker, Modal, Select } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const Reporteportabilidades = () => {
  const [portabilidadesData, setPortabilidadesData] = useState([]);
  const [startDate, setStartDate] = useState("2023-01-01");
    var hoy = moment();
    var hoyf = hoy.add(1, "d").format("YYYY-MM-DD");

    const [endDate, setEndDate] = useState(hoyf);
    
    const [total, setTotal] = useState(0)
    const [totalexitorecarga, setTotalexitorecarga] = useState(0);
    const [totalexitosinrecarga, setTotalexitosinrecarga] = useState(0);
    const [totalenproceso, setTotalenproceso] = useState(0);
    const [totalrechazada, setTotalrechazada] = useState(0);
    const [totaldevuelto, setTotaldevuelto] = useState(0);
     const [totalrecuperacion, setTotalrecuperacion] = useState(0);
    const [data, setData] = useState([])
    const [rolesearch, setRolesearch] = useState("")
    
  //  useEffect(() => { }, [startDate, endDate]);
    useEffect(() => {
        conusltaData(startDate, endDate, rolesearch)
    }, [])
    
    const conusltaData = (startDate, endDate, role) => {
      const directus = new getDirectusClient();

      const datachart = [["estado", "Cantidad"]];
      
      var objf = {}
        
      if (role !== "") {
        objf = {
          date_created: {
            _between: [startDate, endDate],
          },
          user_created: {
            role: {
              name: {
                _eq: role,
              },
            },
          },
        };
      } else {
        objf = {
          date_created: {
            _between: [startDate, endDate],
          },
        };
      }
        
             directus
               .request(
                 readItems("portabilidades", {
                   fields: ["estado", "id", "estado.nombre"],
                   aggregate: {
                     count: ["*"],
                   },
                   filter: objf,

                   limit: -1,
                 })
               )
               .then((data) => {
                 setTotal(data[0].count);
               });
          

      directus
        .request(
          readItems("estados_portabilidad", {
            fields: ["*"],
            limit: -1,
            sort: "id",
          })
        )
        .then((estados) => {
          //  datachart.push()


         

          estados.map((estado) => {

                  var objf2 = {};

                  if (role !== "") {
                    objf2 = {
                      date_created: {
                        _between: [startDate, endDate],
                      },
                      user_created: {
                        role: {
                          name: {
                            _eq: role,
                          },
                        },
                      },
                      estado: estado.id,
                    };
                  } else {
                    objf2 = {
                      date_created: {
                        _between: [startDate, endDate],
                      },
                      estado: estado.id,
                    };
                  }



            directus
              .request(
                readItems("portabilidades", {
                  fields: ["estado", "id", "estado.nombre"],
                  aggregate: {
                    count: ["*"],
                  },
                  filter: objf2,

                  limit: -1,
                })
              )
              .then((data) => {
                datachart.push([estado.nombre, parseInt(data[0].count)]);
              });
          });
        });

      console.log(datachart);
      setTimeout(() => {

        setData(datachart);
      }, 3000);
    };


  
const onRangeChange = (dates, dateStrings) => {
  if (dates) {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
    conusltaData(dateStrings[0], dateStrings[1]);
   
  } else {
    console.log("Clear");
  }
};
  return (
    <Layouts>
      <h2>Reporte de Portabilidades por Estados</h2>
      <h3>{"Total registros: " + total}</h3>
      <Select
        style={{ width: 120 }}
        value={rolesearch}
        onChange={(ev) => {
          console.log(ev);
          Modal.confirm({
            title: "Estas cambiando a gestionar:  " + ev,

            content: "Estas seguro de este cambio",
            onOk() {
              console.log("Si");
              console.log(ev);
              setRolesearch(ev);

              conusltaData( startDate, endDate, ev);
            },
            onCancel() {
              console.log("Cancelar");
              setRolesearch(rolesearch);
            },
          });
        }}
      >
        <Select.Option key={2} value={""}>
          Todos
        </Select.Option>
        <Select.Option key={1} value={"promotores"}>
          Promotores
        </Select.Option>

        <Select.Option key={2} value={"tropas"}>
          Tropas
        </Select.Option>
      </Select>
      Buscar entre fechas:
      <RangePicker
        format={"YYYY/MM/DD HH:mm:ss"}
        onChange={onRangeChange}
        placeholder={["Fecha inicial", "Feha final"]}
        showTime
      />
      <Chart
        chartType="Bar"
        width={"100%"}
        height={"400px"}
        loader={<div>Cargando gráficao</div>}
        data={data}
        options={{
          chart: {
            title: "Reporte de portabilidades por estado",
          },
        }}
      />
      {console.log(data)}
    </Layouts>
  );
};

export default Reporteportabilidades;
