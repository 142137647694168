import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    userAdd: (state, action) => {
      console.log(action);
      return action.payload;
    },
  },
});

export const { userAdd } = authSlice.actions;
export default authSlice.reducer;
