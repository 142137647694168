import React, { useEffect, useState } from "react";
import Layouts from "../../components/Layouts";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getDirectusClient } from "../../lib/directus";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { productosfacturaAdd } from "../../features/productosFacrurar/productosSlice";

function VisualizarFactura(props) {
  let totalfinal = 0;
  const [producutosf, setProductosf] = useState([]);
  const directus = getDirectusClient();

  const location = useLocation();
  const navigate = useNavigate();

  const dispacth = useDispatch();

  const productosfacturar = useSelector((state) => state.productosfacturar);

  const seriales = (id) => {
    const data = directus.items("seriales").readByQuery({
      fields: ["*"],
      filter: {
        produtofactura: {
          id: {
            _eq: id,
          },
        },
      },
    });

    console.log(id);

    return data;
  };

  const consulta = async () => {
    let productos = [];
    location.state.factura.productos.map((producto) => {
      directus
        .items("productos_factura")
        .readOne(producto, {
          fields: ["cantidad", "factura", "id", "nombre", "precio_unitario"],
        })
        .then(async (res) => {
          let d = await seriales(res.id);
          console.log(d);
          productos.push({
            nombre: res.nombre,
            id: res.id,
            cantidad: res.cantidad,
            seriales: d.data,
            preciounitario: res.precio_unitario,
          });
        });
    });

    return productos;
  };

  useEffect(() => {
    const consultardata = async () => {
      const d = await consulta();
      setTimeout(() => {
        setProductosf(d);
      }, 1000);
    };
    consultardata();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <p>Factura N: {location.state.factura.id}</p>
      <p>Fecha : {location.state.factura.date_created}</p>
      <p>Factura N: {location.state.factura.id}</p>
      {console.log(producutosf)}
      <table style={{ textAlign: "left" }}>
        <tr>
          <th>Producto</th>
          <th>cantidad</th>
          <th>Precio unitario</th>
          <th>Total parcial</th>
        </tr>
        {producutosf.map((pro) => {
          let parcialt = pro.cantidad * pro.preciounitario;
          totalfinal = totalfinal + parcialt;
          return (
            <tr style={{ textAlign: "left", boder: 1 }} key={pro.id}>
              <td> {pro.nombre}</td>
              <td> {pro.cantidad}</td>
              <td>{pro.preciounitario}</td>
              <td>{pro.cantidad * pro.preciounitario}</td>
            </tr>
          );
        })}
      </table>
      Total {totalfinal}
      <Button
        className="print-no"
        style={{ width: 150 }}
        onClick={() => {
          navigate("/");
        }}
      >
        Volver
      </Button>
    </div>
  );
}

export default VisualizarFactura;
